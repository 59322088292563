import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";
import { Confirm } from "react-admin";

type Props = {
  state: string;
  onSuccessCallback: (option: string) => void;
};

const showExpeditedSelection = (state: string) => {
  return state === "DE";
};

export const SelectExpediteModalContainer = ({
  state,
  onSuccessCallback,
}: Props) => {
  if (!showExpeditedSelection(state)) {
    onSuccessCallback(EXPEDITED_OPTIONS_DEFAULT);
    return <></>;
  }

  return (
    <SelectExpediteModal state={state} onSuccessCallback={onSuccessCallback} />
  );
};

const SelectExpediteModal = ({ state, onSuccessCallback }: Props) => {
  const [open, setOpen] = useState(true);
  const [option, setOption] = useState(EXPEDITED_OPTIONS_DEFAULT);

  const handleChange = (event: any) => {
    setOption(event.target.value);
  };

  const handleConfirm = async () => {
    setOpen(false);
    onSuccessCallback(option);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Confirm
        isOpen={open}
        title="Select expedite option"
        content={
          <>
            <p>
              For <b>{state}</b>, the options are:
            </p>
            <FormControl fullWidth>
              <Select value={option} onChange={handleChange}>
                {EXPEDITED_OPTIONS.map(({ id, name }) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        }
        confirm="Select"
        cancel="back"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />{" "}
    </>
  );
};

const EXPEDITED_OPTIONS = [
  {
    name: "Standard",
    id: "standard",
  },
  {
    name: "Expedited $50",
    id: "expedited",
  },
  {
    name: "Expedited - 1 day - $100",
    id: "expedited1day",
  },
];

const EXPEDITED_OPTIONS_DEFAULT = EXPEDITED_OPTIONS[0].id;
