import { FooterEmail } from "./Footer";

type Props = {
  fullName: string;
  companyName: string;
};

export const NewNameNeededEmail = ({ fullName, companyName }: Props) => {
  return (
    <>
      <p>Hi {fullName},</p>
      <p>
        I'm reaching out with some bad news - the name you selected for your US
        Company, ({companyName}), is not available.
      </p>
      <p>
        Please reply with three new name choices in order of priority. That way,
        we can get you up and running as soon as possible!
      </p>
      <p>A few key tips:</p>
      <ol>
        <li>
          <p>
            Please avoid plural versions of the same name and adding other
            non-distinguishable words. For example, “Red Wagons” is considered
            the same as "Red Wagon" and "A Red Wagon".
          </p>
        </li>
        <li>
          <p>
            Please avoid versions of the same name with punctuation and
            capitalization changes. The name availability search does not
            accommodate punctuation and capitalization.
          </p>
        </li>
      </ol>
      <p>
        Thanks so much! We will continue with your filing as soon as we get your
        new names.
      </p>
      <FooterEmail />
    </>
  );
};
