import {
  DateField,
  TextField,
  Datagrid,
  ShowBase,
  ShowProps,
  useShowContext,
  ReferenceManyField,
  useRedirect,
  Button,
  BooleanField,
} from "react-admin";
import { CustomerAside } from "./CustomerAside";
import { Box, Card, CardContent, Divider, Typography } from "@material-ui/core";
import { showCustomerStyles } from "./styles";
import { CreateCognitoButton } from "./CustomerActions";

export const CustomerShow = (props: ShowProps) => (
  <ShowBase {...props}>
    {/* <div style={{ display: "none" }}>
      <ShowGuesser {...props} />
    </div>
   */}
    <CustomerShowContent />
  </ShowBase>
);

const CustomerShowContent = () => {
  const redirect = useRedirect();
  const classes = showCustomerStyles();
  const { record, loaded } = useShowContext<any>();
  if (!loaded || !record) return null;

  const onAddCompanyClick = () =>
    redirect(`/companies/create?customerId=${record.id}`);

  return (
    <Box mt={2} display="flex">
      <Box flex="1">
        <Card>
          <CardContent>
            <Box display="flex">
              <Box ml={2} flex="1">
                <Typography variant="h5">{record.fullName}</Typography>
              </Box>
            </Box>
            <Box ml={2} mt={2}>
              Status: <TextField source="status" emptyText="-" />
              <br />
              Cognito Id: <TextField source="cognitoId" emptyText="-" />
              <br />
              Referred by: <TextField source="referredBy" emptyText="-" />
              <br />
              Stripe Id: <TextField source="stripeId" emptyText="-" />
              <br />
              CustomerIo Id: <TextField source="customer.io Id" emptyText="-" />
              <br />
              Time Zone: <TextField source="timezone" emptyText="-" />
              <br />
              Created by email:{" "}
              <TextField
                source="internalDetails.createdByEmail"
                emptyText="-"
              />
              <br />
              Airtable pipeline id: <br />
              <TextField
                source="internalDetails.airtablePipelineId"
                emptyText="-"
              />
              <br />
              Do not send Welcome Mail (plan):
              <BooleanField source="unsubscribeWelcomeEmail" />
              <br />
              <br />
              <Typography variant="subtitle1">
                Companies
                <Button
                  className={classes.createCompanyButton}
                  onClick={onAddCompanyClick}
                >
                  <>+ Add Company</>
                </Button>
              </Typography>
              <Divider style={{ width: "100%" }} />
              <ReferenceManyField
                target="customers"
                reference="companies"
                sort={{ field: "date", order: "DESC" }}
              >
                <Datagrid rowClick="show">
                  <TextField
                    source="companyDetails.name"
                    label="Company name"
                  />
                  <DateField source="createdAt" />
                  <TextField
                    source="companyDetails.geographicState"
                    label="Company State"
                  />
                  <TextField source="status" />
                </Datagrid>
              </ReferenceManyField>
              <br />
              <br />
              <Typography variant="subtitle1">Actions</Typography>
              <CreateCognitoButton record={record} />
              {/* <ExampleCustomerButton record={record} /> */}
            </Box>
          </CardContent>
        </Card>
      </Box>

      <CustomerAside record={record} />
    </Box>
  );
};

export default CustomerShow;
