import { FooterEmail } from "./Footer";

type Props = {
  fullName: string;
  companyName: string;
};

export const PhoneLineEmail = ({ fullName, companyName }: Props) => {
  return (
    <>
      Hi {fullName},
      <br />
      <br />
      No matter what stage of creation {companyName} is, you should definitely
      consider adding the doola US Phone Line + Utility Bill! You may be unsure
      if you really *need* a U.S. Business Phone with a utility bill, but I can
      assure you that you will reap plenty of benefits from having one:
      <br />
      <ul>
        <li>
          Business Verification: When applying for a bank account, Stripe/PayPal
          account, Amazon Seller account and more, a U.S. phone number is
          required
        </li>
        <li>
          Proof of Location: Many services will require a utility bill as a
          proof-of-location to conduct business. If you want to sell on Amazon,
          this is a must!
        </li>
        <li>
          Customer Support: Sometimes customers want to talk to people rather
          than read a website to search for answers!
        </li>
        <li>
          Professionalism: Give your US company an added element of
          professionalism with a U.S. Business Phone number instead of an
          international cell, or no number at all!
        </li>
      </ul>
      <br />
      If you still aren't sure, check out
      https://www.doola.com/us-phone-number-with-utility-bill-proof for a
      detailed breakdown of why {companyName} truly does need a US Phone Line
      that comes with a Utility Bill!
      <br />
      <br />
      And if you're ready to add this to your plan, let us know what your phone
      number is and we'll get started on this ASAP!
      <br />
      <FooterEmail />
    </>
  );
};
