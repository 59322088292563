import { makeStyles } from "@material-ui/core";

export const createCustomerStyles = makeStyles({
  wrapperCreateCustomer: {
    maxWidth: "500px",
    justifyItems: "center",
    margin: "auto",
    marginTop: "30px",
  },
  simpleForm: {},
});

export const showCustomerStyles = makeStyles({
  wrapperCompanies: {
    maxWidth: "500px",
    justifyItems: "center",
    margin: "auto",
    marginTop: "30px",
  },
  createCompanyButton: {
    float: "right",
  },
});
