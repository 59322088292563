import ProductList from "./ProductList";
import ProductShow from "./ProductShow";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ProductEdit from "./ProductEdit";
import ProductCreate from "./ProductCreate";

const products = {
  list: ProductList,
  show: ProductShow,
  edit: ProductEdit,
  create: ProductCreate,
  icon: ShoppingBasketIcon,
};

export default products;
