import BusinessIcon from "@material-ui/icons/Business";
import CompanyList from "./CompanyList";
import CompanyShow from "./CompanyShow";
import CompanyEdit from "./CompanyEdit";
import CompanyCreate from "./CompanyCreate";
import { BusinessTypeNames, BusinessTypeStyles } from "../../models/Company";

const companies = {
  list: CompanyList,
  show: CompanyShow,
  icon: BusinessIcon,
  edit: CompanyEdit,
  create: CompanyCreate,
};

export const stylesOptions = (typeToConsider: BusinessTypeNames): Array<{
  id: BusinessTypeStyles | "";
  name: BusinessTypeStyles | "-";
}> => {
  switch (typeToConsider) {
    case BusinessTypeNames.LLC:
      return [
        { id: "", name: "-" }, // Non selectable value for each case
        { id: "LLC", name: "LLC" }, // 1 the accronym for each case
        { id: "L.L.C", name: "L.L.C" },
        {
          id: "Limited Liability Company",
          name: "Limited Liability Company",
        },
      ];

    case BusinessTypeNames.DAO:
      return [
        { id: "", name: "-" },
        { id: "DAO", name: "DAO" },
        { id: "DAO LLC", name: "DAO LLC" },
        { id: "D.A.O L.L.C", name: "D.A.O L.L.C" },
        {
          id:
            "Decentralized Autonomous Organization Limited Liability Company",
          name:
            "Decentralized Autonomous Organization Limited Liability Company",
        },
      ];

    case BusinessTypeNames.CCORP:
      return [
        { id: "", name: "-" },
        { id: "CCorp", name: "C Corp" },
        { id: "Inc", name: "Inc" },
        { id: "C Corporation", name: "C Corporation" },
      ];

    case BusinessTypeNames.SERIES_LLC:
      return [
        { id: "", name: "-" },
        { id: "Series LLC", name: "Series LLC" },
        {
          id: "Series Limited Liability Company",
          name: "Series Limited Liability Company",
        },
      ];

    default:
      return [];
  }
};

export const allBusinessTypes = () => {
  const businessTypes = []
  // TODO Refactor all of this things with this enum aswell..
  const [_, llcAcronym] = stylesOptions(BusinessTypeNames.LLC)
  const [__, daoAcronym] = stylesOptions(BusinessTypeNames.DAO)
  const [___, ccorpAcryonym] = stylesOptions(BusinessTypeNames.CCORP)
  const [____, seriesLLCAcronym] = stylesOptions(BusinessTypeNames.SERIES_LLC)

  businessTypes.push(llcAcronym, daoAcronym, ccorpAcryonym, seriesLLCAcronym)

  return businessTypes
}


export default companies;
