import * as React from "react";
import Box from "@material-ui/core/Box";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { ServiceNames } from "../../../models/ServiceModel/ServiceEnums";
import { useAddServicesStyles } from "./styles";
import { formatServiceName } from "../../../formatters/serviceFormatters";
import { TextInput, useRecordContext } from "react-admin";
import { useEffect } from "react";
import { useForm } from "react-final-form";
import { PersistedCompany } from "../../../models/Company";

export const AddServices = (props: {
  record?: PersistedCompany;
  isEdditing?: boolean;
}) => {
  const classes = useAddServicesStyles();
  const form = useForm();
  const record = useRecordContext<PersistedCompany>(props);

  const getInitialServicesSelected = () => {
    if (!record?.services) {
      return [];
    }
    return record.services.map((e) => e.name);
  };

  const initialValues = getInitialServicesSelected();

  const [serviceNamesSelected, setServiceNameSelected] = React.useState<
    string[]
  >(initialValues);

  useEffect(() => {
    form.change(
      "services",
      serviceNamesSelected.map((value) => ({
        name: value,
      }))
    );
  });

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setServiceNameSelected(
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={serviceNamesSelected}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        className={classes.muiSelect}
        renderValue={(selected: any) => (
          <Box style={{ display: "flex", flexWrap: "wrap" }}>
            {selected.map((value: any) => (
              <Chip
                style={{ width: "auto", margin: "5px" }}
                key={value}
                label={formatServiceName(value)}
              />
            ))}
          </Box>
        )}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          transformOrigin: { vertical: "top", horizontal: "center" },
        }}
      >
        {Object.values(ServiceNames).map((name) => (
          <MenuItem
            key={name}
            value={name}
            disabled={props.isEdditing && initialValues.includes(name)}
          >
            {formatServiceName(name)}
          </MenuItem>
        ))}
      </Select>
      <TextInput
        style={{ display: "none" }}
        fullWidth
        source="services"
        label="services"
      />
    </>
  );
};
