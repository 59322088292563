import { Grid } from "@material-ui/core";
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from "react-admin";
import { formatServiceName } from "../../formatters/serviceFormatters";
import { ServiceNames } from "../../models/ServiceModel/ServiceEnums";

const ProductCreate = (props: any) => (
  <Create {...props} style={{ width: "100%" }}>
    <SimpleForm>
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        spacing={2}
        style={{ width: "100%" }}
      >
        <Grid item sm={5}>
          <TextInput source="name" fullWidth helperText="Name of Product" />
        </Grid>
        <Grid item sm={5}>
          <SelectInput
            choices={[
              { id: "active", name: "Active" },
              { id: "inactive", name: "Inactive" },
            ]}
            helperText="Active or inactive"
            source="status"
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <ArrayInput
            source="services"
            defaultValue={[{ name: "llc" }]}
            fullWidth
          >
            <SimpleFormIterator>
              <SelectInput
                label="Service for Product"
                choices={Object.values(ServiceNames).map((serviceName) => ({
                  id: serviceName,
                  name: formatServiceName(serviceName),
                }))}
                helperText="Service included in product"
                source="status"
                fullWidth
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <TextInput
          label="Stripe Pricing Id"
          helperText="From Stripe"
          source="id"
          style={{ width: "100%" }}
        />
        <TextInput source="description" style={{ width: "100%" }} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default ProductCreate;
