import { PersistedCompany } from "../models/Company";
import axios, { ResponseType } from "axios";
import config from "../config";
import {
  EINServiceState,
  ServiceNames,
  ServiceStatus,
} from "../models/ServiceModel/ServiceEnums";
import { CompanyService } from "../models/ServiceModel/ServiceModel";
import { AirtableEmail, EmailTypes } from "./airtable_emails";
import { ApiClient } from "../dataproviders/amplifyClass";
const API_NAME = "api";
const Api = new ApiClient(API_NAME);

export class BackendActions {
  company: PersistedCompany;
  googleDriveRoot: string;
  data: {
    companyName: string;
    companyId: string;
    members: {
      fullName: string;
      ownership: string;
      isCompany: boolean;
    }[];
    llcStyle: string;
    location: string;
    ssnItin: string;
    closingMonth: string;
    companyDescription: string;
    businessStarted: string;
    street: string;
    cityStateZip: string;
    countyState: string;
    activity: string;
    activityOther: string;
    articlesFileId: string;
    signedSS4FileId: string;
    driveId: string;
  };
  serviceId?: string;
  callbackSuccess?: any;
  notify: any;

  constructor(params: {
    company: PersistedCompany;
    service?: CompanyService;
    callbackSuccess?: any;
    notify?: any;
  }) {
    const { company, service, callbackSuccess, notify } = params;
    this.company = company;
    this.googleDriveRoot = "https://drive.google.com";
    this.data = {
      companyName: company.companyDetails.name,
      companyId: company.id,
      members: company.companyDetails.owners,
      llcStyle: company.companyDetails.businessType.style,
      location: company.companyDetails.geographicState,
      ssnItin:
        company.companyDetails.ITIN || "" + company.companyDetails.SSN || "",
      closingMonth: "December",
      companyDescription: company.companyDetails.description,
      businessStarted: "not defined yet",
      street: company.applicantAddress.address1,
      cityStateZip:
        company.applicantAddress.city +
        ", " +
        company.applicantAddress.state +
        company.applicantAddress.postalCode, // Not sure if it is from the applicant.
      countyState: "not defined yet",
      activity: company.companyDetails.activity || "",
      activityOther: company.companyDetails.activityOther || "",
      articlesFileId: company.documents.articlesFileId || "",
      signedSS4FileId: company.documents.signedSS4FileId || "",
      driveId: company?.documents?.googleDriveFolderId || "",
    };
    this.serviceId = service?.id;
    this.callbackSuccess = callbackSuccess;
    this.notify = notify;
  }

  formCompany = async (expediteOption: string | undefined) => {
    const addExpediteOption = expediteOption
      ? "?expediteOption=" + expediteOption
      : "";

    const endpoint =
      "/registeredagent/companies/" + this.data.companyId + addExpediteOption;
    return await Api.post(endpoint);
  };

  previewSS4 = async () => {
    const apiUrl =
      config.api.URL + "/documents/ss4/preview/" + this.data?.companyId;
    const response = await GetRequest(apiUrl, "arraybuffer", {
      "Accept": "application/pdf",
      "Content-Type": "application/pdf",
    });
    const data = response.data;
    const encoded = Buffer.from(data, "binary").toString("base64");
    const linkSource = `data:application/pdf;base64,${encoded}`;
    const downloadLink = document.createElement("a");
    const filename = `SS4 Signature ${this.data?.companyName}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
    return { success: true };
  };

  requestSS4Signature = () => {
    const apiUrl = "/integromat/webhook/send-ss4-signature-request";
    const response = Api.post(apiUrl, { companyId: this.data.companyId });
    this._sendconfirmation(
      "SS4 sent for customer signature, check back soon for EIN state update."
    );
    return response;
  };

  previewSignedSS4 = () => {
    console.log("Start");

    const signedSS4FileId = this.data?.signedSS4FileId;
    if (!signedSS4FileId) {
      this._sendconfirmation("Customer does not have a signed SS4.");
      return "";
    }
    const endpoint = "/file/d/";

    (window as any)
      .open(
        this.googleDriveRoot + endpoint + signedSS4FileId + "/view?usp=sharing",
        "_blank"
      )
      .focus();

    return "";
  };

  faxSS4ToIRS = () => {
    const apiUrl = "/integromat/webhook/send-ss4-fax-to-irs";

    const response = Api.post(apiUrl, { companyId: this.data.companyId });

    this._sendconfirmation(
      "SS4 Fax sent to IRS, check back soon for EIN state update."
    );
    return response;
  };

  einReceived = async () => {
    const einServiceStateUpdateUrl = "/services/serviceState";

    const data = {
      companyId: this.data.companyId,
      serviceName: ServiceNames.einCreation,
      newState: EINServiceState.einReceived,
    };
    const response = await Api.put(einServiceStateUpdateUrl, data);
    console.log(response);

    const emailClient = new AirtableEmail(this.company, EmailTypes.einReceived);
    const resp = await emailClient.sendEmail();
    console.log(resp);

    this._sendconfirmation(
      "EIN marked as received. All other service states have been updated."
    );
    return response;
  };

  einResubmissionSent = async () => {
    const einServiceStateUpdateUrl = "/services/serviceState";

    const data = {
      companyId: this.data.companyId,
      serviceName: ServiceNames.einCreation,
      newState: EINServiceState.waitingOnEINFromIRS,
    };
    const response = await Api.put(einServiceStateUpdateUrl, data);
    console.log(response);

    const emailClient = new AirtableEmail(
      this.company,
      EmailTypes.secondSubmissionSent
    );
    const resp = await emailClient.sendEmail();
    console.log(resp);

    this._sendconfirmation(
      "EIN Service State changed and email sent to customer. You should have manually faxed a new EIN submission to the IRS."
    );
    return response;
  };

  goToFolder = () => {
    const driveId = this.data?.driveId;
    if (!driveId) {
      this._sendconfirmation("Customer does not have driveId.");
      return "";
    }

    const endpoint = "/drive/u/1/folders/";

    (window as any)
      .open(this.googleDriveRoot + endpoint + driveId, "_blank")
      .focus();

    return "";
  };

  annualReportOnline = () => {
    const endpoint = "https://wyobiz.wyo.gov/Business/AnnualReport.aspx";
    (window as any).open(endpoint, "_blank").focus();

    return "";
  };

  applyForEinOnline = () => {
    const endpoint = "https://sa.www4.irs.gov/modiein/individual/index.jsp";
    (window as any).open(endpoint, "_blank").focus();
    return "";
  };

  changeServiceState = ({ to }: { to: string }) =>
    RequestPutService({ id: this.serviceId, serviceState: to });

  completeService = () =>
    RequestPutService({
      id: this.serviceId,
      status: ServiceStatus.completed,
      updateStage: true,
    });

  //PRIVATE
  _buildQueryParameters = (paramsToSend: {}) => {
    const queryNonEconded = new URLSearchParams(paramsToSend).toString();
    return "?" + queryNonEconded; // encodeURIComponent(queryNonEconded)
  };

  _sendconfirmation = (message: string) => {
    // throw new Error(message);
    try {
      this.notify(message, { type: "success" });
    } catch (error) {
      console.warn(message);
      console.error(message);
    }
  };
}

const RequestPutService = async (payload: {}) => {
  const response = await Api.put("/services", payload);

  return response;
};

const GetRequest = async (url: string, responseType = "json", headers?: Record<string, any>) => {
  try {
    const response = await axios({
      method: "GET",
      url: url,
      headers,
      responseType: responseType as ResponseType,
    });
    return response;
  } catch (error: any) {
    const { errorMessages } = JSON.parse(
      new TextDecoder().decode(error.response.data)
    );

    throw new SwapError({
      title: `<strong>Missing some data for the SS4</strong>`,
      html: ErrorHtml(errorMessages),
    });
  }
};

type SwapDataType = {
  title: string | HTMLElement;
  html: string | HTMLElement;
};

class SwapError extends Error {
  swapData: SwapDataType & { icon: string };
  constructor(swapData: SwapDataType) {
    super();
    this.swapData = { ...swapData, icon: "error" };
  }
}

const ErrorHtml = (errorMessages: string[]) => {
  return `<ul>${errorMessages
    .map((error) => `<li>${error}</li>`)
    .join("")}</ul>`;
};
