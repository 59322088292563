import { useGetManyReference } from "react-admin";
import { PersistedCompany } from "../../../models/Company";
import { Box } from "@material-ui/core";
import { CustomAction, ActionTypes } from "../../../components/CustomAction";
import CustomEmailLink from "../../../components/CustomEmails";
import { EmailTypes } from "../../../services/airtable_emails";
import { getActions } from "./services";
import { countBy } from "lodash";
import {
  defaultPagination,
  defaultSorting,
  filterParams,
} from "../../../dataproviders/constants";
import { CompanyService } from "../../../models/ServiceModel/ServiceModel";

type ServiceActionsContainerProps = {
  company: PersistedCompany;
  service: {
    key: string;
    serviceState: string;
    object: CompanyService;
  };
};

type ServiceActionsProps = {
  companyEmailsData: any;
  company: PersistedCompany;
} & ServiceActionsContainerProps;

const ServiceActions = ({
  service,
  companyEmailsData,
  company,
}: ServiceActionsProps) => {
  const { actions, mails } = getActions(service.key, service.serviceState);

  const emailCounts = countBy(
    Object.values(companyEmailsData),
    (email: any) => email?.metadata?.emailType
  );

  return (
    <Box display="inline-flex">
      {actions?.map(
        (
          action: {
            label: string;
            type: ActionTypes;
            payload: {};
          },
          index: number
        ) => {
          const { label, type, payload } = action;
          return (
            <CustomAction
              key={`${label}_${index}`}
              label={label}
              type={type}
              company={company}
              service={service.object}
              payload={payload}
            />
          );
        }
      )}
      {mails.map(
        (
          { label, type }: { label: string; type: EmailTypes },
          index: number
        ) => {
          return (
            <CustomEmailLink
              key={`${label}_${index}`}
              label={label}
              type={type}
              company={company}
              emailSentCounter={emailCounts[type]}
            />
          );
        }
      )}
    </Box>
  );
};

export const ServiceActionsContainer = (
  props: ServiceActionsContainerProps
) => {
  const { company } = props;
  const { data } = useGetManyReference(
    `annotations`,
    "companies",
    company.id,
    defaultPagination,
    defaultSorting,
    filterParams,
    "companies"
  );

  if (!data) {
    return null;
  }

  return (
    <ServiceActions {...props} companyEmailsData={data} company={company} />
  );
};

export default ServiceActionsContainer;
