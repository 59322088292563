import { ActionTypes } from "../../../components/CustomAction";
import {
  ServiceNames,
  LLCFormationServiceState,
  EINServiceState,
} from "../../../models/ServiceModel/ServiceEnums";
import { EmailTypes } from "../../../services/airtable_emails";

type MapT = {
  [key: string]: any;
};

export const getActions = (serviceName: string, statusName: string) => {
  const serviceDefined = MapServiceActions[serviceName];
  if (serviceDefined) {
    const statusActionsDefined = serviceDefined[statusName];
    if (statusActionsDefined) {
      return statusActionsDefined;
    }
  }

  return {
    actions: [],
    mails: [],
  };
};

const MapServiceActions = {
  [ServiceNames.llcFormation]: {
    [LLCFormationServiceState.formationPaused]: {
      actions: [],
      mails: [{ label: "Request new name", type: EmailTypes.newName }],
    },
    [LLCFormationServiceState.pendingFormation]: {
      actions: [{ label: "Form Company", type: ActionTypes.formCompany }],
      mails: [],
    },
    [LLCFormationServiceState.waitingForFormationDocs]: {
      actions: [
        // {
        //   label: "Pause",
        //   type: ActionTypes.changeServiceState,
        //   payload: { to: LLCFormationServiceState.formationPaused },
        // },
      ],
      mails: [{ label: "Filing Sent", type: EmailTypes.filingUnderReview }],
    },
    [LLCFormationServiceState.companyFormationCompleted]: {
      actions: [
        // { label: "Form Company", type: ActionTypes.formCompany },
      ],
      mails: [
        // { label: "Filing Sent", type: EmailTypes.filingUnderReview },
      ],
    },
  },
  [ServiceNames.einCreation]: {
    [EINServiceState.formationDocsReceived]: {
      actions: [
        { label: "Preview SS-4", type: ActionTypes.previewSS4 },
        {
          label: "Request SS-4 Signature",
          type: ActionTypes.requestSS4Signature,
        },
        // {
        //   label: "Pause",
        //   type: "change-secondary-status",
        //   payload: { to: EINServiceState.einPaused },
        // },
      ],
      mails: [{ label: "Formation Received", type: EmailTypes.filingReceived }],
    },
    [EINServiceState.waitingForSS4Signature]: {
      actions: [
        { label: "Preview SS-4", type: ActionTypes.previewSS4 },
        {
          label: "Request SS-4 Signature",
          type: ActionTypes.requestSS4Signature,
        },
        // { label: "Edit SS4", type: "not-defined-yet" },
      ],
      mails: [],
    },
    [EINServiceState.applyingForEINFaxToIRS]: {
      actions: [
        { label: "Preview Signed SS-4", type: ActionTypes.previewSignedSS4 },
        { label: "Fax SS-4 to IRS", type: ActionTypes.faxSS4ToIRS },
      ],
      mails: [{ label: "SS-4 faxed to IRS", type: EmailTypes.ss4FaxedToIRS }],
    },
    [EINServiceState.applyingForEINOnline]: {
      actions: [
        { label: "Preview Signed SS-4", type: ActionTypes.previewSignedSS4 },
        { label: "Apply for EIN Online", type: ActionTypes.applyForEinOnline },
        { label: "Mark EIN Received and send email", type: ActionTypes.einReceived },
      ],
      mails: [],
    },
    [EINServiceState.einResubmissionRequired]: {
      actions: [
        { label: "Mark EIN Application as Resubmitted and send email", type: ActionTypes.einResubmissionSent },
      ],
      mails: [],
    },
    [EINServiceState.waitingOnEINFromIRS]: {
      actions: [
        { label: "Mark EIN Received and send email", type: ActionTypes.einReceived },
      ],
      mails: [],
    },
    [EINServiceState.einReceived]: {
      actions: [],
      mails: [
        { label: "EIN Received Email", type: EmailTypes.einReceived },
        { label: "Trust Pilot Request", type: EmailTypes.trustPilot },
      ],
    },
  },
} as MapT;
