import { Dialog, Typography, Button } from "@material-ui/core";
import { useState } from "react";
import {
  Button as RaButton,
  SaveButton,
  Toolbar,
  SimpleForm,
  TextInput,
  useNotify,
  Create,
  useRefresh,
  useUpdate,
  Confirm,
} from "react-admin";
import { Customer } from "../../models/Company";

export const CreateCognitoButton = ({ record }: { record: Customer }) => {
  const notify = useNotify();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const refresh = useRefresh();

  const [customerAction, { loading }] = useUpdate(
    "customers/action",
    record.id,
    { action: "createCognito" },
    record,
    {
      onSuccess: (response) => {
        refresh();
        notify(response.data.message, {
          type: "success",
        });
      },

      onFailure: (response) => {
        console.log("failure");
        console.log(response);
        notify(response?.response?.data?.message, {
          type: "warning",
        });
      },
    }
  );

  const handleTrustPilotInvitationLink = () => {
    const TYPEFORM_URL = "https://doolahq.typeform.com/to/mQ1FYDIL";

    const hiddenVariables = {
      email_hidden: record.email,
      name_hidden: record.fullName,
      customer_id_hidden: record.id,
      admin_user_hidden: "admin@doola.com",
    };

    const url = `${TYPEFORM_URL}#${Object.keys(hiddenVariables)
      .map((key) => `${key}=${(hiddenVariables as any)[key]}&`)
      .join("")}`;

    window.open(url, "_blank");
  };

  return (
    <>
      <RaButton
        disabled={loading}
        onClick={() => setConfirmOpen(true)}
        label="Create cognito account manually"
      />

      <RaButton
        onClick={handleTrustPilotInvitationLink}
        label="Create TrustPilot Invitation Link"
      />

      <Confirm
        isOpen={confirmOpen}
        loading={loading}
        title="Are you sure?"
        content="Actions can not be undone."
        onConfirm={() => {
          setConfirmOpen(false);
          customerAction();
        }}
        onClose={() => setConfirmOpen(false)}
      />
    </>
  );
};

export const ExampleCustomerButton = ({ record }: { record: Customer }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <RaButton
        onClick={() => setIsOpen(true)}
        label="Example Button with form"
      />
      <Dialog
        fullWidth={true}
        open={isOpen}
        maxWidth="md"
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
      >
        <Create
          basePath="asd"
          resource="customers/action"
          record={{ id: record.id }}
          onFailure={(error) => {
            notify(error?.response?.data?.message, {
              type: "warning",
            });
          }}
          onSuccess={(response) => {
            refresh();
            setIsOpen(false);
            notify(response.data.message, {
              type: "success",
            });
          }}
        >
          <SimpleForm
            toolbar={
              <Toolbar
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                <SaveButton label="Confirm" />
              </Toolbar>
            }
            validate={async (values: any) => {
              console.log(values);
              const validationErrors: any = {};
              if (!values.test) {
                validationErrors.test = {
                  type: "invalid_type",
                  ref: undefined,
                  message: "Required",
                };
              }
              return validationErrors;
            }}
            initialValues={{ action: "createCognito" }}
            onAbort={() => setIsOpen(false)}
          >
            <Typography variant="h5">Example title</Typography>
            <br />
            <Typography>Example Description</Typography>
            <TextInput source="test" label="Test input" />
          </SimpleForm>
        </Create>
      </Dialog>
    </>
  );
};
