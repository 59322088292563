import { useState } from "react";

import {
  Datagrid,
  DateField,
  List,
  ListGuesser,
  ReferenceField,
  SearchInput,
  ShowButton,
  TextField,
} from "react-admin";
import CustomPagination from "../../components/CustomPagination";
import { CurrencyField } from "../../components/fields/CurrencyField";
import { StyledListBox } from "../../components/StyledListBox";
import { PAGE_SIZE } from "../../dataproviders/constants";

const OrderList = (props: any) => {
  const [nextToken, setNextToken] = useState<any>(null);

  return (
    <StyledListBox>
      <div style={{ display: "none" }}>
        <ListGuesser {...props} />
      </div>
      <List
        {...props}
        link="show"
        filters={[<SearchInput source="search:search" alwaysOn />]}
        sort={{ field: "createAt", order: "DESC" }}
        pagination={
          <CustomPagination setNextToken={setNextToken} nextToken={nextToken} />
        }
        filter={{ nextToken, pageSize: PAGE_SIZE }}
      >
        <Datagrid rowClick="show" isRowSelectable={(record: any) => false}>
          {/* <TextField source="id" /> */}
          <DateField source="createdAt" />

          <ReferenceField link="show" source="customerId" reference="customers">
            <TextField source="fullName" />
          </ReferenceField>
          <ReferenceField link="show" source="customerId" reference="customers">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            link="show"
            label="Company"
            source="companyId"
            reference="companies"
          >
            <TextField source="companyDetails.name" />
          </ReferenceField>
          <CurrencyField label="Amount paid" source="data.amount_paid" />
          <TextField source="data.billing_reason" label="Billing reason" />
          <TextField source="data.status" label="Billing Status" />
          {/* <EditButton /> */}
          <ShowButton />
        </Datagrid>
      </List>
    </StyledListBox>
  );
};

export default OrderList;
