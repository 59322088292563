import OrderList from "./OrderList";
import OrderShow from "./OrderShow";
import ShopIcon from "@material-ui/icons/Shop";

const orders = {
  list: OrderList,
  show: OrderShow,
  icon: ShopIcon,
};

export default orders;
