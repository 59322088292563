// Generated by ts-to-zod
// Generated by ts-to-zod
import { z } from "zod";
import { AggregatedState, CompanyStatus } from "../models/Company";
import { iCompanyServiceSchema } from "../models/ServiceModel/serviceValidation";

export const companyStatusSchema = z.nativeEnum(CompanyStatus);

export const aggregatedStateSchema = z.nativeEnum(AggregatedState);

export const migrationDataSchema = z.object({
  submissionId: z.string().optional(),
  pipelineId: z.string().optional(),
});

export const customerSchema = z.object({
  fullName: z.string(),
  id: z.string(),
  phone: z.string().optional(),
  email: z.string(),
  timezone: z.string().optional(),
});

export const mailingAddressSchema = z.object({
  linesFormatted: z.string().optional(),
  cityStateZipCodeFormatted: z.string().optional(),
  countyAndStateFormatted: z.string().optional(),
  country: z.string().optional(),
});

export const ownerSchema = z.object({
  ownership: z.string(),
  fullName: z.string(),
  isCompany: z.boolean(),
});

export const businessTypeSchema = z.object({
  name: z.string(),
  style: z.string(),
});

export const documentsSchema = z.object({
  googleDriveFolderId: z.string().optional(),
  signedSS4FileId: z.string().optional(),
  articlesFileId: z.string().optional(),
  rightSignatureDocId: z.string().optional(),
});

export const applicantAddressSchema = z.object({
  phone: z.string(),
  state: z.string(),
  address2: z.string(),
  address1: z.string(),
  postalCode: z.string(),
  country: z.string(),
  city: z.string(),
});

export const internalDetailsSchema = z.object({
  comment: z.string().optional(),
  migrationData: migrationDataSchema.optional(),
});

export const companyDetailsSchema = z.object({
  activity: z.string().optional(),
  activityOther: z.string().optional(),
  description: z.string(),
  geographicState: z.string(),
  businessType: businessTypeSchema,
  name: z.string(),
  addressType: z.string(),
  mailingAddress: mailingAddressSchema.optional(),
  url: z.string(),
  SSN: z.string().optional(),
  ITIN: z.string().optional(),
  EIN: z.string().optional(),
  registeredAgentId: z.string().optional(),
  verizonNumber: z.string().optional(),
  owners: z.array(ownerSchema),
});

export const companyBaseSchema = z.object({
  applicantAddress: applicantAddressSchema,
  documents: documentsSchema,
  companyDetails: companyDetailsSchema,
  status: z.string(),
  services: z.array(iCompanyServiceSchema),
  customer: customerSchema,
  internalDetails: internalDetailsSchema,
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const companyEditBaseSchema = z.object({
  applicantAddress: applicantAddressSchema,
  documents: documentsSchema,
  companyDetails: companyDetailsSchema,
  status: z.string(),
});

export const companyCreateBaseSchema = z.object({
  applicantAddress: applicantAddressSchema,
  documents: documentsSchema,
  companyDetails: companyDetailsSchema,
  status: z.string().optional(),
});
