import * as React from "react";
import { useState, FormEvent } from "react";
import {
  useRecordContext,
  useCreate,
  useNotify,
  useGetIdentity,
} from "react-admin";
import { TextField as TextInput, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
  small: {
    marginRight: "1em",
    "& .MuiFilledInput-input": {
      paddingTop: 10,
    },
  },
}));

export const NewNote = ({
  showStatus,
  reference,
  refetchNotes,
  record,
}: {
  showStatus?: boolean;
  reference: "customers" | "companies";
  refetchNotes: any;
  record?: any;
}) => {
  const classes = useStyles();
  const recordValue = useRecordContext({ record });
  const [text, setText] = useState("");
  const [status] = useState(recordValue && recordValue.status);
  const [create, { loading }] = useCreate();

  const notify = useNotify();
  const { identity } = useGetIdentity();
  if (!recordValue || !identity) return null;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data: any = {
      ...(reference === "customers" && {
        customerId: recordValue[foreignKeyMapping[reference]],
      }),
      ...(reference === "companies" && {
        companyId: recordValue[foreignKeyMapping[reference]],
      }),
      annotationType: "note",
      metadata: {
        content: text,
        status,
      },
    };
    if (showStatus) {
      data.status = status;
    }
    await create("annotations", data, {
      onSuccess: () => {
        refetchNotes();
      },
    });
    setText("");
    notify("Note added successfully");
    // refresh();
    return false;
  };
  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <TextInput
          label="Add a note"
          variant="filled"
          size="small"
          fullWidth
          multiline
          value={text}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setText(event.target.value)
          }
          rows={3}
        />
        <div className={classes.toolbar}>
          {/* <span>
            {text ? (
              <>
                {showStatus && (
                  <StatusSelector
                    status={status}
                    setStatus={setStatus}
                    className={classes.small}
                  />
                )}
                <TextInput
                  type="datetime-local"
                  variant="filled"
                  size="small"
                  value={date}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDate(event.target.value);
                  }}
                  className={classes.small}
                />
              </>
            ) : null}
          </span> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!text || loading}
          >
            Add this note
          </Button>
        </div>
      </form>
    </div>
  );
};

const foreignKeyMapping = {
  contacts: "contact_id",
  deals: "deal_id",
  annotations: "id",
  customers: "customerId",
  companies: "id",
};
