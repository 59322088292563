import CustomPagination from "../../components/CustomPagination";
import { StyledListBox } from "../../components/StyledListBox";
import { PAGE_SIZE } from "../../dataproviders/constants";
import {
  formatServiceName,
  mapServiceNameToColor,
} from "../../formatters/serviceFormatters";
import { CompanyStatus, PersistedCompany } from "../../models/Company";
import { useState } from "react";

import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  FunctionField,
  List,
  SelectInput,
  ShowButton,
  SimpleListLoading,
  SingleFieldList,
  TextField,
  TopToolbar,
  useGetIdentity,
  useListContext,
} from "react-admin";
import { CompanyListFilter } from "./search/CompanyListFilter";
import { ServiceNames } from "../../models/ServiceModel/ServiceEnums";
import { formatCompanyStatus } from "../../formatters/companyFormatter";
import { subDays } from "date-fns";
import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GetAppIcon from "@material-ui/icons/GetApp";
import { ApiClient } from "../../dataproviders/amplifyClass";
const API_NAME = "api";
const Api = new ApiClient(API_NAME);

const exporter = (companies: PersistedCompany[], kind = "") => {
  jsonExport(
    companies,
    {
      // headers: ["id", "title", "author_name", "body"], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, `company_export_${kind}_${new Date().toJSON()}`); // download as 'posts.csv` file
    }
  );
};
const exportPage = (companies: PersistedCompany[]) => {
  const postsForExport = companies.map((company) => ({
    Id: company?.id || "",
    "Company Name": company?.companyDetails?.name || "",
    "Company State": company?.companyDetails?.geographicState || "",
    "Created At": company?.createdAt || "",
    Services:
      company?.services?.map((service) => service?.name).join(", ") || "",
    "Date Paid": company?.createdAt || "",
    Email: company?.customer?.email || "",

    "GDrive Folder": company?.documents?.googleDriveFolderId || "",
    Members: company?.companyDetails?.owners?.map(
      (owner) =>
        `${(owner?.isCompany ? "Company: " : "Member ") + owner?.fullName} (${
          owner?.ownership
        }%)`
    ),
    Notes: company?.internalDetails?.comment,
    Status: company?.status || "",
    ServiceStatus: company?.aggregatedState || "",
  }));
  jsonExport(postsForExport, {}, (err, csv) => {
    downloadCSV(csv, `company_export_page_${new Date().toJSON()}`); // download as 'posts.csv` file
  });
};

const ExportMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const companiesExport = async (kind: string) => {
    const queryString = kind === "verified" ? "?status=verified" : "";
    const companies = await Api.get("/companies/export" + queryString);
    console.log(companies);
    exporter(companies.data.items, kind);
  };
  return (
    <div>
      <ExportButton label="Export Page" exporter={exportPage} />
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <GetAppIcon fontSize="small" /> Export
      </Button>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => companiesExport("all")}>
          <ListItemIcon>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>Export all</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => companiesExport("verified")}>
          <ListItemIcon>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText> Export verified companies</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

const ListActions = () => {
  return (
    <TopToolbar>
      <ExportMenu />
    </TopToolbar>
  );
};

const CompanyList = (props: any) => {
  const [nextToken, setNextToken] = useState<any>(null);

  const { identity } = useGetIdentity();
  return identity ? (
    <StyledListBox>
      {/* <div style={{ display: "none" }}>
        <ListGuesser {...props} />
      </div> */}
      <List
        link="show"
        {...props}
        filterDefaultValues={{ "global:status": "verified" }}
        sort={{ field: "createAt", order: "DESC" }}
        pagination={
          <CustomPagination setNextToken={setNextToken} nextToken={nextToken} />
        }
        actions={<ListActions />}
        filters={[
          <SelectInput
            label="Status"
            source="global:status"
            allowEmpty
            onChange={() => {
              setNextToken(null);
            }}
            emptyValue={CompanyStatus.verified}
            choices={[
              { id: "all", name: "All" },
              ...Object.values(CompanyStatus).map((item) => ({
                id: item,
                name: formatCompanyStatus(item),
              })),
            ]}
            alwaysOn
          />,
        ]}
        filter={{ nextToken, pageSize: PAGE_SIZE }}
        aside={<CompanyListFilter />}
        perPage={25}
        linkType={"show"}
      >
        <CompanyListContent />
      </List>
    </StyledListBox>
  ) : null;
};

const CompanyListContent = () => {
  const { loaded } = useListContext<PersistedCompany>();
  if (loaded === false) {
    return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
  }
  return (
    <Datagrid
      rowClick="show"
      isRowSelectable={(record: any) => false}
      rowStyle={(record: any) =>
        record?.renewalDetails?.renewalDate &&
        subDays(new Date(record.renewalDetails.renewalDate), 30) < new Date()
          ? { backgroundColor: "#ffcf33" }
          : {}
      }
    >
      <TextField source="companyDetails.name" label="Name" />
      <FunctionField
        render={(record: any) => {
          return record?.companyDetails?.geographicState;
        }}
        label="Company State"
      />
      <TextField source="companyDetails.businessType.style" label="LLC Style" />
      <ArrayField source="services">
        <SingleFieldList linkType={false}>
          <FunctionField
            render={(record: any) => {
              return (
                <ChipField
                  {...{ to: record.id }}
                  record={{
                    id: record.id,
                    name: formatServiceName(record.name),
                  }}
                  textAlign="left"
                  style={{
                    backgroundColor: mapServiceNameToColor(
                      record.name as ServiceNames
                    ),
                  }}
                  source="name"
                />
              );
            }}
          />
        </SingleFieldList>
      </ArrayField>
      <TextField source="aggregatedState" label="Company status" />
      <TextField source="status" label="Aggregated state" />
      <TextField
        source="invoiceDetails.lastInvoiceStatus"
        label="Last invoice"
      />
      <DateField source="createdAt" />
      <DateField
        source="annualReport.upcomingReport"
        label="Upcoming annual report"
      />

      {/* <ReferenceField  link="show"source="driveId" reference="drives">
            <TextField source="id" />
          </ReferenceField> */}
      <EditButton />
      <ShowButton />
    </Datagrid>
  );
};

export default CompanyList;
