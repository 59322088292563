import { DBBaseItem } from "./Common";

export enum CustomerStatus {
  unsubmitted = "unsubmitted",
  verified = "verified",
  blocked = "blocked",
  canceled = "canceled",
}

export enum MigrationStatuses {
  unmigrated = "unmigrated",
  migratedUnverified = "migratedUnverified",
  verified = "verified",
}

export interface InternalCustomerDetails {
  // For airtable migration and debugging purposes
  airtablePipelineId?: string;
  migrationStatus?: MigrationStatuses;
  createdByEmail?: string;
}

export interface CustomerBase {
  status: CustomerStatus;
  fullName: string;
  email: string;
  phone?: string;
  companyIds: string[];
  intercomId?: string;
  referredBy?: string;
  referrals?: string[];
  cognitoId?: string;
  stripeId?: string;
  customerIoId?: string;
  lastCustomerIoSync?: string;
  unsubscribeWelcomeEmail?: boolean;
  internalDetails?: InternalCustomerDetails;
  timezone?: string;
}

export type PersistedCustomer = CustomerBase & DBBaseItem;
