import { useState, FormEvent, ChangeEvent } from "react";
import { DateField, useResourceContext, useUpdate } from "react-admin";
import { Typography, FilledInput, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  metadata: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  textarea: {
    paddingTop: 16,
    paddingLeft: 14,
    paddingRight: 60,
    paddingBottom: 14,
    lineHeight: 1.3,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  cancel: {
    marginRight: theme.spacing(1),
  },
  content: {
    backgroundColor: "#edf3f0",
    padding: "0 1em",
    borderRadius: 10,
    display: "flex",
    alignItems: "stretch",
    marginBottom: theme.spacing(1),
  },
  text: {
    flex: 1,
  },
  paragraph: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: 1.3,
    marginBottom: theme.spacing(2.4),
  },
  toolbar: {
    marginLeft: theme.spacing(2),
    visibility: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
}));

export const Note = ({
  showStatus,
  note,
  isLast,
  reference,
}: {
  showStatus?: boolean;
  note: any;
  isLast: boolean;
  reference: string;
}) => {
  const [isEditing, setEditing] = useState(false);
  const [noteText, setNoteText] = useState(note?.metadata?.content || "");
  const resource = useResourceContext();
  const classes = useStyles();
  const [update, { loading }] = useUpdate();

  const handleCancelEdit = () => {
    setEditing(false);
    setNoteText(note?.metadata?.content || "");
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNoteText(event.target.value);
  };

  const handleNoteUpdate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    update(resource, note.id, { content: noteText }, note, {
      onSuccess: () => {
        setEditing(false);
        setNoteText(note.content);
      },
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.metadata}>
        {note?.createdById}{" "}
        <Typography component="span" variant="body1">
          {note.annotationType === "email"
            ? "sent a mail on"
            : " added a note on"}{" "}
        </Typography>
        <DateField
          source="createdAt"
          record={note}
          variant="body1"
          showTime
          locales="en"
          options={{
            dateStyle: "full",
            timeStyle: "short",
          }}
        />{" "}
        {/* {showStatus && <Status status={note.metadata.status} />} */}
      </div>
      {isEditing ? (
        <form onSubmit={handleNoteUpdate}>
          <FilledInput
            value={noteText}
            onChange={handleTextChange}
            fullWidth
            multiline
            className={classes.textarea}
            autoFocus
          />
          <div className={classes.buttons}>
            <Button
              className={classes.cancel}
              onClick={handleCancelEdit}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading}
            >
              Update Note
            </Button>
          </div>
        </form>
      ) : (
        <div className={classes.content}>
          <div className={classes.text}>
            {(note.annotationType === "email"
              ? note?.metadata?.subject // TODO Email Type
              : note?.metadata?.content || ""
            )
              .split("\n")
              .map((paragraph: string, index: number) => (
                <p className={classes.paragraph} key={index}>
                  {paragraph}
                </p>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
