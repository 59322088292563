import { EditButton, ShowButton } from "react-admin";

import { CurrencyField } from "../../components/fields/CurrencyField";
import {
  Datagrid,
  DateField,
  TextField,
  SimpleShowLayout,
  ArrayField,
  BooleanField,
  ReferenceManyField,
} from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box, CardHeader, Divider, Grid, makeStyles } from "@material-ui/core";

import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import ShareIcon from "@material-ui/icons/Share";
import SaveIcon from "@material-ui/icons/Save";
import { CompanyMailSpeedDial } from "./components/MailSpeedDial";
import { CompanyActionSpeedDial } from "./components/SpeedDial";
import { PersistedCompany } from "../../models/Company";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    width: "100%",
    padding: 0,
  },
  inlineFieldHalf: {
    display: "block",
    // width: "50%",
    flexGrow: 0,
    // maxWidth: "50%",
    flexBasis: "50%",
  },
  inlineFieldFull: {
    display: "block",

    flexGrow: 0,
    maxWidth: "100%",
    flexBasis: "100%",
  },
}));

export const CompanyShowAside = ({
  record,
  link = "edit",
}: {
  record: PersistedCompany;
  link?: string;
}) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box ml={4} width="100%" minWidth={350} maxWidth={450}>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={5}>
          <CompanyActionSpeedDial
            actions={[
              ...(record?.documents?.googleDriveFolderId
                ? [
                    {
                      icon: <FileCopyIcon />,
                      tooltip: "Google Drive",
                      name: "Open Drive",
                      action: () => {
                        window.open(
                          `https://drive.google.com/drive/folders/${record.documents.googleDriveFolderId}`,
                          "_blank"
                        );
                      },
                    },
                  ]
                : []),
              {
                icon: <SaveIcon />,
                name: "Edit",
                tooltip: "Edit",
                action: () => {
                  history.push(`/companies/${record.id}`);
                },
              },
              // {
              //   icon: <SaveIcon />,
              //   name: "Sync intercom",
              //   tooltip: "Sync intercom",
              //   action: () => {
              //     // history.push(`/companies/${record.id}`);
              //   },
              // },
              // { icon: <PrintIcon />, name: "Print", tooltip: "Hello Word" },

              {
                icon: <ShareIcon />,
                tooltip: "View in Airtable",
                name: "View in Airtable",
                action: () => {
                  window.open(
                    `https://airtable.com/appqmOtqYbhmS0gkK/tblHOaP7gLiF5HdJf/viw1Owgr2nhECU13g/${record.internalDetails.migrationData?.pipelineId}`,
                    "_blank"
                  );
                },
              },
            ]}
          />
        </Grid>
        <Grid item xs={5}>
          <CompanyMailSpeedDial
            actions={[
              {
                icon: <FileCopyIcon />,
                tooltip: "Still in Todo",
                name: "Still in Todo",
                action: () => {
                  window.open("https://www.google.com", "_blank");
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      <Card style={{ marginTop: 16 }}>
        <CardHeader
          style={{ paddingBottom: 0 }}
          title="Customer overview"
          titleTypographyProps={{ variant: "subtitle2" }}
        />
        <CardContent>
          <Divider />

          <Box mt={1}>
            <Typography variant="body2" color="textSecondary" component="span">
              Full name
            </Typography>
            <br />
            <TextField source="customer.fullName" />
          </Box>
          <Box mt={1}>
            <Typography variant="body2" color="textSecondary" component="span">
              E-Mail
            </Typography>
            <br />
            <TextField source="customer.email" />
          </Box>
          <Box mt={1}>
            <Typography variant="body2" color="textSecondary" component="span">
              Phone
            </Typography>
            <br />
            <TextField source="customer.phone" />
          </Box>

          <Box mt={1} mb={2}>
            <Typography variant="body2" color="textSecondary" component="span">
              Created at
            </Typography>
            <br />
            <DateField source="createdAt" />
            <br />
            <ShowButton
              basePath="/customers"
              record={record.customer}
              label="Show Customer"
            />
            <EditButton
              disabled={true}
              basePath="/customers"
              record={record}
              label="Edit Customer"
            />
          </Box>
          <Divider />
          <Typography variant="subtitle2">Company Details</Typography>
          <EditButton
            basePath="/companies"
            record={record}
            label="Edit Company"
          />
          <SimpleShowLayout className={classes.container}>
            <TextField
              source="companyDetails.activity"
              label="Company Activity"
              emptyText="-"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="companyDetails.activityOther"
              label="Company Activity (Other)"
              emptyText="-"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="companyDetails.url"
              label="Website"
              emptyText="-"
              className={classes.inlineFieldHalf}
            />
            <Divider />
            {/* <TextField source="status" className={classes.inlineFieldFull} /> */}
            <TextField
              source="applicantAddress.address1"
              label="Address Line 1"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.address2"
              label="Address Line 2"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.city"
              label="Address City"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.country"
              label="Country"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.phone"
              label="Phone"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.postalCode"
              label="Postal code"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.state"
              label="State"
              className={classes.inlineFieldHalf}
            />

            <TextField
              source="companyDetails.mailingAddress.linesFormatted"
              label="Mailing Address Lines"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="companyDetails.mailingAddress.cityStateZipCodeFormatted"
              label="Mailing Address City, State, Postal Code"
              className={classes.inlineFieldHalf}
            />

            <ArrayField
              source="companyDetails.owners"
              // record={}
              label="Company Members"
              // className={classes.inlineFieldFull}
            >
              <Datagrid>
                <TextField source="fullName" />
                <TextField source="ownership" />
                <BooleanField source="isCompany" />
              </Datagrid>
            </ArrayField>

            <BooleanField
              source="annualReport.emailed"
              label="Annual report emailed"
              className={classes.inlineFieldHalf}
            />
            <BooleanField
              source="annualReport.charged"
              label="Annual report charged"
              className={classes.inlineFieldHalf}
            />

            {/* <ReferenceArrayField emptyText="-" source="driveId" reference="documents">
        <TextField source="name" />
      </ReferenceArrayField> */}
          </SimpleShowLayout>
        </CardContent>
      </Card>
      <Divider />
      <Card style={{ marginTop: 20 }}>
        <CardHeader
          style={{ paddingBottom: 0 }}
          title="Customer invoices"
          titleTypographyProps={{ variant: "subtitle2" }}
        />
        <CardContent>
          <ReferenceManyField
            target="companies"
            reference="orders"
            sort={{ field: "date", order: "DESC" }}
          >
            <Datagrid rowClick={"show"}>
              <CurrencyField label="Amount paid" source="data.amount_paid" />
              <TextField source="data.billing_reason" label="Billing reason" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </CardContent>
        <CardContent>
          Last status update:
          <b>
            <TextField
              source="invoiceDetails.lastInvoiceStatus"
              label="Last invoice status"
              style={{ fontWeight: "bold" }}
              className={classes.inlineFieldFull}
            />
          </b>
          Last update:
          <b>
            <DateField
              source="invoiceDetails.updatedAt"
              label="Last update"
              className={classes.inlineFieldFull}
            />
          </b>
          {record.vendorPayments && record.vendorPayments.length > 0 && (
            <>
              {record.vendorPayments[0].stripeSubscriptionId ? `Stripe: ` : `Paypal: ` }
              <b>
              <br/>
              {record.vendorPayments[0].stripeSubscriptionId ? record.vendorPayments[0].stripeSubscriptionId : record.vendorPayments[0].payPalSubscriptionId }
              </b>
            </>
            )}
        </CardContent>
      </Card>
    </Box>
  );
};
