import { useState } from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  List,
  ReferenceField,
  SearchInput,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar,
} from "react-admin";
import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GetAppIcon from "@material-ui/icons/GetApp";
import CustomPagination from "../../components/CustomPagination";
import { PAGE_SIZE } from "../../dataproviders/constants";
import { StyledListBox } from "../../components/StyledListBox";
import { CustomerStatus, PersistedCustomer } from "../../models/Customer";
import { ApiClient } from "../../dataproviders/amplifyClass";
const API_NAME = "api";
const Api = new ApiClient(API_NAME);

const exporter = (customers: PersistedCustomer[], kind = "") => {
  jsonExport(
    customers,
    {
      // headers: ["id", "title", "author_name", "body"], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, `customer_export_${kind}_${new Date().toJSON()}`); // download as 'posts.csv` file
    }
  );
};
const exportPage = (customers: PersistedCustomer[]) => {
  const postsForExport = customers.map((customer) => ({
    Id: customer?.id || "",
    "Full name": customer?.fullName || "",
    Companies: customer?.companyIds?.join(", ") || "",
    "Created At": customer?.createdAt || "",
    Email: customer?.email || "",
    "Intercom Id": customer?.intercomId || "",
    Location: customer?.timezone || "",
    Phone: customer?.phone || "",
    "First Visit": "Created At" || "",
    Status: customer?.status || "",
  }));
  jsonExport(postsForExport, {}, (err, csv) => {
    downloadCSV(csv, `customer_export_page_${new Date().toJSON()}`); // download as 'posts.csv` file
  });
};

const ExportMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const companiesExport = async (kind: string) => {
    const queryString = kind === "verified" ? "?status=verified" : "";
    const companies = await Api.get("/customers/export" + queryString);
    console.log(companies);
    exporter(companies.data.items, kind);
  };
  return (
    <div>
      <CreateButton />
      <ExportButton label="Export Page" exporter={exportPage} />
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <GetAppIcon fontSize="small" /> Export
      </Button>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => companiesExport("all")}>
          <ListItemIcon>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>Export all</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => companiesExport("verified")}>
          <ListItemIcon>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText> Export verified customers</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

const ListActions = () => {
  return (
    <TopToolbar>
      {" "}
      <ExportMenu />
    </TopToolbar>
  );
};

const CustomerList = (props: any) => {
  const [nextToken, setNextToken] = useState<any>(null);
  return (
    <StyledListBox>
      {/* <div style={{ display: "none" }}>
        <ListGuesser {...props} />
      </div> */}
      <List
        {...props}
        filters={[
          <SearchInput source="search:search" alwaysOn />,
          <SelectInput
            label="Status"
            source="global:status"
            allowEmpty
            onChange={() => {
              setNextToken(null);
            }}
            emptyValue={CustomerStatus.verified}
            choices={[
              { id: "all", name: "All" },
              { id: CustomerStatus.verified, name: "Verified (Paid)" },
              { id: CustomerStatus.blocked, name: "Blocked" },
              { id: CustomerStatus.unsubmitted, name: "Unsubmitted" },
            ]}
            alwaysOn
          />,
        ]}
        actions={<ListActions />}
        filterDefaultValues={{ "global:status": "verified" }}
        sort={{ field: "createAt", order: "DESC" }}
        pagination={
          <CustomPagination setNextToken={setNextToken} nextToken={nextToken} />
        }
        filter={{ nextToken, pageSize: PAGE_SIZE }}
      >
        <Datagrid rowClick="show" isRowSelectable={(record: any) => false}>
          <TextField source="fullName" />
          <TextField source="email" />
          <TextField source="phone" />
          <ReferenceField
            link="show"
            label="Companies"
            source="companyIds"
            reference="companies"
          >
            <TextField source="companyDetails.name" />
          </ReferenceField>
          <DateField source="createdAt" />
          <TextField source="status" />
          <EditButton />
          <ShowButton />
        </Datagrid>
      </List>
    </StyledListBox>
  );
};

export default CustomerList;
