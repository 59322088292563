import {
  DateField,
  TextField,
  Datagrid,
  ShowProps,
  ReferenceManyField,
  FunctionField,
  useRefresh,
  useGetOne,
  Refetch,
  Loading,
  DeleteButton,
  Empty,
} from "react-admin";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CompanyAside } from "./../companies/CompanyAside";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@material-ui/core";
import {
  formatServiceName,
  formatServiceStatus,
  mapServiceNameToColor,
} from "../../formatters/serviceFormatters";
import { CurrencyField } from "../../components/fields/CurrencyField";
import { ColoredChipField } from "../customers/TagsList";
import { ServiceStateEdit } from "./ServiceStatusListEdit";
import { PersistedCompany } from "../../models/Company";
import { NotesIterator } from "../notes";
import { ServiceActionsContainer } from "../inbox/components/ServiceActions";
import { buildService } from "../inbox/services";

export const ServiceShow = (props: ShowProps) => {
  const serviceId = props.id;
  const { data: companyRecord, loading, refetch } = useGetOne<PersistedCompany>(
    "companies",
    serviceId?.split("_")[1]!
  );
  const service =
    companyRecord?.services[
      companyRecord?.services?.findIndex(
        (service: any) => service.id === serviceId
      )
    ];

  if (!companyRecord) {
    return <Empty />;
  }

  return loading ? (
    <Loading />
  ) : (
    <ServiceShowContent
      company={companyRecord}
      service={service}
      refetch={refetch}
    />
  );
};

const ServiceShowContent = (props: {
  company: PersistedCompany;
  service: any;
  refetch: Refetch;
}) => {
  const { company, service } = props;
  const refresh = useRefresh();
  if (!company) return null;

  return (
    <Box mt={2} display="flex">
      {/* <div style={{ display: "none" }}>
        <ShowGuesser {...props} />
      </div> */}
      <Box flex="1">
        <Card>
          <CardContent>
            <Box display="flex">
              {/* <Avatar record={record} /> */}
              <Box ml={2} flex="1">
                <Typography variant="h5">
                  {formatServiceName(service.name)}
                </Typography>
                <Typography variant="body2">
                  {service.title}
                  Company: {company.companyDetails.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  Created at: <DateField record={company} source="createdAt" />
                </Typography>
                <DeleteButton
                  mutationMode="optimistic"
                  basePath="services"
                  record={service}
                  redirect={"/"}
                />
              </Box>
            </Box>
            <Box ml={1}>
              <Typography variant="subtitle1">
                <FunctionField
                  label="Service"
                  record={service}
                  render={(_record: any) => (
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <ColoredChipField
                        style={{ marginRight: "auto", float: "left" }}
                        record={{
                          name: formatServiceStatus(service.status),
                          color: mapServiceNameToColor(service.name),
                        }}
                        source="name"
                        variant="outlined"
                        size="small"
                      />
                      <Box style={{ marginLeft: "auto", float: "right" }}>
                        <ServiceStateEdit
                          record={service}
                          refetchCompany={refresh}
                        />
                      </Box>
                    </Box>
                  )}
                />
              </Typography>
            </Box>

            <Box ml={2} mt={2}>
              <Typography variant="subtitle1">Details</Typography>
              <Divider />

              <Typography variant="subtitle2">
                Service State: {service.serviceState}
                <br />
              </Typography>
            </Box>

            <Box ml={2} mt={2}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="members-content"
                  id="members-header"
                >
                  Company Files
                </AccordionSummary>
                <AccordionDetails>
                  <ReferenceManyField
                    record={company}
                    target="companies"
                    label=""
                    source="id"
                    reference="documents"
                  >
                    <Datagrid rowClick={(params) => "#"}>
                      <TextField source="id" />
                      <TextField source="name" />
                      <TextField source="mimeType" />
                    </Datagrid>
                  </ReferenceManyField>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="members-content"
                  id="members-header"
                >
                  Related Orders
                </AccordionSummary>
                <AccordionDetails>
                  <ReferenceManyField
                    label=""
                    reference="orders"
                    record={company}
                    // source="companyId"
                    target="companies"
                  >
                    <Datagrid rowClick="show">
                      <TextField source="invoiceId" />
                      <CurrencyField
                        label="Amount paid"
                        source="data.amount_paid"
                      />

                      <DateField source="createdAt" />
                    </Datagrid>
                  </ReferenceManyField>
                </AccordionDetails>
              </Accordion>
              <Typography variant="subtitle1"></Typography>
              <Divider />
            </Box>

            <Box ml={2} mt={2}>
              {/* <NewNote showStatus={true} reference={"companies"} /> */}
            </Box>
          </CardContent>
        </Card>

        <Card style={{ marginTop: 20 }}>
          <CardHeader
            style={{ paddingBottom: 0 }}
            title="Next Service Action"
          />
          <CardContent>
            <ServiceActionsContainer
              service={buildService(service)}
              company={company}
            />
          </CardContent>
        </Card>
        <Card style={{ marginTop: 20 }}>
          <CardHeader style={{ paddingBottom: 0 }} title="Notes" />
          <CardContent>
            <ReferenceManyField
              record={company}
              target="companies"
              reference="annotations"
              sort={{ field: "date", order: "DESC" }}
            >
              <NotesIterator
                showStatus
                reference="companies"
                record={company}
              />
            </ReferenceManyField>
          </CardContent>
        </Card>
      </Box>
      <CompanyAside link="show" record={company} />
    </Box>
  );
};

export default ServiceShow;
