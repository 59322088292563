import { Button, makeStyles } from "@material-ui/core";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import { debounce, throttle } from "lodash";
import { useState } from "react";
import MailIcon from "@material-ui/icons/Mail";
const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
  },

  speedDial: {
    position: "absolute",
    marginLeft: "5%",
  },
}));
export const CompanyMailSpeedDial = (props: any) => {
  const { actions } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const debounceOpen = throttle(() => setOpen(true), 1000);
  const debounceClose = debounce(() => setOpen(false), 6000);

  return (
    <>
      <Button
        variant="outlined"
        style={{ color: "black" }}
        onClick={() => {
          setOpen(!open);
        }}
        fullWidth
        onMouseOver={() => debounceOpen()}
        onMouseOut={() => debounceClose()}
      >
        <MailIcon /> Mail Templates
      </Button>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        hidden={true}
        open={open}
        direction={"down"}
      >
        {actions?.map((action: any) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipOpen
            onClick={() => {
              action.action();
              setOpen(false);
            }}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </>
  );
};
