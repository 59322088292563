import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styled from "styled-components";

export const useCompanyEditStyles = makeStyles((theme) => ({
  wrapperEditCompany: {
    maxWidth: "800px",
    justifyItems: "center",
    margin: "auto",
    marginTop: "30px",
  },
  container: {
    "& div": {
      width: "100%",
    },
  },
}));

export const useCompanyCreationStyles = makeStyles((theme) => ({
  wrapperCreateCompany: {
    maxWidth: "500px",
    justifyItems: "center",
    margin: "auto",
    marginTop: "30px",
  },
  container: {
    "& div": {
      width: "100%",
    },
  },
}));

export const StyledAlert = styled(Alert)`
  .MuiAlert-icon {
    width: 20px;
  }
`;
