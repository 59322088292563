import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

type StyledBusinessTypeLabelProps = {
  color?: string;
};

export const StyledBusinessTypeLabel = styled.div<StyledBusinessTypeLabelProps>`
  padding: 5px;
  background: ${(props) => props.color || "inherit"};
  border-radius: 4px;
  margin: auto;
  margin-left: 0px;
  width: fit-content;
  border: 1px solid lightgrey;
`;

export const inboxListStyles = makeStyles({
  cardService: {
    marginBottom: "1em",
    // paddingTop: 4,
    // padding: 20,
  },
  cardServiceActionArea: {
    paddingBottom: 0,
    padding: 20,
  },
  linearProgress: {
    // position: "absolute",
    width: "100%",
    top: "0px",
    left: "0px",
  },
  businessTypeLabel: {
    padding: "10px",
    background: "red",
  },
  lastUpdate: {
    float: "right",
    color: "grey",
    textAlign: "right",
  },
  goToDriveWrapper: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});
