/* eslint-disable import/no-anonymous-default-export */
import {
  FilterList,
  FilterLiveSearch,
  FilterListItem,
  useListFilterContext,
} from "react-admin";
import { Box } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import RoomIcon from "@material-ui/icons/Room";
import {
  endOfYesterday,
  startOfWeek,
  startOfMonth,
  subMonths,
  addMonths,
  endOfToday,
} from "date-fns";
import { Status } from "./../StatusColor";
import { ServiceNames } from "../../../models/ServiceModel/ServiceEnums";
import { formatServiceName } from "../../../formatters/serviceFormatters";
import { SearchApi } from "../../../dataproviders/search";
import { useState, useEffect } from "react";
import { formatStateKey } from "../../../formatters/stateFormatter";
import { ApiClient } from "../../../dataproviders/amplifyClass";
import { formatCompanyAggregatedState } from "../../../formatters/companyFormatter";
const API_NAME = "api";
const Api = new ApiClient(API_NAME);

export const CompanyListFilter = () => {
  const [facets, setFacets] = useState<Record<string, Record<string, number>>>(
    {}
  );
  const [searchAuth, setSearchAuth] = useState<Record<string, string>>({});
  const { filterValues } = useListFilterContext();
  useEffect(() => {
    Api.get("/cognito/search").then((response) => {
      setSearchAuth(response.data);
    });
  }, []);
  useEffect(() => {
    if (!searchAuth?.appId) {
      return undefined;
    } else {
      (window as any).searchCreds = searchAuth;
    }

    const fetchFacets = async () => {
      const searchClient = new SearchApi({
        appId: searchAuth?.appId,
        apiKey: searchAuth?.apiKey,
        index: "company",
        facets: ["*"],
      });

      const [, searchQuery, facetQuery, facetKeys] = SearchApi.queryBuilder(
        filterValues
      );
      const facetsData = await searchClient.getFilterCount(
        searchQuery as any,
        facetQuery as any,
        facetKeys as any
      );
      if (facetsData.facets) {
        setFacets(facetsData.facets);
      }
    };

    fetchFacets();
  }, [filterValues, searchAuth, searchAuth?.appId]);

  return (
    <Box width="15em" minWidth="250px" order="-1" marginRight="1em">
      <FilterLiveSearch source="search:search" />
      {/* <Button onClick={() => setFilters({}, {})} label="Reset x" /> */}
      <FilterList label="Last seen" icon={<AccessTimeIcon />}>
        <FilterListItem
          key="Today"
          label="Today"
          value={{
            "numeric:createdAtSearch>": endOfYesterday().getDate() / 1000,
            "numeric:createdAtSearch<": undefined,
          }}
        />
        <FilterListItem
          key="This week"
          label="This week"
          value={{
            "numeric:createdAtSearch>":
              startOfWeek(new Date()).getTime() / 1000,
            "numeric:createdAtSearch<": undefined,
          }}
        />
        <FilterListItem
          key="Last 30 days"
          label="Last 30 days"
          value={{
            "numeric:createdAtSearch>": undefined,
            "numeric:createdAtSearch<":
              subMonths(new Date(), 1).getTime() / 1000,
          }}
        />
      </FilterList>
      <FilterList
        key="compstat"
        label={`Company Status (${
          facets?.aggregatedState &&
          Object.entries(facets.aggregatedState)?.reduce((prev, curr) => {
            return prev + curr[1];
          }, 0)
        })`}
        icon={<TrendingUpIcon />}
      >
        {facets?.aggregatedState &&
          Object.entries(facets.aggregatedState).map(
            ([aggregatedState, count]) => (
              <FilterListItem
                key={"status" + aggregatedState}
                label={
                  <div style={{ textTransform: "capitalize" }}>
                    {formatCompanyAggregatedState(aggregatedState as any)} (
                    {count || "0"}) <Status kind="company" status="hot" />
                  </div>
                }
                value={{
                  [`facet:aggregatedState`]: aggregatedState,
                }}
              />
            )
          )}
      </FilterList>
      <FilterList label="Company State" icon={<RoomIcon />}>
        {facets?.["companyDetails.geographicState"] &&
          Object.entries(facets["companyDetails.geographicState"]).map(
            ([companyStateAbbreviated, count]) => (
              <FilterListItem
                key={"state" + companyStateAbbreviated}
                label={
                  <div style={{ textTransform: "capitalize" }}>
                    {formatStateKey(companyStateAbbreviated) ||
                      companyStateAbbreviated}{" "}
                    ({count || "0"}){" "}
                  </div>
                }
                value={{
                  [`facet:companyDetails.geographicState`]: companyStateAbbreviated,
                }}
              />
            )
          )}
      </FilterList>
      <FilterList label="Service" icon={<LocalOfferIcon />}>
        {facets?.["services.name"] &&
          Object.entries(facets["services.name"]).map(
            ([serviceName, count]) => (
              <FilterListItem
                key={"service" + serviceName}
                label={
                  <div style={{ textTransform: "capitalize" }}>
                    {formatServiceName(serviceName as ServiceNames)} (
                    {count || "0"}){" "}
                    <Status kind="service" status={serviceName} />
                  </div>
                }
                value={{
                  "facet:services.name": serviceName,
                }}
              />
            )
          )}
      </FilterList>
      <FilterList label="Master Views" icon={<LocalOfferIcon />}>
        {facets?.["companyDetails.hasITIN"] &&
          Object.entries(facets["companyDetails.hasITIN"]).map(
            ([hasItin, count]) => (
              <FilterListItem
                key={"hasITIN" + hasItin}
                label={
                  <>
                    {hasItin === "true"
                      ? "ITIN Number exists"
                      : "ITIN Number missing"}{" "}
                    ({count || "0"}){" "}
                  </>
                }
                value={{
                  [`facet:companyDetails.hasITIN`]: hasItin,
                }}
              />
            )
          )}
        {facets?.["companyDetails.hasSSN"] &&
          Object.entries(facets["companyDetails.hasSSN"]).map(
            ([hasSSN, count]) => (
              <FilterListItem
                key={"hasITIN" + hasSSN}
                label={
                  <>
                    {hasSSN === "true"
                      ? "SSN Number exists"
                      : "SSN Number missing"}{" "}
                    ({count || "0"}){" "}
                  </>
                }
                value={{
                  [`facet:companyDetails.hasSSN`]: hasSSN,
                }}
              />
            )
          )}
        {facets?.["companyDetails.hasEIN"] &&
          Object.entries(facets["companyDetails.hasEIN"]).map(
            ([hasEIN, count]) => (
              <FilterListItem
                key={"hasEIN" + hasEIN}
                label={
                  <>
                    {hasEIN === "true"
                      ? "EIN Number exists "
                      : "EIN Number missing"}
                    ({count || "0"}){" "}
                  </>
                }
                value={{
                  [`facet:companyDetails.hasEIN`]: hasEIN,
                }}
              />
            )
          )}
        {facets?.["renewalDetails.charged"] &&
          Object.entries(facets["renewalDetails.charged"]).map(
            ([renewalCharged, count]) => (
              <FilterListItem
                key={"renewalCharged" + renewalCharged}
                label={
                  <>
                    {renewalCharged
                      ? "Renewal is charged"
                      : "Renewal uncharged"}{" "}
                    ({count || "0"}){" "}
                  </>
                }
                value={{
                  [`facet:renewalDetails.charged`]: renewalCharged,
                }}
              />
            )
          )}
        {facets?.["renewalDetails.emailed"] &&
          Object.entries(facets["renewalDetails.emailed"]).map(
            ([renewalEmailed, count]) => (
              <FilterListItem
                key={"renewalEmailed" + renewalEmailed}
                label={
                  <>
                    {renewalEmailed
                      ? "Renewal is emailed"
                      : "Renewal not emailed"}{" "}
                    ({count || "0"}){" "}
                  </>
                }
                value={{
                  [`facet:renewalDetails.emailed`]: renewalEmailed,
                }}
              />
            )
          )}

        <FilterListItem
          key="Renewal before Today"
          label="Renewal before Today"
          value={{
            "numeric:renewalDetails.renewalDateSearch>": undefined,
            "numeric:renewalDetails.renewalDateSearch<":
              endOfToday().getTime() / 1000,
          }}
        />
        <FilterListItem
          key="Renewal in the next two Months"
          label="Renewal in the next two Months"
          value={{
            "numeric:renewalDetails.renewalDateSearch>": undefined,

            "numeric:renewalDetails.renewalDateSearch<":
              addMonths(startOfMonth(new Date()), 2).getTime() / 1000,
          }}
        />
      </FilterList>
      <FilterList label="Last invoice status" icon={<LocalOfferIcon />}>
        {facets?.["invoiceDetails.lastInvoiceStatus"] &&
          Object.entries(facets["invoiceDetails.lastInvoiceStatus"]).map(
            ([serviceName, count]) => (
              <FilterListItem
                key={"service" + serviceName}
                label={
                  <div style={{ textTransform: "capitalize" }}>
                    {formatServiceName(serviceName as ServiceNames)} (
                    {count || "0"}){" "}
                  </div>
                }
                value={{
                  "facet:invoiceDetails.lastInvoiceStatus": serviceName,
                }}
              />
            )
          )}
      </FilterList>
      <FilterList label="Service Actions" icon={<TrendingUpIcon />}>
        {facets?.["services.status"] &&
          Object.entries(facets["services.status"]).map(
            ([serviceStatus, count]) => (
              <FilterListItem
                key={"servicestatus" + serviceStatus}
                label={
                  <div style={{ textTransform: "capitalize" }}>
                    {serviceStatus} ({count || "0"}){" "}
                    <Status kind="company" status="hot" />
                  </div>
                }
                value={{
                  [`facet:services.status`]: serviceStatus,
                }}
              />
            )
          )}
      </FilterList>
      <FilterList label="Service Status" icon={<TrendingUpIcon />}>
        {facets?.["services.serviceState"] &&
          Object.entries(facets["services.serviceState"]).map(
            ([serviceState, count]) => (
              <FilterListItem
                key={"serviceState" + serviceState}
                label={
                  <>
                    {serviceState} ({count || "0"}){" "}
                    <Status kind="company" status="hot" />
                  </>
                }
                value={{
                  [`facet:services.serviceState`]: serviceState,
                }}
              />
            )
          )}
      </FilterList>
      <FilterList label="Annual Report" icon={<LocalOfferIcon />}>
        <FilterListItem
          key="Annual report before Today"
          label="Annual report before Today"
          value={{
            "numeric:annualReport.upcomingReportSearch>": undefined,
            "numeric:annualReport.upcomingReportSearch<":
              endOfToday().getTime() / 1000,
          }}
        />
        <FilterListItem
          key="Annual report in the next two Months"
          label="Annual report in the next two Months"
          value={{
            "numeric:annualReport.upcomingReportSearch>": undefined,

            "numeric:annualReport.upcomingReportSearch<":
              addMonths(startOfMonth(new Date()), 2).getTime() / 1000,
          }}
        />
        {facets?.["annualReport.emailed"] &&
          Object.entries(facets["annualReport.emailed"]).map(
            ([emailed, count]) => (
              <FilterListItem
                key={"annualReportEmailed" + emailed}
                label={
                  <>
                    {emailed === "true" ? "Contacted" : "Not yet contacted"}(
                    {count || "0"}){" "}
                  </>
                }
                value={{
                  [`faceannualReport.emailed`]: emailed,
                }}
              />
            )
          )}
      </FilterList>
    </Box>
  );
};
