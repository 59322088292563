import * as React from "react";
import { useState } from "react";
import { useNotify, useUpdate } from "react-admin";
import { Chip, Box, MenuItem, Menu } from "@material-ui/core";

import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";

import {
  mapStatusEnumToServiceName,
  serviceTagColors,
} from "../../formatters/serviceFormatters";

const SELECTED_COLOR = serviceTagColors[6];
const NON_SELECTED_COLOR = serviceTagColors[1];

export const ServiceStateEdit = ({
  record,
  refetchCompany,
}: {
  record: any;
  refetchCompany: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [update] = useUpdate();
  const notify = useNotify();

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusSelected = async (value: string) => {
    console.log("hie");
    await update(
      "services",
      record.id,
      { serviceState: value },
      {},
      {
        onSuccess: (_payload: any) => {
          notify("Update succesful", "success");

          refetchCompany();
        },
        onFailure: (error: any) => {
          notify(`Update failed: ${error.message}`, "error");
          console.error(error);
        },
      }
    );

    setAnchorEl(null);
  };

  const isSelected = (statusName: string) => record.serviceState === statusName;
  return (
    <>
      <Box key={"serviceStateChip"}>
        <Chip
          icon={<ArrowDropDownCircleOutlinedIcon />}
          onClick={handleOpen}
          size="small"
          variant="outlined"
          label={record.serviceState}
          style={{ backgroundColor: SELECTED_COLOR }}
        />
      </Box>

      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        {Object.values(mapStatusEnumToServiceName(record?.name))?.map(
          (serviceEnum: string) => (
            <MenuItem
              key={serviceEnum}
              onClick={() => handleStatusSelected(serviceEnum)}
            >
              <Chip
                size="small"
                variant="outlined"
                label={serviceEnum}
                style={{
                  backgroundColor: isSelected(serviceEnum)
                    ? SELECTED_COLOR
                    : NON_SELECTED_COLOR,
                  border: 0,
                }}
                onClick={() => handleStatusSelected(serviceEnum)}
              />
            </MenuItem>
          )
        )}
        {/* <MenuItem onClick={handleOpenCreateDialog}>
          <Chip
            icon={<EditIcon />}
            size="small"
            variant="outlined"
            onClick={handleOpenCreateDialog}
            color="primary"
            label="Change to a custom status"
          />
        </MenuItem> */}
      </Menu>
      {/* <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleCreateTag}>
          <DialogTitle id="form-dialog-title">
            Change to custom status
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              label="Custom status"
              fullWidth
              value={customStatus}
              onChange={handleNewTagNameChange}
            />
            <Box display="flex" flexWrap="wrap" width={230} mt={2}>
              {serviceTagColors.map((color) => (
                <RoundButton
                  key={color}
                  color={color}
                  selected={color === newTagColor}
                  handleClick={() => {
                    setNewTagColor(color);
                  }}
                />
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={disabled}>
              Save Status
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}
    </>
  );
};
