import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  List,
  ListGuesser,
  SingleFieldList,
  TextField,
} from "react-admin";
import { StyledListBox } from "../../components/StyledListBox";
// import { CurrencyField } from "../../components/fields/CurrencyField";

export const ProductList = (props: any) => (
  <StyledListBox>
    <div style={{ display: "none" }}>
      <ListGuesser {...props} />
    </div>
    <List {...props}>
      <Datagrid rowClick="show" isRowSelectable={(record: any) => false}>
        <TextField source="id" label="Stripe Price" />
        <TextField source="name" />
        <ArrayField source="services">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="status" />
        <DateField source="createdAt" />
        <TextField source="createdById" />
      </Datagrid>
    </List>
  </StyledListBox>
);

export default ProductList;
