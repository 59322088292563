import config from "../../config";
import { FooterEmail } from "./Footer";

const dashboardUrl = config.customerApp.URL + "/dashboard";

type Props = {
  fullName: string;
};

export const EinReceivedEmail = ({ fullName }: Props) => {
  return (
    <>
      <p>Hi {fullName},</p>
      <p>
        Congratulations - you've received your EIN Number and a confirmation
        letter from the IRS! You can find all your documents in your{" "}
        <a href={dashboardUrl} rel="noopener noreferrer" target="_blank">
          dashboard
        </a>
        .
      </p>
      <p>
        Now it's time to apply for a bank account. We are happy to announce our in-house banking service is available to all of our customers!
        {" "}<b>doola banking</b>, allows you to open an account without visiting the US or being a US resident. Please follow our{" "}
        <a
          href="https://doola.gitbook.io/faq/banking-frequently-asked-questions/how-to-sign-up"
          rel="noopener noreferrer"
          target="_blank"
        >
          step-by-step guide
        </a>{" "}
        to apply.
      </p>
      <ul>
        <li>
            You can apply now to create an account that comes with:
            <ul>
              <li>Checking account</li>
              <li>Virtual Debit cards</li>
              <li>ACH payments</li>
              <li>Exclusive perks</li>
            </ul>
        </li>

      </ul>
      <p>
        <b>For payments gateways,</b> we suggest Stripe! You can receive international payments using this option!
      </p>
      <ul>
        <li>
          To begin your Stripe registration you can press{" "}
          <a
            href="https://dashboard.stripe.com/register"
            rel="noopener noreferrer"
            target="_blank"
        >here</a>
        </li>
        <li>
          And for more info, you can see our guide on setting up your account{" "}
          <a
            href="https://www.doola.com/blog/how-to-create-a-us-stripe-account"
            rel="noopener noreferrer"
            target="_blank"
        >here</a>
        </li>
      </ul>
      <p>
        Thanks so much and congratulations again. We can't wait to see you grow your business!
      </p>
      <FooterEmail />
    </>
  );
};
