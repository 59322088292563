import {
  mapServiceStateToPercentageAsFloat,
  formatServiceName,
  mapServiceNameToColor,
} from "../../formatters/serviceFormatters";
import { PersistedCompany } from "../../models/Company";
import { ServiceStatus } from "../../models/ServiceModel/ServiceEnums";
import { timeSince } from "../../formatters/dateFormatter";
import { CompanyService } from "../../models/ServiceModel/ServiceModel";

// It filters the companies who has services and the onces with a current service active.
export const buildInboxData = (companies: Array<PersistedCompany>) => {
  const filterCompanyByActiveServices = companies.filter(
    ({ services }) =>
      services?.filter(({ status }) => status === ServiceStatus.requiresAction)
        .length > 0
  );

  return filterCompanyByActiveServices.map((company) => {
    const { services } = company;
    const servicesOrdered = services.filter(
      ({ status }) => status === ServiceStatus.requiresAction
    );
    const servicesCompleted = services.filter(
      ({ status }) => status === ServiceStatus.completed
    );
    const companyProgress = servicesCompleted.length / services.length;
    const servicesForInbox = servicesOrdered.map(buildService);

    return {
      companyProgress: companyProgress * 100,
      services: servicesForInbox,
      companyId: company.id,
      companyData: company,
    };
  });
};

export const buildService = (obj: CompanyService) => {
  return {
    object: obj,
    serviceProgress:
      mapServiceStateToPercentageAsFloat(obj.name, obj.serviceState) * 100,
    name: formatServiceName(obj.name),
    key: obj.name,
    serviceColor: mapServiceNameToColor(obj.name),
    serviceState: obj.serviceState,
    lastUpdate: timeSince(obj.updatedAt),
  };
};
