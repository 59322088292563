import { FillingUnderReviewEmail } from "./email_templates/FilingUnderReview";
import { SS4SentToIRSEmail } from "./email_templates/SS4SentToIRS";
import { EinReceivedEmail } from "./email_templates/EinReceived";
import { FilingReceivedEmail } from "./email_templates/FilingReceived";
import { NewNameNeededEmail } from "./email_templates/NewNameNeeded";
import { PhoneLineEmail } from "./email_templates/PhoneLine";
import { TrustPilotEmail } from "./email_templates/TrustPilot";
import { EinUpdateEmail } from "./email_templates/EinUpdate";
import { AnnualReportCompleteEmail } from "./email_templates/AnnualReportComplete";
import { SecondSubmissionSentEmail } from "./email_templates/SecondSubmissionSent";
import { PersistedCompany } from "../models/Company";
import config from "../config";
import { renderToString } from "react-dom/server";
import { ApiClient } from "../dataproviders/amplifyClass";
const API_NAME = "api";
const Api = new ApiClient(API_NAME);

export enum EmailTypes {
  filingUnderReview = "filing-under-review",
  ss4FaxedToIRS = "SS4-faxed-to-irs",
  filingReceived = "filing-received",
  newName = "new-name",
  einReceived = "ein-received",
  longTimeUpdate = "long-time-update",
  trustPilot = "trust-pilot",
  phoneLine = "phone-line",
  annualReportComplete = "annual-report-completed",
  secondSubmissionSent = "2nd-submission-sent",
}

type EmailData = { subject: string; htmlJSX: JSX.Element };

export class AirtableEmail {
  googleDriveRoot: string;
  fromEmail: string;
  emailTo: string;
  cc: string;
  bcc: string[];
  replyTo: string;
  data: {
    companyID: string;
    companyName: string;
    customerId: string;
    customerFullName: string;
    einNummer: string;
    driveFolderRef: string;
    location: string;
  };
  type: EmailTypes;

  constructor(company: PersistedCompany, type: EmailTypes) {
    this.googleDriveRoot = "https://drive.google.com/";
    this.fromEmail = "doola <hello@doola.com>";
    this.emailTo = company.customer.email;
    this.cc = "";
    this.bcc = [];
    this.replyTo = "hello@doola.com";
    this.type = type;
    this.data = {
      companyID: company.id,
      companyName: company.companyDetails.name,
      location: company.companyDetails.geographicState,
      customerId: company.customer.id,
      customerFullName: company.customer.fullName,
      einNummer: "not defined yet",
      driveFolderRef:
        this.googleDriveRoot +
        "/drive/u/1/folders/" +
        company?.documents?.googleDriveFolderId,
    };
  }

  emailData = (): EmailData => {
    switch (this.type) {
      case EmailTypes.filingUnderReview:
        return this.filingUnderReviewWithState();
      case EmailTypes.ss4FaxedToIRS:
        return this.SS4SentToIRs();
      case EmailTypes.filingReceived:
        return this.filingReceived();
      case EmailTypes.newName:
        return this.newNameNeeded();
      case EmailTypes.einReceived:
        return this.einNummerReceived();
      case EmailTypes.longTimeUpdate:
        return this.einUpdate();
      case EmailTypes.trustPilot:
        return this.trustPilot();
      case EmailTypes.phoneLine:
        return this.phoneLine();
      case EmailTypes.annualReportComplete:
        return this.annualReportComplete();
      case EmailTypes.secondSubmissionSent:
        return this.secondSubmissionSent();
      default:
        return { subject: "", htmlJSX: <>Not implemented yet</> };
    }
  };

  filingUnderReviewWithState = (): EmailData => ({
    subject:
      "doola Update: US Company Filing for " +
      this.data.companyName +
      " Is Under Review",
    htmlJSX: (
      <FillingUnderReviewEmail
        fullName={this.data.customerFullName}
        companyName={this.data.companyName}
      />
    ),
  });

  SS4SentToIRs = (): EmailData => ({
    subject:
      "doola Update: EIN Application for " +
      this.data.companyName +
      " is Under Review",
    htmlJSX: (
      <SS4SentToIRSEmail
        fullName={this.data.customerFullName}
        companyName={this.data.companyName}
      />
    ),
  });

  einNummerReceived = (): EmailData => ({
    subject:
      "doola Update: " +
      this.data.companyName +
      " EIN Number Approved + Next Steps",
    htmlJSX: <EinReceivedEmail fullName={this.data.customerFullName} />,
  });

  filingReceived = (): EmailData => ({
    subject:
      "doola Update: " +
      this.data.companyName +
      " is officially registered! 🎉",
    htmlJSX: <FilingReceivedEmail fullName={this.data.customerFullName} />,
  });

  newNameNeeded = (): EmailData => ({
    subject:
      "[ACTION NEEDED] doola Update: " +
      this.data.companyName +
      " Name Not Available",
    htmlJSX: (
      <NewNameNeededEmail
        fullName={this.data.customerFullName}
        companyName={this.data.companyName}
      />
    ),
  });

  phoneLine = (): EmailData => ({
    subject: "Get your U.S. Phone Line!",
    htmlJSX: (
      <PhoneLineEmail
        fullName={this.data.customerFullName}
        companyName={this.data.companyName}
      />
    ),
  });

  trustPilot = (): EmailData => ({
    subject: `Your feedback means the world to us ❤️`,
    htmlJSX: <TrustPilotEmail fullName={this.data.customerFullName} />,
  });

  einUpdate = (): EmailData => ({
    subject: this.data.companyName + " EIN Update",
    htmlJSX: <EinUpdateEmail fullName={this.data.customerFullName} />,
  });

  annualReportComplete = (): EmailData => ({
    subject: "doola Update: Annual Report Completed",
    htmlJSX: (
      <AnnualReportCompleteEmail
        firstName={this.data.customerFullName}
        state={this.data.location}
      />
    ),
  });

  secondSubmissionSent = (): EmailData => ({
    subject: "doola Update: Second Submission for " + this.data.companyName,
    htmlJSX: (
      <SecondSubmissionSentEmail
        firstName={this.data.customerFullName}
        companyName={this.data.companyName}
      />
    ),
  });

  sendEmail = async () => {
    const { subject, htmlJSX } = this.emailData();
    const payload = this._createEmailPayload(subject, renderToString(htmlJSX));

    const url = "/notifications/email";

    const response = await Api.post(url, payload);
    return response;
  };

  _createEmailPayload = (subject: string, html: string) => ({
    type: this.type,
    companyId: this.data.companyID,
    customerId: this.data.customerId,
    to: this.emailTo,
    from: this.fromEmail,
    cc: this.cc,
    bcc: this._getBcc(),
    replyTo: this.replyTo,
    subject: subject,
    html: html,
  });

  _getBcc = () => {
    if (this.type === EmailTypes.einReceived)
      return ["doola.com+0005f58c4d@invite.trustpilot.com"];
    return this.bcc;
  };

  _sendEmail = async (subject: string, html: string) => {
    const payload = this._createEmailPayload(subject, html);

    const response = await Api.post(config.api.URL + "/notifications/email", payload);
    return response;
  };
}
