import { Box } from "@material-ui/core";
import { mapServiceNameToColor } from "../../formatters/serviceFormatters";
import { ServiceNames } from "../../models/ServiceModel/ServiceEnums";

export const Status = ({ status, kind }: { status: string; kind: string }) => (
  <Box
    width={10}
    height={10}
    display="inline-block"
    borderRadius={5}
    bgcolor={
      kind === "service"
        ? mapServiceNameToColor(status as any)
        : mapServiceNameToColor(ServiceNames.llcFormation)
    }
    component="span"
  />
);
