import InboxIcon from "@material-ui/icons/Inbox";

import InboxList from "./InboxList";
import InboxShow from "./InboxShow";
import InboxEdit from "./InboxEdit";
import InboxCreate from "./InboxCreate";
import { groupBy } from "lodash";
import { useGetList } from "ra-core";
import { PAGE_SIZE } from "../../dataproviders/constants";
import { Badge } from "@material-ui/core";
import { CompanyService } from "../../models/ServiceModel/ServiceModel";

const DynamicIcon = () => {
  const { data } = useGetList<CompanyService>(
    "services",
    undefined,
    undefined,
    { pageSize: PAGE_SIZE },
    {
      onSuccess: (payload: any) => {
        console.log("SERVICE COMPLETE");
      },
    }
  );

  const companiesById = groupBy(Object.values(data), "companyId");

  return (
    <Badge
      badgeContent={Object.values(companiesById).length}
      max={25}
      color="primary"
    >
      <InboxIcon color="action" />
    </Badge>
  );
};

const inbox = {
  list: InboxList,
  show: InboxShow,
  icon: DynamicIcon,
  edit: InboxEdit,
  create: InboxCreate,
};

export default inbox;
