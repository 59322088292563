import {
  Datagrid,
  DateField,
  TextField,
  SimpleShowLayout,
  ArrayField,
  FunctionField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  Link,
  ShowBase,
  ShowProps,
  useShowContext,
  Button as RaButton,
  useRecordContext,
  useUpdate,
} from "react-admin";
import Actions from "../companies/components/Actions";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  formatServiceName,
  formatServiceStatus,
  formatServiceStatusWithIcons,
  mapServiceNameToColor,
} from "../../formatters/serviceFormatters";
import {
  Box,
  Button,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Tab,
  TextField as MUITextField,
  CardActionArea,
  Chip,
} from "@material-ui/core";
import { FC, useState } from "react";
import { get } from "lodash";
import { CompanyStatus, PersistedCompany } from "../../models/Company";
import { CompanyShowAside } from "./CompanyShowAside";
import { useHistory } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { useEffect } from "react";
import { ServiceStatus } from "../../models/ServiceModel/ServiceEnums";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import { ColoredChipField } from "../customers/TagsList";
import { NotesIterator } from "../notes";

export const CompanyShow = (props: ShowProps) => (
  <ShowBase {...props}>
    {/* <div style={{ display: "none" }}>
      <ShowGuesser {...props} />
    </div> */}
    <CompanyShowContent />
  </ShowBase>
);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    width: "100%",
  },
  inlineFieldHalf: {
    display: "block",
    // width: "50%",
    flexGrow: 0,
    // maxWidth: "50%",
    flexBasis: "50%",
  },
  inlineFieldFull: {
    display: "block",

    flexGrow: 0,
    maxWidth: "100%",
    flexBasis: "100%",
  },
}));

const CompanyShowContent = (props: any) => {
  const { record, loaded } = useShowContext<PersistedCompany>(props);
  const [update, { loading }] = useUpdate();
  const classes = useStyles();
  const history = useHistory();

  const [comment, setComment] = useState(record?.internalDetails?.comment);
  const [selectedService, setSelectedService] = useState<string>(
    ServiceStatus.requiresAction
  );

  const [commentChange, setCommentChanged] = useState(false);

  useEffect(() => {
    setComment(record?.internalDetails?.comment);
  }, [record?.internalDetails?.comment]);
  if (!loaded || !record) return null;
  return (
    <Box mt={2} display="flex">
      <Box flex="1">
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          style={{ paddingBottom: "15px" }}
        >
          <Grid item xs={2}>
            <Button
              variant="outlined"
              style={{ color: "black" }}
              onClick={() => history.goBack()}
            >
              &larr;
            </Button>
          </Grid>

          <Grid item xs={2}></Grid>
        </Grid>
        <Card style={{ paddingRight: "16px", paddingLeft: "16px" }}>
          <CardHeader
            style={{ paddingBottom: 0 }}
            title={
              <>
                {record?.companyDetails?.name}
                {record.status === CompanyStatus.canceled && (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={record?.status}
                    style={{
                      backgroundColor: "#d9363c",
                      color: "#fff",
                      marginLeft: "20px",
                    }}
                  />
                )}
              </>
            }
          ></CardHeader>
          <CardContent>
            <Grid
              container
              style={{
                width: "100%",
              }}
            >
              {record.status === CompanyStatus.canceled && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1" style={{ color: "#d9363c" }}>
                    Cancellation Reason:{" "}
                    <b>{record?.cancellationReason || "-"}</b>
                  </Typography>
                  <br />
                </Grid>
              )}
              <Grid item xs={6}>
                <Typography variant="caption" style={{ color: "gray" }}>
                  Entity:{" "}
                  <b>{record?.companyDetails?.businessType?.name || "-"}</b>{" "}
                  <br />
                  Style:{" "}
                  <b>
                    {record?.companyDetails?.name}{" "}{record?.companyDetails?.businessType?.style || "-"}
                  </b>{" "}
                  <br />
                  Status:{" "}
                  <b style={{ textTransform: "capitalize" }}>
                    {(record as any)?.aggregatedState || "-"}
                  </b>
                  <br />
                  State: {record?.companyDetails?.geographicState || "-"} <br />
                  Renewal date:{" "}
                  <DateField source="renewalDetails.renewalDate" /> <br />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" style={{ color: "gray" }}>
                  Company EIN: <b>{record?.companyDetails?.EIN || "-"} </b>
                  <br />
                  Company ITIN: <b>{record?.companyDetails?.ITIN || "-"}</b>
                  <br />
                  Company SSN: <b>{record?.companyDetails?.SSN || "-"} </b>
                  <br />
                </Typography>
              </Grid>
              <Grid
                item
                xs={commentChange ? 10 : 12}
                style={{ paddingTop: "30px", paddingBottom: "30px" }}
              >
                <Typography variant="subtitle2" style={{ color: "gray" }}>
                  Comment for company:
                </Typography>
                <MUITextField
                  id="outlined-textarea"
                  label=""
                  placeholder="Add Comment to customer"
                  multiline
                  value={comment}
                  onChange={(event) => {
                    setComment(event.currentTarget.value);
                    if (
                      event.currentTarget.value.length &&
                      event.currentTarget.value !==
                        record?.internalDetails?.comment
                    ) {
                      setCommentChanged(true);
                    } else {
                      setCommentChanged(false);
                    }
                  }}
                  style={{ width: "100%", minHeight: "50px" }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: !commentChange ? "none" : "block",
                  paddingLeft: "20px",
                }}
              >
                <Button
                  fullWidth
                  style={{ marginTop: "45px" }}
                  variant="contained"
                  disabled={loading}
                  onClick={() => {
                    update(
                      "companies",
                      record.id,
                      { internalDetails: { comment: comment } },
                      undefined
                    );
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "20px" }}>
                <Divider />
              </Grid>
              <Grid item xs={4}>
                Created at:
                <br />
                <b>{formatDistanceToNow(new Date(record?.createdAt))}</b>
              </Grid>
              <Grid item xs={4}>
                Total Spent:
                <br /> <b> -</b>
              </Grid>
              <Grid item xs={4}>
                Last updated at:
                <br />
                <b>{formatDistanceToNow(new Date(record?.updatedAt))}</b>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          style={{
            marginTop: "30px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          <CardHeader
            title={[
              "Services",
              // <Button style={{ textAlign: "right" }}>Add Service</Button>,
            ]}
          />
          <CardContent>
            <TabContext value={selectedService}>
              {/* <AppBar position="static"> */}
              <TabList
                onChange={(event, newValue) => setSelectedService(newValue)}
                aria-label="simple tabs example"
              >
                {Object.values(ServiceStatus).map((serviceStatus) => (
                  <Tab
                    label={`${formatServiceStatus(serviceStatus)} (${
                      record.services.filter(
                        (service) => service.status === serviceStatus
                      ).length
                    })`}
                    key={serviceStatus}
                    value={serviceStatus}
                  />
                ))}
              </TabList>

              {Object.values(ServiceStatus).map((serviceStatus, index) => (
                <TabPanel value={serviceStatus} key={index}>
                  {record.services
                    .filter((service) => service.status === serviceStatus)
                    .map((service, index) => {
                      return (
                        <Card style={{ marginTop: index ? 15 : 0 }}>
                          <CardActionArea
                            onClick={() => {
                              history.push(`/services/${service.id}/show`);
                            }}
                          >
                            <CardContent>
                              <Grid container justifyContent="space-between">
                                <Grid item>
                                  <b>{formatServiceName(service.name)}</b>
                                </Grid>
                                <Grid item></Grid>
                                <ColoredChipField
                                  record={{
                                    name: service.serviceState,
                                    color: mapServiceNameToColor(service.name),
                                  }}
                                  source="name"
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      );
                    })}
                </TabPanel>
              ))}
            </TabContext>
          </CardContent>
        </Card>
        <Accordion
          className={classes.inlineFieldFull}
          style={{ marginTop: 20 }}
          square={false}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="members-content"
            id="members-header"
          >
            Company Files
          </AccordionSummary>
          <AccordionDetails>
            <ReferenceManyField
              target="companies"
              label=""
              source="id"
              reference="documents"
            >
              <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="mimeType" />
              </Datagrid>
            </ReferenceManyField>
          </AccordionDetails>
        </Accordion>
        <Card
          style={{
            marginTop: "30px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          <CardHeader title="Recent Notes" />
          <CardContent>
            <ReferenceManyField
              target="companies"
              reference="annotations"
              sort={{ field: "date", order: "DESC" }}
            >
              <NotesIterator showStatus reference="companies" />
            </ReferenceManyField>
          </CardContent>
        </Card>

        <Card style={{ marginTop: "30px" }}>
          <SimpleShowLayout className={classes.container}>
            <ArrayField source="services" label="All services">
              <Datagrid
                rowClick={(id, basePath, record) =>
                  `/services/${record.id}/show`
                }
              >
                <FunctionField
                  label=""
                  source="status"
                  render={(record: any, path?: string) =>
                    formatServiceStatusWithIcons(get(record, path || "", ""))
                  }
                />
                <FunctionField
                  label="Name"
                  source="name"
                  render={(record: any, path?: string) =>
                    formatServiceName(get(record, path || "", ""))
                  }
                />
                <FunctionField
                  source="status"
                  render={(record: any, path?: string) => (
                    <>{formatServiceStatus(get(record, path || "", ""))}</>
                  )}
                />

                <TextField source="serviceState" label="Status" />
                {/* <TextField source="notes" /> */}
                <ArrayField source="options">
                  <SingleFieldList>
                    <ChipField source="key" />
                  </SingleFieldList>
                </ArrayField>
                <DateField source="updatedAt" />
              </Datagrid>
            </ArrayField>
            {/* 
            <DateField
              className={classes.inlineFieldHalf}
              source="createdAt"
              label="Created at"
            />{" "}
            <DateField
              className={classes.inlineFieldHalf}
              source="updatedAt"
              label="Updated at"
            />
            <TextField
              source="companyDetails.activity"
              label="Company Activity"
              emptyText="-"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="companyDetails.activityOther"
              label="Company Activity (Other)"
              emptyText="-"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="companyDetails.url"
              label="Website"
              emptyText="-"
              className={classes.inlineFieldHalf}
            />
            <Divider />
            <TextField
              source="companyDetails.EIN"
              label="Company EIN"
              className={classes.inlineFieldFull}
            />
            <TextField
              source="companyDetails.ITIN"
              label="Company ITIN"
              className={classes.inlineFieldFull}
            />
            <TextField
              source="companyDetails.SSN"
              label="Company SSN"
              className={classes.inlineFieldFull}
            />
           <TextField source="status" className={classes.inlineFieldFull} />  
            <TextField
              source="applicantAddress.address1"
              label="Address Line 1"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.address2"
              label="Address Line 2"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.city"
              label="Address City"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.country"
              label="Country"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.phone"
              label="Phone"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.postalCode"
              label="Postal code"
              className={classes.inlineFieldHalf}
            />
            <TextField
              source="applicantAddress.state"
              label="State"
              className={classes.inlineFieldHalf}
            />
            <Accordion className={classes.inlineFieldFull}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="members-content"
                id="members-header"
              >
                Company Members
              </AccordionSummary>
              <AccordionDetails>
                <ArrayField source="companyDetails.owners">
                  <Datagrid>
                    <TextField source="fullName" />
                    <DateField source="ownership" />
                    <BooleanField source="isCompany" />
                  </Datagrid>
                </ArrayField>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.inlineFieldFull}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="members-content"
                id="members-header"
              >
                Company Files
              </AccordionSummary>
              <AccordionDetails>
                <ReferenceManyField
                  target="companies"
                  label=""
                  source="id"
                  reference="documents"
                >
                  <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="mimeType" />
                  </Datagrid>
                </ReferenceManyField>
              </AccordionDetails>
            </Accordion>
      <ReferenceArrayField emptyText="-" source="driveId" reference="documents">
        <TextField source="name" />
      </ReferenceArrayField> */}
          </SimpleShowLayout>
        </Card>
        <Card style={{ marginTop: 20 }}>
          <CardHeader style={{ paddingBottom: 0 }} title="All Actions" />
          <CardContent>
            <Actions company={record} />
          </CardContent>
        </Card>
      </Box>
      <CompanyShowAside record={record} />
    </Box>
  );
};

export const CurrentService: FC<any> = (props: any) => {
  const record = useRecordContext(props);
  const serviceStage = record.serviceStage;
  const recordServices = record?.services || [];

  const items = recordServices
    .filter(
      (item: any) =>
        item.stage >= (serviceStage || 0) && item.id !== props.recordId
    )
    .sort((a: any, b: any) => a.stage - b.stage);
  const nextRecord: any = items[0];
  return (
    <Box>
      <Typography>
        {nextRecord
          ? `Status: ${formatServiceStatus(nextRecord?.status)}`
          : "Loading..."}
      </Typography>
      <Typography>
        {nextRecord ? `${nextRecord?.serviceState}` : "Loading..."}
      </Typography>
      <RaButton
        // style={{ height: "50px" }}
        fullWidth
        component={Link}
        label={
          nextRecord
            ? `Current Service: ${formatServiceName(nextRecord?.name)}`
            : "Loading..."
        }
        to={`/services/${nextRecord?.id}/show`}
        color="secondary"
        variant="contained"
      />
    </Box>
  );
};

export default CompanyShow;
