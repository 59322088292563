//source https://stackoverflow.com/questions/50724915/is-it-possible-to-have-multiple-dataproviders-in-react-admin

import { sanitizeFetchType } from "react-admin";
import customDataProvider from "./amplify";
import mockDataProvider from "./mock";

const dataProvidersPerResource = {
  amplify: {
    dataProvider: customDataProvider,
    resources: [
      "companies",
      "customers",
      "services",
      "documents",
      "orders",
      "products",
      "annotations",
    ],
  },
  mock: {
    dataProvider: mockDataProvider,
  },
};

const dataProviders = async (
  type: string,
  resource: string,
  params?: Record<string, any>
) => {
  const dataProviderMapping = resource.includes("mock_")
    ? dataProvidersPerResource.mock
    : dataProvidersPerResource.amplify;

  const dataProviderFunction =
    (dataProviderMapping?.dataProvider as any) || mockDataProvider;

  return dataProviderFunction[sanitizeFetchType(type)](
    resource.replace(/mock_|local_/i, ""),
    params
  );
};

export default dataProviders;
