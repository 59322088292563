const industryOptions: {
  position: number;
  label: string;
}[] = [
  { position: 1, label: "Health care & social assistance" },
  { position: 2, label: "Wholesale-agent/broker" },
  { position: 3, label: "Construction" },
  { position: 4, label: "Rental & leasing" },
  { position: 5, label: "Transportation & warehousing" },
  { position: 6, label: "Accommodation & food service" },
  { position: 7, label: "Wholesale-other" },
  { position: 8, label: "Retail" },
  { position: 9, label: "Real estate" },
  { position: 10, label: "Manufacturing" },
  { position: 11, label: "Finance & insurance" },
  { position: 12, label: "Other" },
];

export default industryOptions;
