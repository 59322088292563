import { Admin, Resource, defaultTheme, ListGuesser } from "react-admin";

import { nextTokenReducer } from "ra-aws-amplify";
import merge from "lodash/merge";
import companies from "./features/companies";
import inbox from "./features/inbox";
import services from "./features/services";
import customers from "./features/customers";
import orders from "./features/orders";
import products from "./features/products";
import dataproviders from "./dataproviders";
import Layout from "./Layout";
import InboxApp from "./features/inbox/InboxList";
import red from "@material-ui/core/colors/red";
import { buildAuthProvider } from "react-admin-amplify";

import config from "./config";
import { Auth, Amplify } from "aws-amplify";
import { useEffect } from "react";
Amplify.configure(config.amplify);

const myTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: "#865ED4",
    },
    secondary: {
      main: "#F3B62F",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiButton: {
      root: {
        // color: "white", // Some CSS
      },
    },
  },
});

const App = () => {
  const queryParams = window.location.href;
  const challenge = new URLSearchParams(queryParams.split("?")[1]).get(
    "challenge"
  );

  useEffect(() => {
    const finishSignin = async (challenge: string) => {
      try {
        console.log("Logging in");
        const [email, code] = challenge.split(",");
        const user = await Auth.signIn(email);
        await Auth.sendCustomChallengeAnswer(user, code);
        await Auth.currentSession();
        console.log("Logged in");
        window.location.href = "/";
      } catch (e) {
        console.error(e);
      }
    };

    if (challenge && challenge.includes(",")) {
      finishSignin(challenge);
    }
  }, [challenge]);

  return (
    <Admin
      title="doola Management Dashboard"
      dataProvider={dataproviders}
      disableTelemetry
      authProvider={buildAuthProvider()}
      // loginPage={Login}
      customReducers={{ nextToken: nextTokenReducer }}
      layout={Layout}
      dashboard={InboxApp}
      theme={myTheme}
    >
      <Resource name="inbox" {...inbox} options={{ label: "Inbox" }} />
      <Resource name="companies" {...companies} />
      <Resource name="customers" {...customers} />
      <Resource name="services" {...services} />
      <Resource name="orders" {...orders} />
      <Resource name="products" {...products} />
      <Resource name="documents" />
      <Resource name="customers/action" />
      <Resource name="annotations" list={ListGuesser} />
      <Resource name="subscriptions" />
      <Resource name="notes" />
      <Resource name="invoices" />
      <Resource name="cognito" />
      <Resource name="stripes" />
      <Resource name="internalDetails.migrationData.submissions" />
      <Resource name="documents.googleDriveFolders" />
      <Resource name="drives" />
      <Resource name="customer_taxes" />
      <Resource name="account_taxes" />
      <Resource name="cognitos" />
      <Resource name="createdBies" />
      <Resource name="additionalPricings" />
    </Admin>
  );
};

export default App;
