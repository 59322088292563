import config from "../../config";
import { FooterEmail } from "./Footer";

const dashboardUrl = config.customerApp.URL + "/dashboard";

type Props = {
  fullName: string;
};

export const FilingReceivedEmail = ({ fullName }: Props) => {
  return (
    <>
      <p>Hi {fullName},</p>
      <p>
        Congratulations, your US Company filing is complete and is officially
        registered with the state! You will be able to access all of your
        documents on your <a href={dashboardUrl}>dashboard</a>.
      </p>
      <p>A few notes on the documents:</p>
      <ul>
        <li>
          Your operating agreement is an internal doc! You are able to make any
          modifications / changes and sign it whenever you're ready. It's for
          your personal records and never needs to be resubmitted to the state.
        </li>
        <li>
          Your US Company Membership certificate is an internal doc. It doesn't
          go on any public records and is optional to sign.
        </li>
        <li>
          Your US Company resolution is a form you can use to open a bank
          account and also optional to sign.
        </li>
      </ul>
      <p>
        Once again congratulations! Next up, we'll get started on your EIN
        application. Stay tuned for a signature request!
      </p>
      <FooterEmail />
    </>
  );
};
