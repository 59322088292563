import { makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";

export const InboxServiceStyles = makeStyles({
  serviceWrapper: {},
  lastUpdate: {
    position: "relative",
    color: "grey",
    fontSize: "12px",
    display: "contents",
  },
  icon: {
    height: "15px",
    marginTop: "1px",
  },
});

type Props = {
  label: string;
};

export const LastUpdateLabel = ({ label }: Props) => {
  const classes = InboxServiceStyles();

  return (
    <Box className={classes.lastUpdate}>
      <span>Last updated at {label}</span>
      <UpdateOutlinedIcon className={classes.icon} />
    </Box>
  );
};
