export const FooterEmail = () => {
  return (
    <>
      <p>
        Cheers, <br />{" "}
        <a
          href="https://www.doola.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          The doola Team
        </a>
      </p>
      {/* <strong>Arjun Mahadevan</strong>
      <br />
      Co-founder, CEO at{" "}
      <a
        href="https://www.startpack.io"
        target="_blank"
        rel="noopener noreferrer"
      >
        StartPack
      </a>
      <br />
      <a
        href="https://www.twitter.com/arjmahadevan"
        rel="noopener noreferrer"
        target="_blank"
      >
        LinkedIn
      </a>{" "}
      |{" "}
      <a
        href="https://www.twitter.com/arjmahadevan"
        rel="noopener noreferrer"
        target="_blank"
      >
        @arjmahadevan
      </a>{" "}
      | +1 551 273 2773 */}
    </>
  );
};
