import { FooterEmail } from "./Footer";

type Props = {
  fullName: string;
  companyName: string;
};

export const FillingUnderReviewEmail = ({ fullName, companyName }: Props) => {
  return (
    <>
      <p>Hi {fullName},</p>
      <p>
        This message is to confirm that your filing for {companyName} is
        currently under review! The state usually gets back to us within about a
        week. If we hear anything before then, you will be the first to know!
      </p>
      <p>
        Stay tuned for a signature request that will allow us to move forward
        with the process and get your EIN. In a meantime, join{" "}
        <a
          href="https://t.me/+Q-dJ63psUhZjNGQ9"
          rel="noopener noreferrer"
          target="_blank"
        >
          doola Founder's Community
        </a>{" "}
        on Telegram. The goal of this community is to help entrepreneurs build a
        global network, learn, and grow together.
      </p>
      <p>Don't hesitate to reach out with any questions!</p>
      <FooterEmail />
    </>
  );
};
