import CustomerList from "./CustomerList";
import CustomerShow from "./CustomerShow";
import CustomerEdit from "./CustomerEdit";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CustomerCreate from "./CustomerCreate";

const customers = {
  list: CustomerList,
  show: CustomerShow,
  icon: SupervisorAccountIcon,
  edit: CustomerEdit,
  create: CustomerCreate,
};

export default customers;
