import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import { Tabs, Tab, Toolbar, AppBar, Box, Typography } from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";
import { UserMenu, Logout, LoadingIndicator, MenuItemLink } from "react-admin";
import { DoolaLogo } from "./components/DoolaLogo";
import packageJSON from "./../package.json";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  logo: {
    width: 50,
    height: 43.54,
  },
});

const Header = () => {
  const classes = useStyles();
  const match = useRouteMatch(["/companies", "/customers", "/orders"]);
  const currentPath = match?.path ?? "/";

  return (
    <nav className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <Box flex={1} display="flex" justifyContent="space-between">
            <Hidden xsDown>
              <Box display="flex" alignItems="center">
                <Link to="/inbox">
                  <Box style={{ height: "35px" }}>
                    <DoolaLogo />
                  </Box>
                </Link>
                <Typography component="span" variant="h5">
                  {/* Atomic CRM */}
                </Typography>
              </Box>
            </Hidden>
            <Box>
              <Tabs value={currentPath} aria-label="Navigation Tabs">
                <Tab label={"Inbox"} component={Link} to="/" value="/" />
                <Tab
                  label={"Companies"}
                  component={Link}
                  to="/companies"
                  value="/companies"
                />
                <Tab
                  label={"Customers"}
                  component={Link}
                  to="/customers"
                  value="/customers"
                />
                <Tab
                  label={"Invoices"}
                  component={Link}
                  to="/orders"
                  value="/orders"
                />
              </Tabs>
            </Box>
            <Box display="flex">
              <LoadingIndicator />
              <UserMenu logout={<Logout button />}>
                <MenuItemLink
                  to="#"
                  primaryText={`Version: ${packageJSON.version}`}
                />
              </UserMenu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </nav>
  );
};

export default Header;
