import Linkify from "react-linkify";

import {
  Card,
  Grid,
  CardContent,
  CardActionArea,
  CardHeader,
  Divider,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useState } from "react";
import {
  CRUD_GET_LIST_SUCCESS,
  Empty,
  Link,
  Loading,
  useGetList,
} from "react-admin";
import { LoadMore } from "../../components/CustomPagination";
import { PAGE_SIZE } from "../../dataproviders/constants";
import { useDispatch } from "react-redux";
import { InboxServiceShow } from "./components/service";
import { timeSince } from "../../formatters/dateFormatter";
import { inboxListStyles, StyledBusinessTypeLabel } from "./styles";
import { buildInboxData } from "./services";
import { LastUpdateLabel } from "../../components/LastUpdate";
import {
  BusinessTypeNames,
  CompanyStatus,
  PersistedCompany,
} from "../../models/Company";
import { ServiceNames } from "../../models/ServiceModel/ServiceEnums";
import { CompanyService } from "../../models/ServiceModel/ServiceModel";

const InboxList = (props: any) => {
  const [nextToken, setNextToken] = useState<any>(null);
  const dispatch = useDispatch();

  const {
    data: inboxData,
    loading,
    loaded,
    error,
  } = useGetList<PersistedCompany>(
    "companies",
    undefined,
    undefined,
    { nextToken, pageSize: PAGE_SIZE, status: CompanyStatus.requiresAction },
    {
      onSuccess: (payload: any) =>
        dispatch({ type: CRUD_GET_LIST_SUCCESS, payload }),
    }
  );

  if (!inboxData) {
    return null;
  }

  const processedInboxItems = buildInboxData(
    Object.values(inboxData) as Array<PersistedCompany>
  );

  if (!loaded && loading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <>
      {processedInboxItems.length === 0 ? (
        <Empty resource={"companies"} />
      ) : (
        processedInboxItems.map((inboxItem, index) => (
          <>
            <InboxItem
              {...inboxItem}
              key={`${inboxItem.companyId}_inboxitem`}
            />
          </>
        ))
      )}
      <LoadMore setNextToken={setNextToken} nextToken={nextToken} />
    </>
  );
};

export default InboxList;

function InboxItem({
  companyId,
  companyProgress,
  companyData,
  services,
}: {
  companyId: string;
  companyProgress: number;
  companyData: PersistedCompany;
  services: {
    object: CompanyService;
    serviceProgress: number;
    name: string;
    key: ServiceNames;
    serviceColor: string;
    serviceState: string;
    lastUpdate: string;
  }[];
}): JSX.Element {
  const classes = inboxListStyles();

  const { companyDetails } = companyData;
  const { SSN, ITIN, businessType } = companyDetails;
  const { name: businessName } = businessType;
  const isUSCitizen = SSN || ITIN;
  const isLLC = businessName === BusinessTypeNames.LLC;

  return (
    <Card
      key={`${companyId}_card`}
      className={classes.cardService}
      elevation={0}
      variant="outlined"
    >
      <CardActionArea
        component={Link}
        to={`companies/${encodeURIComponent(companyId)}/show`}
      >
        <LinearProgress
          className={classes.linearProgress}
          variant="determinate"
          value={companyProgress}
          color="primary"
        />
        <CardHeader
          title={
            <Grid container justifyContent="space-between">
              <Grid item>{companyData.companyDetails.name}</Grid>
              <Grid item>
                <LastUpdateLabel label={timeSince(companyData.updatedAt)} />
              </Grid>
            </Grid>
          }
        />

        <Divider />
        <CardContent style={{ padding: 0 }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ padding: 16 }}
          >
            <Grid item xs={1} style={{ display: "flex" }}>
              <StyledBusinessTypeLabel color={!isLLC ? "#F3B62F" : "inherit"}>
                {businessName}
              </StyledBusinessTypeLabel>
            </Grid>
            <Grid item xs={5}>
              Company state: {companyData.companyDetails.geographicState || "-"}
              <br />
              Customer: {companyData.customer.fullName}
              <br />
              {companyData.customer.email}
              <br />
              {companyData?.invoiceDetails?.lastInvoiceStatus && (
                <>
                  Last invoice: {companyData?.invoiceDetails?.lastInvoiceStatus}
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              Citizenship: {isUSCitizen ? "US Citizen" : "Non-US Citizen"}{" "}
              <br />
              Created at:{" "}
              {new Date(companyData.createdAt).toLocaleDateString("en-US")}{" "}
              <br />
              Comment:{" "}
              <Linkify>{companyData?.internalDetails?.comment || "-"}</Linkify>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <Grid container>
        <Grid item xs={12}>
          <Divider />
          {services.map((service: any, index) => (
            <InboxServiceShow
              service={service}
              company={companyData}
              key={`${companyData.id}_${index}_service`}
            />
          ))}
        </Grid>
      </Grid>
    </Card>
  );
}
