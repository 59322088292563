type Props = {
  fullName: string;
};

export const TrustPilotEmail = ({ fullName }: Props) => {
  return (
    <>
      <p>Hi {fullName},</p>
      <p>
        Thank you again for trusting our team to help you start and maintain
        your US business! We would love to receive your feedback. Would you mind
        taking a minute and writing us a review on{" "}
        <a
          href="https://www.trustpilot.com/review/doola.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>TrustPilot</strong>
        </a>
        ?
      </p>
      <p>
        Every single review is very important to us as it helps doola team
        improve our product and service. Writing it might seem like a small
        thing to you, but it has a huge impact on our business.
      </p>
      <p>
        We founded doola to unlock the potential of human entrepreneurship and
        democratize access to wealth creation by empowering anyone in the world
        to turn their dream into their US business. If you believe in our
        mission, please take a moment to share your feedback and help others
        like you start their own US business
      </p>
      <p>
        <a
          href="https://www.trustpilot.com/review/doola.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>Click here to write a review! 🙏</strong>
        </a>
      </p>
      <p>
        Feel free to reach out with any questions - I read every email I get!
      </p>
      <p>Cheers,</p>
      <strong>Arjun Mahadevan</strong>
      <br />
      Co-founder, CEO at{" "}
      <a href="https://www.doola.com" target="_blank" rel="noopener noreferrer">
        doola
      </a>
      <br />
      <a
        href="https://www.twitter.com/arjmahadevan"
        rel="noopener noreferrer"
        target="_blank"
      >
        LinkedIn
      </a>{" "}
      |{" "}
      <a
        href="https://www.twitter.com/arjmahadevan"
        rel="noopener noreferrer"
        target="_blank"
      >
        @arjmahadevan
      </a>{" "}
      | +1 551 273 2773
    </>
  );
};
