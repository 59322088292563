import {
  Datagrid,
  DateField,
  Show,
  TextField,
  SimpleShowLayout,
  ArrayField,
  BooleanField,
  ReferenceField,
} from "react-admin";

const CompanyShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="customer.fullName" />
      <TextField source="plan" />
      <TextField source="companyState" />
      <TextField source="companyStateAbbreviated" />
      <TextField source="companyDetails.name" />
      <TextField source="llcStyle" />
      <TextField source="companyActivity" />
      <TextField source="companyActivityOther" />
      <TextField source="entityType" />
      <TextField source="companyDescription" />
      <TextField source="companyURL" />
      <DateField source="applicantSSNorITIN" />
      <TextField source="companyAddressType" />
      <TextField source="applicantAddress1" />
      <TextField source="applicantAddress2" />
      <TextField source="applicantCity" />
      <TextField source="applicantState" />
      <TextField source="applicantCountry" />
      <DateField source="applicantPostalCode" />
      <TextField source="applicantPhone" />
      <ArrayField source="members">
        <Datagrid>
          <TextField source="fullName" />
          <DateField source="ownership" />
          <BooleanField source="isCompany" />
        </Datagrid>
      </ArrayField>
      <ReferenceField link="show" source="driveId" reference="drives">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="signedSS4FileId" />
      <DateField source="articlesFileId" />
    </SimpleShowLayout>
  </Show>
);

export default CompanyShow;
