import { useGetManyReference } from "react-admin";
import { PersistedCompany } from "../../../models/Company";
import { Box, Input } from "@material-ui/core";
import { CustomAction, ActionTypes } from "../../../components/CustomAction";
import CustomEmailLink from "../../../components/CustomEmails";
import { EmailTypes } from "../../../services/airtable_emails";
import { countBy } from "lodash";

import DescriptionIcon from "@material-ui/icons/Description";
import { Confirm, Button as RaButton } from "react-admin";
import { useState, Fragment } from "react";
import {
  defaultPagination,
  defaultSorting,
  filterParams,
} from "../../../dataproviders/constants";
import { ApiClient } from "../../../dataproviders/amplifyClass";

const API_NAME = "api";
const Api = new ApiClient(API_NAME);

type ServiceActionsContainerProps = {
  company: PersistedCompany;
};

type ServiceActionsProps = {
  companyEmailsData: any;
  company: PersistedCompany;
} & ServiceActionsContainerProps;

const ServiceActions = ({
  companyEmailsData,
  company,
}: ServiceActionsProps) => {
  const actions = [
    { label: "Form Company", type: ActionTypes.formCompany, confirm: true },
    { label: "Go to folder", type: ActionTypes.goToFolder },
    { label: "Preview SS-4", type: ActionTypes.previewSS4 },
    { label: "Preview Signed SS4", type: ActionTypes.previewSignedSS4 },
    { label: "Request SS-4 Signature", type: ActionTypes.requestSS4Signature },
    { label: "Fax SS-4 to IRS", type: ActionTypes.faxSS4ToIRS },
    {
      label: "Mark EIN Application as Resubmitted and send email",
      type: ActionTypes.einResubmissionSent,
    },
    {
      label: "Mark EIN Received and send email",
      type: ActionTypes.einReceived,
    },
    { label: "Apply for EIN Online", type: ActionTypes.applyForEinOnline },
    {
      label: "Complete Annual Report Online",
      type: ActionTypes.annualReportOnline,
    },
    // {
    //   label: "Link invoice",
    //   type: ActionTypes.linkInvoice,
    // },
  ];

  const mails = [
    { label: "Request new name", type: EmailTypes.newName },
    { label: "Filing Sent", type: EmailTypes.filingUnderReview },
    { label: "Formation Received", type: EmailTypes.filingReceived },
    { label: "SS4 sent", type: EmailTypes.ss4FaxedToIRS },
    // { label: "Long Time Update", type: EmailTypes.longTimeUpdate },
    { label: "Trust Pilot Request", type: EmailTypes.trustPilot },
    { label: "EIN Received", type: EmailTypes.einReceived },
    { label: "Second Submission Sent", type: EmailTypes.secondSubmissionSent },
    { label: "Annual Report Created", type: EmailTypes.annualReportComplete },
    // { label: "Phone Line", type: EmailTypes.phoneLine },
  ];

  const emailCounts = countBy(
    Object.values(companyEmailsData),
    (email: any) => email?.metadata?.emailType
  );

  return (
    <>
      <Box display="inline-flex" m={1}>
        {actions?.map(
          ({ label, type }: { label: string; type: ActionTypes }, index) => (
            <CustomAction
              key={index}
              label={label}
              type={type}
              company={company}
            />
          )
        )}
      </Box>
      <Box display="inline-flex" m={1}>
        {mails?.map(
          ({ label, type }: { label: string; type: EmailTypes }, index) => {
            return (
              <CustomEmailLink
                key={index}
                label={label}
                type={type}
                company={company}
                emailSentCounter={emailCounts[type]}
              />
            );
          }
        )}
      </Box>
      <Box display="inline-flex" m={1}>
        <ModalInputInvoice companyId={company.id} invoiceId="" />
      </Box>
    </>
  );
};

export const Actions = (props: ServiceActionsContainerProps) => {
  const { company } = props;
  const { data } = useGetManyReference(
    `annotations`,
    "companies",
    company.id,
    defaultPagination,
    defaultSorting,
    filterParams,
    "companies"
  );

  if (!data) {
    return null;
  }
  return (
    <ServiceActions {...props} companyEmailsData={data} company={company} />
  );
};
const ModalInputInvoice = ({
  companyId,
  invoiceId,
}: {
  companyId: string;
  invoiceId: string;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceIdState, setInvoiceId] = useState(invoiceId);
  const handleClick = () => {
    setOpen(true);
  };
  const handleConfirm = async () => {
    setLoading(false);
    await Api.post(`/payments/invoice/${invoiceIdState}/${companyId}`);
    setOpen(false);
    setLoading(false);
  };
  const handleDialogClose = () => {
    setOpen(false);
    setLoading(false);
  };

  return (
    <Fragment>
      <RaButton
        label={"Link Invoice"}
        onClick={handleClick}
        variant="contained"
        size="small"
        color={"secondary"}
        style={{
          marginRight: "10px",
          fontSize: "10px",
          float: "right",
        }}
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Link Stripe subscription to company "
        content={
          <>
            Do you want to link a Stripe subscription to the company by{" "}
            <b>invoice Id</b>?<br />
            <Input
              style={{ paddingTop: "50px" }}
              placeholder="Invoice id: (exp.: in_23123..)"
              onChange={(e) => setInvoiceId(e.target.value)}
            />
            {"  "} <DescriptionIcon style={{ marginTop: "-0px" }} />
          </>
        }
        confirm="connet invoice to company"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />{" "}
    </Fragment>
  );
};

export default Actions;
