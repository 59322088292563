import { FunctionField } from "react-admin";
import _ from "lodash";
export const CurrencyField = (props: { source: string; label: string }) => {
  const { source, label } = props;
  return (
    <FunctionField
      label={label}
      render={(record: any) =>
        `${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(_.get(record, source) / 100)}`
      }
    />
  );
};
