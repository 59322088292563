import DnsIcon from "@material-ui/icons/Dns";
import ServiceShow from "./ServiceShow";
import ServiceEdit from "./ServiceEdit";
import ServiceCreate from "./ServiceCreate";

const services = {
  show: ServiceShow,
  icon: DnsIcon,
  edit: ServiceEdit,
  create: ServiceCreate,
};

export default services;
