import { DataProvider, GetListResult } from "react-admin";
import { ApiClient } from "./amplifyClass";
import { SearchApi } from "./search";
const API_NAME = "api";
const Api = new ApiClient(API_NAME);
const searchableResources = ["companies", "customers", "orders"];

const dataProvider: DataProvider = {
  getList: async (
    resource: string,
    params: any
  ): Promise<GetListResult<any>> => {
    // const { page, perPage } = params.pagination;
    const { order } = params.sort;
    const { nextToken, pageSize } = params.filter;
    const [
      isSearch,
      searchQuery,
      facetQuery,
      facetKeys,
      numericFilters,
    ] = SearchApi.queryBuilder(params?.filter);
    const allStatus = !(
      params?.filter["global:status"] || params?.filter["status"]
    );

    console.log(params?.filter["global:status"] || params?.filter["status"]);
    if (searchableResources.includes(resource) && (isSearch || allStatus)) {
      if (!(window as any)?.searchCreds) {
        const response = await Api.get("/cognito/search");
        if (response.data?.appId) {
          (window as any).searchCreds = response.data;
        }
      }

      const searchClient = new SearchApi({
        appId: (window as any).searchCreds?.appId,
        apiKey: (window as any).searchCreds?.apiKey,
        index: getIndexForResource(resource),
        facets: ["*"],
      });
      const response = await searchClient.search(
        searchQuery as any,
        nextToken || 0,
        facetQuery as any,
        facetKeys as any,
        numericFilters as any
      );
      return {
        data: response.hits,
        total: response.nbHits,
        ...(response.page + 1 < response.nbPages && {
          nextToken: (response.page || 0) + 1,
        }),
      } as any;
    }

    const query = {
      ...(nextToken && { cursor: nextToken }),
      ...(pageSize && { pageSize }),
      ...(params.filter.status && { status: params.filter.status }),
      ...(params.filter["global:status"] && {
        status: params.filter["global:status"],
      }),
      ...(order && { order }),
    };
    const url = `/${resource}${
      query && `?${new URLSearchParams(query).toString()}`
    }`;
    const response = await Api.get(url);
    const responseBody = response.data;
    return {
      data: responseBody.items,
      total: responseBody.items.length + (responseBody.cursor ? 1 : 0),
      nextToken:
        responseBody.cursor &&
        Buffer.from(JSON.stringify(responseBody.cursor)).toString("base64"),
    } as any;
  },

  getOne: async (resource: string, params: any) => {
    const url = `/${resource}/${params.id}`;

    return await Api.get(url);
  },

  getMany: async (resource: string, params: any) => {
    const { ids } = params;
    // maybe this is needed to form the urls:
    // ids.length === 1 ? `/${resource}/${ids[0]}` :

    const url = `/${resource}${
      ids &&
      `?${new URLSearchParams({
        ids: ids.join(","),
      }).toString()}`
    }`;
    const response = await Api.get(url);
    const responseBody = response.data;

    return {
      data: responseBody.items,
      total: responseBody.items.length,
    };
  },

  getManyReference: async (resource: string, params: any) => {
    const url = `/${resource}${"/" + params.target || ""}/${params.id}`;
    const body = !params.target ? { id: params.id } : {};
    const response = await Api.get(url, body);
    const responseBody = response.data;

    return {
      data: responseBody.items,
      total: responseBody.items.length,
    };
  },

  update: async (resource: string, params: any) => {
    const url = `/${resource}`;
    console.log("params");
    console.log(params);
    const body = { id: params.id, ...params.data };
    const response = await Api.put(url, body);
    return response;
  },

  updateMany: async (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

    const url = `/${resource}`;
    const response = await Api.get(url, query);
    const responseBody = response.body;

    return {
      data: responseBody.items,
      total: responseBody.items.length,
    };
  },

  create: async (resource: string, params: any) => {
    const url = `/${resource}`;

    return await Api.post(url, params.data);
  },
  delete: async (resource: string, params: any) => {
    const url = `/${resource}/${params.id}`;
    const query = { id: params.id };
    const response = await Api.delete(url, query);
    return response;
  },

  deleteMany: async (resource: string, params: any) => {
    const body = {
      filter: JSON.stringify({ id: params.ids }),
    };

    const url = `/${resource}`;
    const response = await Api.get(url, body);
    const responseBody = response.body;

    return {
      data: responseBody.items,
      total: responseBody.items.length,
    };
  },

  customPost: async (resource: string, body: any) => {
    console.log("custom post request");
    const url = `/${resource}`;
    const response = await Api.post(url, body);
    return {
      data: response,
    };
  },
};

export default dataProvider;

function getIndexForResource(resource: string): string {
  switch (resource) {
    case "companies":
      return "company";
    case "orders":
      return "order";
    case "customers":
      return "customer";
    default:
      return resource;
  }
}
