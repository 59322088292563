const data = {
  companies: [
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM1",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e1",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM2",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e3",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM4",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e4",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM5",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e5",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM10",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM6",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e6",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM7",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e7",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM8",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e8",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM9",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e9",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
  ],
  customers: [
    {
      fullName: "Jimmy Newtron",
      email: "foo@bar.com",
      phone: "+49 123132",
      id: "151ae153-9a65-4aa0-9ba6-7488846c906e",
      companies: ["1xYGVrPcJOdmi3K3J6bypnQOZQM"],
      referredBy: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      referrals: ["1xYGVrPcJOdmi3K3J6bypnQOZQM"],
    },
    {
      fullName: "Jimmy Newtron",
      email: "foo@bar.com",
      phone: "+49 123132",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      companies: ["1xYGVrPcJOdmi3K3J6bypnQOZQM1"],
      referredBy: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      referrals: ["1xYGVrPcJOdmi3K3J6bypnQOZQM"],
    },
    {
      fullName: "Jimmy Newtron",
      email: "foo@bar.com",
      phone: "+49 123132",
      id: "151ae153-9a65-4aa0-9ba6-7488846c906e2",
      companies: ["1xYGVrPcJOdmi3K3J6bypnQOZQM2"],
      referredBy: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      referrals: ["1xYGVrPcJOdmi3K3J6bypnQOZQM"],
    },
    {
      fullName: "Jimmy Newtron",
      email: "foo@bar.com",
      phone: "+49 123132",
      id: "151ae153-9a65-4aa0-9ba6-7488846c906e3",
      companies: ["1xYGVrPcJOdmi3K3J6bypnQOZQM3"],
      referredBy: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      referrals: ["1xYGVrPcJOdmi3K3J6bypnQOZQM"],
    },
    {
      fullName: "Jimmy Newtron",
      email: "foo@bar.com",
      phone: "+49 123132",
      id: "151ae153-9a65-4aa0-9ba6-7488846c906e4",
      companies: ["1xYGVrPcJOdmi3K3J6bypnQOZQM4"],
      referredBy: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      referrals: ["1xYGVrPcJOdmi3K3J6bypnQOZQM"],
    },
    {
      fullName: "Jimmy Newtron",
      email: "foo@bar.com",
      phone: "+49 123132",
      id: "151ae153-9a65-4aa0-9ba6-7488846c906e5",
      companies: ["1xYGVrPcJOdmi3K3J6bypnQOZQM5"],
      referredBy: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      referrals: ["1xYGVrPcJOdmi3K3J6bypnQOZQM"],
    },
    {
      fullName: "Jimmy Newtron",
      email: "foo@bar.com",
      phone: "+49 123132",
      id: "151ae153-9a65-4aa0-9ba6-7488846c906e6",
      companies: ["1xYGVrPcJOdmi3K3J6bypnQOZQM6"],
      referredBy: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      referrals: ["1xYGVrPcJOdmi3K3J6bypnQOZQM"],
    },
  ],
  orders: [
    {
      id: "in_1JUDUaGIhH9I2BWrHNx04Ks2",
      companyId: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      object: "invoice",
      account_country: "US",
      account_name: "Distributed Inc.",
      account_tax_ids: null,
      amount_due: 109900,
      amount_paid: 109900,
      amount_remaining: 0,
      application_fee_amount: null,
      attempt_count: 1,
      attempted: true,
      auto_advance: false,
      automatic_tax: {
        enabled: false,
        status: null,
      },
      billing_reason: "subscription_create",
      charge: "ch_3JUDUaGIhH9I2BWr0Gfn6fOG",
      collection_method: "charge_automatically",
      created: 1630341472,
      currency: "usd",
      custom_fields: null,
      customer: "cus_K8UTw11orMVPHT",
      customer_address: null,
      customer_email: "nacho+100@doola.com",
      customer_id: "nacho+100@doola.com",
      company_id: "nacho+100@doola.com",
      customer_name: null,
      customer_phone: null,
      customer_shipping: null,
      customer_tax_exempt: "none",
      customer_tax_ids: [],
      default_payment_method: null,
      default_source: null,
      default_tax_rates: [],
      description: null,
      discount: null,
      discounts: [],
      due_date: null,
      ending_balance: 0,
      footer: null,
      hosted_invoice_url:
        "https://invoice.stripe.com/i/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A",
      invoice_pdf:
        "https://pay.stripe.com/invoice/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A/pdf",
      last_finalization_error: null,
      lines: {
        object: "list",
        data: [
          {
            id: "il_1JUDUaGIhH9I2BWrF4VEPNiz",
            object: "line_item",
            amount: 10000,
            currency: "usd",
            description: "WY LLC Formation Fee",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            invoice_item: "ii_1JUDUaGIhH9I2BWr0QU8od4T",
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: null,
            price: {
              id: "price_1IE1ERGIhH9I2BWruDukbDpo",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1611704759,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: "WY LLC Formation Fee price",
              product: "prod_IpgbKuOQbrEgZp",
              recurring: null,
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "one_time",
              unit_amount: 10000,
              unit_amount_decimal: "10000",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            tax_amounts: [],
            tax_rates: [],
            type: "invoiceitem",
          },
          {
            id: "il_1JUDUaGIhH9I2BWryi7uJ09p",
            object: "line_item",
            amount: 99900,
            currency: "usd",
            description: "1 × Premium Plan v3 (at $999.00 / year)",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "plan",
              active: true,
              aggregate_usage: null,
              amount: 99900,
              amount_decimal: "99900",
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              interval: "year",
              interval_count: 1,
              livemode: false,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              tiers_mode: null,
              transform_usage: null,
              trial_period_days: null,
              usage_type: "licensed",
            },
            price: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              recurring: {
                aggregate_usage: null,
                interval: "year",
                interval_count: 1,
                trial_period_days: null,
                usage_type: "licensed",
              },
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "recurring",
              unit_amount: 99900,
              unit_amount_decimal: "99900",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            subscription_item: "si_K8UTDtKiTd8rtb",
            tax_amounts: [],
            tax_rates: [],
            type: "subscription",
          },
        ],
        has_more: false,
        total_count: 2,
        url: "/v1/invoices/in_1JUDUaGIhH9I2BWrHNx04Ks2/lines",
      },
      livemode: false,
      metadata: {},
      next_payment_attempt: null,
      number: "9A89FB7A-0001",
      on_behalf_of: null,
      paid: true,
      paper_checks: [],
      payment_intent: "pi_3JUDUaGIhH9I2BWr0V2HlRXz",
      payment_settings: {
        payment_method_options: null,
        payment_method_types: null,
      },
      period_end: 1630341471,
      period_start: 1630341471,
      post_payment_credit_notes_amount: 0,
      pre_payment_credit_notes_amount: 0,
      quote: null,
      receipt_number: "2013-6112",
      starting_balance: 0,
      statement_descriptor: null,
      status: "paid",
      status_transitions: {
        finalized_at: 1630341471,
        marked_uncollectible_at: null,
        paid_at: 1630341471,
        voided_at: null,
      },
      subscription: "sub_K8UTQoIUom1mmF",
      subtotal: 109900,
      tax: null,
      total: 109900,
      total_discount_amounts: [],
      total_tax_amounts: [],
      transfer_data: null,
      webhooks_delivered_at: 1630341476,
    },
    {
      id: "in_1JUDUaGIhH9I2BWrHNx04Ks21",
      companyId: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      object: "invoice",
      account_country: "US",
      account_name: "Distributed Inc.",
      account_tax_ids: null,
      amount_due: 109900,
      amount_paid: 109900,
      amount_remaining: 0,
      application_fee_amount: null,
      attempt_count: 1,
      attempted: true,
      auto_advance: false,
      automatic_tax: {
        enabled: false,
        status: null,
      },
      billing_reason: "subscription_create",
      charge: "ch_3JUDUaGIhH9I2BWr0Gfn6fOG",
      collection_method: "charge_automatically",
      created: 1630341472,
      currency: "usd",
      custom_fields: null,
      customer: "cus_K8UTw11orMVPHT",
      customer_address: null,
      customer_email: "nacho+100@doola.com",
      customer_id: "nacho+100@doola.com",
      company_id: "nacho+100@doola.com",
      customer_name: null,
      customer_phone: null,
      customer_shipping: null,
      customer_tax_exempt: "none",
      customer_tax_ids: [],
      default_payment_method: null,
      default_source: null,
      default_tax_rates: [],
      description: null,
      discount: null,
      discounts: [],
      due_date: null,
      ending_balance: 0,
      footer: null,
      hosted_invoice_url:
        "https://invoice.stripe.com/i/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A",
      invoice_pdf:
        "https://pay.stripe.com/invoice/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A/pdf",
      last_finalization_error: null,
      lines: {
        object: "list",
        data: [
          {
            id: "il_1JUDUaGIhH9I2BWrF4VEPNiz",
            object: "line_item",
            amount: 10000,
            currency: "usd",
            description: "WY LLC Formation Fee",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            invoice_item: "ii_1JUDUaGIhH9I2BWr0QU8od4T",
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: null,
            price: {
              id: "price_1IE1ERGIhH9I2BWruDukbDpo",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1611704759,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: "WY LLC Formation Fee price",
              product: "prod_IpgbKuOQbrEgZp",
              recurring: null,
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "one_time",
              unit_amount: 10000,
              unit_amount_decimal: "10000",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            tax_amounts: [],
            tax_rates: [],
            type: "invoiceitem",
          },
          {
            id: "il_1JUDUaGIhH9I2BWryi7uJ09p",
            object: "line_item",
            amount: 99900,
            currency: "usd",
            description: "1 × Premium Plan v3 (at $999.00 / year)",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "plan",
              active: true,
              aggregate_usage: null,
              amount: 99900,
              amount_decimal: "99900",
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              interval: "year",
              interval_count: 1,
              livemode: false,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              tiers_mode: null,
              transform_usage: null,
              trial_period_days: null,
              usage_type: "licensed",
            },
            price: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              recurring: {
                aggregate_usage: null,
                interval: "year",
                interval_count: 1,
                trial_period_days: null,
                usage_type: "licensed",
              },
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "recurring",
              unit_amount: 99900,
              unit_amount_decimal: "99900",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            subscription_item: "si_K8UTDtKiTd8rtb",
            tax_amounts: [],
            tax_rates: [],
            type: "subscription",
          },
        ],
        has_more: false,
        total_count: 2,
        url: "/v1/invoices/in_1JUDUaGIhH9I2BWrHNx04Ks2/lines",
      },
      livemode: false,
      metadata: {},
      next_payment_attempt: null,
      number: "9A89FB7A-0001",
      on_behalf_of: null,
      paid: true,
      paper_checks: [],
      payment_intent: "pi_3JUDUaGIhH9I2BWr0V2HlRXz",
      payment_settings: {
        payment_method_options: null,
        payment_method_types: null,
      },
      period_end: 1630341471,
      period_start: 1630341471,
      post_payment_credit_notes_amount: 0,
      pre_payment_credit_notes_amount: 0,
      quote: null,
      receipt_number: "2013-6112",
      starting_balance: 0,
      statement_descriptor: null,
      status: "paid",
      status_transitions: {
        finalized_at: 1630341471,
        marked_uncollectible_at: null,
        paid_at: 1630341471,
        voided_at: null,
      },
      subscription: "sub_K8UTQoIUom1mmF",
      subtotal: 109900,
      tax: null,
      total: 109900,
      total_discount_amounts: [],
      total_tax_amounts: [],
      transfer_data: null,
      webhooks_delivered_at: 1630341476,
    },
    {
      id: "in_1JUDUaGIhH9I2BWrHNx04Ks22",
      companyId: "1xYGVrPcJOdmi3K3J6bypnQOZQM1",
      object: "invoice",
      account_country: "US",
      account_name: "Distributed Inc.",
      account_tax_ids: null,
      amount_due: 109900,
      amount_paid: 109900,
      amount_remaining: 0,
      application_fee_amount: null,
      attempt_count: 1,
      attempted: true,
      auto_advance: false,
      automatic_tax: {
        enabled: false,
        status: null,
      },
      billing_reason: "subscription_create",
      charge: "ch_3JUDUaGIhH9I2BWr0Gfn6fOG",
      collection_method: "charge_automatically",
      created: 1630341472,
      currency: "usd",
      custom_fields: null,
      customer: "cus_K8UTw11orMVPHT",
      customer_address: null,
      customer_email: "nacho+100@doola.com",
      customer_id: "nacho+100@doola.com",
      company_id: "nacho+100@doola.com",
      customer_name: null,
      customer_phone: null,
      customer_shipping: null,
      customer_tax_exempt: "none",
      customer_tax_ids: [],
      default_payment_method: null,
      default_source: null,
      default_tax_rates: [],
      description: null,
      discount: null,
      discounts: [],
      due_date: null,
      ending_balance: 0,
      footer: null,
      hosted_invoice_url:
        "https://invoice.stripe.com/i/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A",
      invoice_pdf:
        "https://pay.stripe.com/invoice/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A/pdf",
      last_finalization_error: null,
      lines: {
        object: "list",
        data: [
          {
            id: "il_1JUDUaGIhH9I2BWrF4VEPNiz",
            object: "line_item",
            amount: 10000,
            currency: "usd",
            description: "WY LLC Formation Fee",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            invoice_item: "ii_1JUDUaGIhH9I2BWr0QU8od4T",
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: null,
            price: {
              id: "price_1IE1ERGIhH9I2BWruDukbDpo",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1611704759,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: "WY LLC Formation Fee price",
              product: "prod_IpgbKuOQbrEgZp",
              recurring: null,
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "one_time",
              unit_amount: 10000,
              unit_amount_decimal: "10000",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            tax_amounts: [],
            tax_rates: [],
            type: "invoiceitem",
          },
          {
            id: "il_1JUDUaGIhH9I2BWryi7uJ09p",
            object: "line_item",
            amount: 99900,
            currency: "usd",
            description: "1 × Premium Plan v3 (at $999.00 / year)",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "plan",
              active: true,
              aggregate_usage: null,
              amount: 99900,
              amount_decimal: "99900",
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              interval: "year",
              interval_count: 1,
              livemode: false,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              tiers_mode: null,
              transform_usage: null,
              trial_period_days: null,
              usage_type: "licensed",
            },
            price: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              recurring: {
                aggregate_usage: null,
                interval: "year",
                interval_count: 1,
                trial_period_days: null,
                usage_type: "licensed",
              },
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "recurring",
              unit_amount: 99900,
              unit_amount_decimal: "99900",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            subscription_item: "si_K8UTDtKiTd8rtb",
            tax_amounts: [],
            tax_rates: [],
            type: "subscription",
          },
        ],
        has_more: false,
        total_count: 2,
        url: "/v1/invoices/in_1JUDUaGIhH9I2BWrHNx04Ks2/lines",
      },
      livemode: false,
      metadata: {},
      next_payment_attempt: null,
      number: "9A89FB7A-0001",
      on_behalf_of: null,
      paid: true,
      paper_checks: [],
      payment_intent: "pi_3JUDUaGIhH9I2BWr0V2HlRXz",
      payment_settings: {
        payment_method_options: null,
        payment_method_types: null,
      },
      period_end: 1630341471,
      period_start: 1630341471,
      post_payment_credit_notes_amount: 0,
      pre_payment_credit_notes_amount: 0,
      quote: null,
      receipt_number: "2013-6112",
      starting_balance: 0,
      statement_descriptor: null,
      status: "paid",
      status_transitions: {
        finalized_at: 1630341471,
        marked_uncollectible_at: null,
        paid_at: 1630341471,
        voided_at: null,
      },
      subscription: "sub_K8UTQoIUom1mmF",
      subtotal: 109900,
      tax: null,
      total: 109900,
      total_discount_amounts: [],
      total_tax_amounts: [],
      transfer_data: null,
      webhooks_delivered_at: 1630341476,
    },
    {
      id: "in_1JUDUaGIhH9I2BWrHNx04Ks23",
      companyId: "1xYGVrPcJOdmi3K3J6bypnQOZQM3",
      object: "invoice",
      account_country: "US",
      account_name: "Distributed Inc.",
      account_tax_ids: null,
      amount_due: 109900,
      amount_paid: 109900,
      amount_remaining: 0,
      application_fee_amount: null,
      attempt_count: 1,
      attempted: true,
      auto_advance: false,
      automatic_tax: {
        enabled: false,
        status: null,
      },
      billing_reason: "subscription_create",
      charge: "ch_3JUDUaGIhH9I2BWr0Gfn6fOG",
      collection_method: "charge_automatically",
      created: 1630341472,
      currency: "usd",
      custom_fields: null,
      customer: "cus_K8UTw11orMVPHT",
      customer_address: null,
      customer_email: "nacho+100@doola.com",
      customer_id: "nacho+100@doola.com",
      company_id: "nacho+100@doola.com",
      customer_name: null,
      customer_phone: null,
      customer_shipping: null,
      customer_tax_exempt: "none",
      customer_tax_ids: [],
      default_payment_method: null,
      default_source: null,
      default_tax_rates: [],
      description: null,
      discount: null,
      discounts: [],
      due_date: null,
      ending_balance: 0,
      footer: null,
      hosted_invoice_url:
        "https://invoice.stripe.com/i/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A",
      invoice_pdf:
        "https://pay.stripe.com/invoice/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A/pdf",
      last_finalization_error: null,
      lines: {
        object: "list",
        data: [
          {
            id: "il_1JUDUaGIhH9I2BWrF4VEPNiz",
            object: "line_item",
            amount: 10000,
            currency: "usd",
            description: "WY LLC Formation Fee",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            invoice_item: "ii_1JUDUaGIhH9I2BWr0QU8od4T",
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: null,
            price: {
              id: "price_1IE1ERGIhH9I2BWruDukbDpo",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1611704759,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: "WY LLC Formation Fee price",
              product: "prod_IpgbKuOQbrEgZp",
              recurring: null,
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "one_time",
              unit_amount: 10000,
              unit_amount_decimal: "10000",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            tax_amounts: [],
            tax_rates: [],
            type: "invoiceitem",
          },
          {
            id: "il_1JUDUaGIhH9I2BWryi7uJ09p",
            object: "line_item",
            amount: 99900,
            currency: "usd",
            description: "1 × Premium Plan v3 (at $999.00 / year)",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "plan",
              active: true,
              aggregate_usage: null,
              amount: 99900,
              amount_decimal: "99900",
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              interval: "year",
              interval_count: 1,
              livemode: false,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              tiers_mode: null,
              transform_usage: null,
              trial_period_days: null,
              usage_type: "licensed",
            },
            price: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              recurring: {
                aggregate_usage: null,
                interval: "year",
                interval_count: 1,
                trial_period_days: null,
                usage_type: "licensed",
              },
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "recurring",
              unit_amount: 99900,
              unit_amount_decimal: "99900",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            subscription_item: "si_K8UTDtKiTd8rtb",
            tax_amounts: [],
            tax_rates: [],
            type: "subscription",
          },
        ],
        has_more: false,
        total_count: 2,
        url: "/v1/invoices/in_1JUDUaGIhH9I2BWrHNx04Ks2/lines",
      },
      livemode: false,
      metadata: {},
      next_payment_attempt: null,
      number: "9A89FB7A-0001",
      on_behalf_of: null,
      paid: true,
      paper_checks: [],
      payment_intent: "pi_3JUDUaGIhH9I2BWr0V2HlRXz",
      payment_settings: {
        payment_method_options: null,
        payment_method_types: null,
      },
      period_end: 1630341471,
      period_start: 1630341471,
      post_payment_credit_notes_amount: 0,
      pre_payment_credit_notes_amount: 0,
      quote: null,
      receipt_number: "2013-6112",
      starting_balance: 0,
      statement_descriptor: null,
      status: "paid",
      status_transitions: {
        finalized_at: 1630341471,
        marked_uncollectible_at: null,
        paid_at: 1630341471,
        voided_at: null,
      },
      subscription: "sub_K8UTQoIUom1mmF",
      subtotal: 109900,
      tax: null,
      total: 109900,
      total_discount_amounts: [],
      total_tax_amounts: [],
      transfer_data: null,
      webhooks_delivered_at: 1630341476,
    },
    {
      id: "in_1JUDUaGIhH9I2BWrHNx04Ks24",
      companyId: "1xYGVrPcJOdmi3K3J6bypnQOZQM4",
      object: "invoice",
      account_country: "US",
      account_name: "Distributed Inc.",
      account_tax_ids: null,
      amount_due: 109900,
      amount_paid: 109900,
      amount_remaining: 0,
      application_fee_amount: null,
      attempt_count: 1,
      attempted: true,
      auto_advance: false,
      automatic_tax: {
        enabled: false,
        status: null,
      },
      billing_reason: "subscription_create",
      charge: "ch_3JUDUaGIhH9I2BWr0Gfn6fOG",
      collection_method: "charge_automatically",
      created: 1630341472,
      currency: "usd",
      custom_fields: null,
      customer: "cus_K8UTw11orMVPHT",
      customer_address: null,
      customer_email: "nacho+100@doola.com",
      customer_id: "nacho+100@doola.com",
      company_id: "nacho+100@doola.com",
      customer_name: null,
      customer_phone: null,
      customer_shipping: null,
      customer_tax_exempt: "none",
      customer_tax_ids: [],
      default_payment_method: null,
      default_source: null,
      default_tax_rates: [],
      description: null,
      discount: null,
      discounts: [],
      due_date: null,
      ending_balance: 0,
      footer: null,
      hosted_invoice_url:
        "https://invoice.stripe.com/i/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A",
      invoice_pdf:
        "https://pay.stripe.com/invoice/acct_1HRL70GIhH9I2BWr/invst_K8UT0Cesg3o448XAH8x2571lvIa6J2A/pdf",
      last_finalization_error: null,
      lines: {
        object: "list",
        data: [
          {
            id: "il_1JUDUaGIhH9I2BWrF4VEPNiz",
            object: "line_item",
            amount: 10000,
            currency: "usd",
            description: "WY LLC Formation Fee",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            invoice_item: "ii_1JUDUaGIhH9I2BWr0QU8od4T",
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: null,
            price: {
              id: "price_1IE1ERGIhH9I2BWruDukbDpo",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1611704759,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: "WY LLC Formation Fee price",
              product: "prod_IpgbKuOQbrEgZp",
              recurring: null,
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "one_time",
              unit_amount: 10000,
              unit_amount_decimal: "10000",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            tax_amounts: [],
            tax_rates: [],
            type: "invoiceitem",
          },
          {
            id: "il_1JUDUaGIhH9I2BWryi7uJ09p",
            object: "line_item",
            amount: 99900,
            currency: "usd",
            description: "1 × Premium Plan v3 (at $999.00 / year)",
            discount_amounts: [],
            discountable: true,
            discounts: [],
            livemode: false,
            metadata: {},
            period: {
              end: 1661877471,
              start: 1630341471,
            },
            plan: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "plan",
              active: true,
              aggregate_usage: null,
              amount: 99900,
              amount_decimal: "99900",
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              interval: "year",
              interval_count: 1,
              livemode: false,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              tiers_mode: null,
              transform_usage: null,
              trial_period_days: null,
              usage_type: "licensed",
            },
            price: {
              id: "price_1JK2mMGIhH9I2BWrJVxctji7",
              object: "price",
              active: true,
              billing_scheme: "per_unit",
              created: 1627917010,
              currency: "usd",
              livemode: false,
              lookup_key: null,
              metadata: {},
              nickname: null,
              product: "prod_JxyjMV5cvkzuEI",
              recurring: {
                aggregate_usage: null,
                interval: "year",
                interval_count: 1,
                trial_period_days: null,
                usage_type: "licensed",
              },
              tax_behavior: "unspecified",
              tiers_mode: null,
              transform_quantity: null,
              type: "recurring",
              unit_amount: 99900,
              unit_amount_decimal: "99900",
            },
            proration: false,
            quantity: 1,
            subscription: "sub_K8UTQoIUom1mmF",
            subscription_item: "si_K8UTDtKiTd8rtb",
            tax_amounts: [],
            tax_rates: [],
            type: "subscription",
          },
        ],
        has_more: false,
        total_count: 2,
        url: "/v1/invoices/in_1JUDUaGIhH9I2BWrHNx04Ks2/lines",
      },
      livemode: false,
      metadata: {},
      next_payment_attempt: null,
      number: "9A89FB7A-0001",
      on_behalf_of: null,
      paid: true,
      paper_checks: [],
      payment_intent: "pi_3JUDUaGIhH9I2BWr0V2HlRXz",
      payment_settings: {
        payment_method_options: null,
        payment_method_types: null,
      },
      period_end: 1630341471,
      period_start: 1630341471,
      post_payment_credit_notes_amount: 0,
      pre_payment_credit_notes_amount: 0,
      quote: null,
      receipt_number: "2013-6112",
      starting_balance: 0,
      statement_descriptor: null,
      status: "paid",
      status_transitions: {
        finalized_at: 1630341471,
        marked_uncollectible_at: null,
        paid_at: 1630341471,
        voided_at: null,
      },
      subscription: "sub_K8UTQoIUom1mmF",
      subtotal: 109900,
      tax: null,
      total: 109900,
      total_discount_amounts: [],
      total_tax_amounts: [],
      transfer_data: null,
      webhooks_delivered_at: 1630341476,
    },
  ],
  products: [
    {
      id: "il_1JUDUaGIhH9I2BWryi7uJ09p",
      orderId: "in_1JUDUaGIhH9I2BWrHNx04Ks2",
      serviceIds: ["JUDUaGIhH9I2BWrHNx"],
      object: "line_item",
      amount: 99900,
      currency: "usd",
      description: "1 × Premium Plan v3 (at $999.00 / year)",
      discount_amounts: [],
      discountable: true,
      discounts: [],
      livemode: false,
      metadata: {},
      period: {
        end: 1661877471,
        start: 1630341471,
      },
      plan: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "plan",
        active: true,
        aggregate_usage: null,
        amount: 99900,
        amount_decimal: "99900",
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        interval: "year",
        interval_count: 1,
        livemode: false,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      price: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "price",
        active: true,
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        recurring: {
          aggregate_usage: null,
          interval: "year",
          interval_count: 1,
          trial_period_days: null,
          usage_type: "licensed",
        },
        tax_behavior: "unspecified",
        tiers_mode: null,
        transform_quantity: null,
        type: "recurring",
        unit_amount: 99900,
        unit_amount_decimal: "99900",
      },
      proration: false,
      quantity: 1,
      subscription: "sub_K8UTQoIUom1mmF",
      subscription_item: "si_K8UTDtKiTd8rtb",
      tax_amounts: [],
      tax_rates: [],
      type: "subscription",
    },
    {
      id: "il_1JUDUaGIhH9I2BWryi7uJ09p1",
      orderId: "in_1JUDUaGIhH9I2BWrHNx04Ks21",
      serviceIds: ["JUDUaGIhH9I2BWrHNx"],
      object: "line_item",
      amount: 99900,
      currency: "usd",
      description: "1 × Premium Plan v3 (at $999.00 / year)",
      discount_amounts: [],
      discountable: true,
      discounts: [],
      livemode: false,
      metadata: {},
      period: {
        end: 1661877471,
        start: 1630341471,
      },
      plan: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "plan",
        active: true,
        aggregate_usage: null,
        amount: 99900,
        amount_decimal: "99900",
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        interval: "year",
        interval_count: 1,
        livemode: false,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      price: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "price",
        active: true,
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        recurring: {
          aggregate_usage: null,
          interval: "year",
          interval_count: 1,
          trial_period_days: null,
          usage_type: "licensed",
        },
        tax_behavior: "unspecified",
        tiers_mode: null,
        transform_quantity: null,
        type: "recurring",
        unit_amount: 99900,
        unit_amount_decimal: "99900",
      },
      proration: false,
      quantity: 1,
      subscription: "sub_K8UTQoIUom1mmF",
      subscription_item: "si_K8UTDtKiTd8rtb",
      tax_amounts: [],
      tax_rates: [],
      type: "subscription",
    },
    {
      id: "il_1JUDUaGIhH9I2BWryi7uJ09p2",
      orderId: "in_1JUDUaGIhH9I2BWrHNx04Ks22",
      serviceIds: ["JUDUaGIhH9I2BWrHNx"],
      object: "line_item",
      amount: 99900,
      currency: "usd",
      description: "1 × Premium Plan v3 (at $999.00 / year)",
      discount_amounts: [],
      discountable: true,
      discounts: [],
      livemode: false,
      metadata: {},
      period: {
        end: 1661877471,
        start: 1630341471,
      },
      plan: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "plan",
        active: true,
        aggregate_usage: null,
        amount: 99900,
        amount_decimal: "99900",
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        interval: "year",
        interval_count: 1,
        livemode: false,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      price: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "price",
        active: true,
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        recurring: {
          aggregate_usage: null,
          interval: "year",
          interval_count: 1,
          trial_period_days: null,
          usage_type: "licensed",
        },
        tax_behavior: "unspecified",
        tiers_mode: null,
        transform_quantity: null,
        type: "recurring",
        unit_amount: 99900,
        unit_amount_decimal: "99900",
      },
      proration: false,
      quantity: 1,
      subscription: "sub_K8UTQoIUom1mmF",
      subscription_item: "si_K8UTDtKiTd8rtb",
      tax_amounts: [],
      tax_rates: [],
      type: "subscription",
    },
    {
      id: "il_1JUDUaGIhH9I2BWryi7uJ09p3",
      orderId: "in_1JUDUaGIhH9I2BWrHNx04Ks23",
      serviceIds: ["JUDUaGIhH9I2BWrHNx"],
      object: "line_item",
      amount: 99900,
      currency: "usd",
      description: "1 × Premium Plan v3 (at $999.00 / year)",
      discount_amounts: [],
      discountable: true,
      discounts: [],
      livemode: false,
      metadata: {},
      period: {
        end: 1661877471,
        start: 1630341471,
      },
      plan: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "plan",
        active: true,
        aggregate_usage: null,
        amount: 99900,
        amount_decimal: "99900",
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        interval: "year",
        interval_count: 1,
        livemode: false,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      price: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "price",
        active: true,
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        recurring: {
          aggregate_usage: null,
          interval: "year",
          interval_count: 1,
          trial_period_days: null,
          usage_type: "licensed",
        },
        tax_behavior: "unspecified",
        tiers_mode: null,
        transform_quantity: null,
        type: "recurring",
        unit_amount: 99900,
        unit_amount_decimal: "99900",
      },
      proration: false,
      quantity: 1,
      subscription: "sub_K8UTQoIUom1mmF",
      subscription_item: "si_K8UTDtKiTd8rtb",
      tax_amounts: [],
      tax_rates: [],
      type: "subscription",
    },
    {
      id: "il_1JUDUaGIhH9I2BWryi7uJ09p4",
      orderId: "in_1JUDUaGIhH9I2BWrHNx04Ks24",
      serviceIds: ["JUDUaGIhH9I2BWrHNx"],
      object: "line_item",
      amount: 99900,
      currency: "usd",
      description: "1 × Premium Plan v3 (at $999.00 / year)",
      discount_amounts: [],
      discountable: true,
      discounts: [],
      livemode: false,
      metadata: {},
      period: {
        end: 1661877471,
        start: 1630341471,
      },
      plan: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "plan",
        active: true,
        aggregate_usage: null,
        amount: 99900,
        amount_decimal: "99900",
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        interval: "year",
        interval_count: 1,
        livemode: false,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      price: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "price",
        active: true,
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        recurring: {
          aggregate_usage: null,
          interval: "year",
          interval_count: 1,
          trial_period_days: null,
          usage_type: "licensed",
        },
        tax_behavior: "unspecified",
        tiers_mode: null,
        transform_quantity: null,
        type: "recurring",
        unit_amount: 99900,
        unit_amount_decimal: "99900",
      },
      proration: false,
      quantity: 1,
      subscription: "sub_K8UTQoIUom1mmF",
      subscription_item: "si_K8UTDtKiTd8rtb",
      tax_amounts: [],
      tax_rates: [],
      type: "subscription",
    },
    {
      id: "il_1JUDUaGIhH9I2BWryi7uJ09p5",
      orderId: "in_1JUDUaGIhH9I2BWrHNx04Ks25",
      serviceIds: ["JUDUaGIhH9I2BWrHNx"],
      object: "line_item",
      amount: 99900,
      currency: "usd",
      description: "1 × Premium Plan v3 (at $999.00 / year)",
      discount_amounts: [],
      discountable: true,
      discounts: [],
      livemode: false,
      metadata: {},
      period: {
        end: 1661877471,
        start: 1630341471,
      },
      plan: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "plan",
        active: true,
        aggregate_usage: null,
        amount: 99900,
        amount_decimal: "99900",
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        interval: "year",
        interval_count: 1,
        livemode: false,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      price: {
        id: "price_1JK2mMGIhH9I2BWrJVxctji7",
        object: "price",
        active: true,
        billing_scheme: "per_unit",
        created: 1627917010,
        currency: "usd",
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: "prod_JxyjMV5cvkzuEI",
        recurring: {
          aggregate_usage: null,
          interval: "year",
          interval_count: 1,
          trial_period_days: null,
          usage_type: "licensed",
        },
        tax_behavior: "unspecified",
        tiers_mode: null,
        transform_quantity: null,
        type: "recurring",
        unit_amount: 99900,
        unit_amount_decimal: "99900",
      },
      proration: false,
      quantity: 1,
      subscription: "sub_K8UTQoIUom1mmF",
      subscription_item: "si_K8UTDtKiTd8rtb",
      tax_amounts: [],
      tax_rates: [],
      type: "subscription",
    },
  ],
  services: [
    {
      id: "JUDUaGIhH9I2BWrHNx",
      name: "phoneLine",
      status: "waiting",
      airTableStatus: "// From airtable",
      description:
        'bla\nnotes: from the filer\ncomment: Great progress\ncompanyId: 123123\ninvoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks2"',
      options: [
        {
          key: "speed filing",
          value: true,
        },
      ],
      invoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks2",
      customerData: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      },
      companyData: {
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
        GdriveId: "asdasd",
        LLCStyle: "LLC",
        timezone: "EU/Central",
        companyName: "Foo Bar industries",
        createdAt: "2021",
        creator: {
          fullName: "johnny be good",
          email: "johnny@gmail.com",
          phone: "0123123",
        },
      },
    },
    {
      id: "JUDUaGIhH9I2BWrHNx1",
      name: "taxFiling ",
      status: "waiting",
      airTableStatus: "// From airtable",
      description:
        'bla\nnotes: from the filer\ncomment: Great progress\ncompanyId: 123123\ninvoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks2"',
      options: [
        {
          key: "speed filing",
          value: true,
        },
      ],
      invoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks21",
      customerData: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM1",
      },
      companyData: {
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM1",
        GdriveId: "asdasd",
        LLCStyle: "LLC",
        timezone: "EU/Central",
        companyName: "Foo Bar industries",
        createdAt: "2021",
        creator: {
          fullName: "johnny be good",
          email: "johnny@gmail.com",
          phone: "0123123",
        },
      },
    },
    {
      id: "JUDUaGIhH9I2BWrHNx2",
      name: "llcFormation",
      status: "filed",
      airTableStatus: "// From airtable",
      description:
        'bla\nnotes: from the filer\ncomment: Great progress\ncompanyId: 123123\ninvoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks2"',
      options: [
        {
          key: "speed filing",
          value: true,
        },
      ],
      invoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks22",
      customerData: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      },
      companyData: {
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
        GdriveId: "asdasd",
        LLCStyle: "LLC",
        timezone: "EU/Central",
        companyName: "Foo Bar industries",
        createdAt: "2021",
        creator: {
          fullName: "johnny be good",
          email: "johnny@gmail.com",
          phone: "0123123",
        },
      },
    },
    {
      id: "JUDUaGIhH9I2BWrHNx3",
      name: "phoneLine ",
      status: "waiting",
      airTableStatus: "// From airtable",
      description:
        'bla\nnotes: from the filer\ncomment: Great progress\ncompanyId: 123123\ninvoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks2"',
      options: [
        {
          key: "speed filing",
          value: true,
        },
      ],
      invoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks23",
      customerData: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      },
      companyData: {
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
        GdriveId: "asdasd",
        LLCStyle: "LLC",
        timezone: "EU/Central",
        companyName: "Foo Bar industries",
        createdAt: "2021",
        creator: {
          fullName: "johnny be good",
          email: "johnny@gmail.com",
          phone: "0123123",
        },
      },
    },
    {
      id: "JUDUaGIhH9I2BWrHNx4",
      name: "iTin",
      status: "complete",
      airTableStatus: "// From airtable",
      description:
        'bla\nnotes: from the filer\ncomment: Great progress\ncompanyId: 123123\ninvoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks2"',
      options: [
        {
          key: "speed filing",
          value: true,
        },
      ],
      invoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks24",
      customerData: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      },
      companyData: {
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
        GdriveId: "asdasd",
        LLCStyle: "LLC",
        timezone: "EU/Central",
        companyName: "Foo Bar industries",
        createdAt: "2021",
        creator: {
          fullName: "johnny be good",
          email: "johnny@gmail.com",
          phone: "0123123",
        },
      },
    },
    {
      id: "JUDUaGIhH9I2BWrHNx5",
      name: "taxFiling",
      status: "cancelled",
      airTableStatus: "// From airtable",
      description:
        'bla\nnotes: from the filer\ncomment: Great progress\ncompanyId: 123123\ninvoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks2"',
      options: [
        {
          key: "speed filing",
          value: true,
        },
      ],
      invoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks25",
      customerData: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      },
      companyData: {
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
        GdriveId: "asdasd",
        LLCStyle: "LLC",
        timezone: "EU/Central",
        companyName: "Foo Bar industries",
        createdAt: "2021",
        creator: {
          fullName: "johnny be good",
          email: "johnny@gmail.com",
          phone: "0123123",
        },
      },
    },
    {
      id: "JUDUaGIhH9I2BWrHNx6",
      name: "llcFormation", //'phoneLine | llcFormation | taxFiling | iTin ',
      status: "inprogress", //'waiting | unfiled | inprogress | filed | cancelled | error',
      airTableStatus: "// From airtable",
      description:
        'bla\nnotes: from the filer\ncomment: Great progress\ncompanyId: 123123\ninvoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks2"',
      options: [
        {
          key: "speed filing",
          value: true,
        },
      ],
      invoiceId: "in_1JUDUaGIhH9I2BWrHNx04Ks26",
      customerData: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e1",
      },
      companyData: {
        id: "1xYGVrPcJOdmi3K3J6bypnQOZQM1",
        GdriveId: "asdasd",
        LLCStyle: "LLC",
        timezone: "EU/Central",
        companyName: "Foo Bar industries",
        createdAt: "2021",
        creator: {
          fullName: "johnny be good",
          email: "johnny@gmail.com",
          phone: "0123123",
        },
      },
    },
  ],
  custom_companies: [
    {
      status: "unconfirmed",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "LLC",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      status: "unconfirmed",

      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM1",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e1",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      status: "unconfirmed",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM2",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e3",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      status: "confirmed",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM4",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e4",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      status: "unconfirmed",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM5",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e5",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      status: "unconfirmed",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM10",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      status: "unconfirmed",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM6",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e6",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      status: "unconfirmed",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM7",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e7",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      status: "unconfirmed",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM8",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e8",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
    {
      status: "unconfirmed",
      id: "1xYGVrPcJOdmi3K3J6bypnQOZQM9",
      customer: {
        fullName: "Jimmy Newtron",
        email: "foo@bar.com",
        phone: "+49 123132",
        id: "151ae153-9a65-4aa0-9ba6-7488846c906e9",
      },
      plan: "premium",
      companyState: "Wyoming",
      companyStateAbbreviated: "WY",
      companyName: "Hello Wolrd Industries",
      llcStyle: "foobar",
      companyActivity: "foobar",
      companyActivityOther: "foobar",
      entityType: "foobar",
      companyDescription: "foobar",
      companyURL: "www.google.com",
      applicantSSNorITIN: "",
      companyAddressType: "registeredAgent",
      applicantAddress1: "Main street 12",
      applicantAddress2: "Apartment A",
      applicantCity: "New York",
      applicantState: "NY",
      applicantCountry: "USA",
      applicantPostalCode: "9494",
      applicantPhone: "+49 123132",
      members: [
        {
          fullName: "peter",
          ownership: "95",
          isCompany: false,
        },
      ],
      driveId: "asddasa",
      signedSS4FileId: "23123",
      articlesFileId: "23123",
    },
  ],
};

export default data;
