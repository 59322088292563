export const DoolaLogo = () => {
  return (
    <svg
      style={{ height: "inherit", width: "auto" }}
      width="450"
      height="127"
      viewBox="0 0 450 127"
      fill="none"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M302.399 52.9131C324.59 52.9131 339.066 67.6353 339.066 89.4912C339.066 111.347 324.175 127.021 301.735 127.021C279.814 127.021 264.778 112.029 264.778 90.0288C264.778 68.0282 279.917 52.9131 302.399 52.9131ZM301.694 118.481C310.383 118.481 316.211 107.088 316.211 90.0288C316.211 72.9701 310.383 61.4942 301.839 61.4942C293.294 61.4942 287.632 72.908 287.632 90.2149C287.632 107.522 293.294 118.481 301.694 118.481Z"
          fill="#1E1E1E"
        />
        <path
          d="M220.728 52.9131C242.919 52.9131 257.415 67.6353 257.415 89.4912C257.415 111.347 242.525 127.021 220.085 127.021C198.163 127.021 183.127 112.029 183.127 90.0288C183.127 68.0282 198.246 52.9131 220.728 52.9131ZM220.043 118.481C228.733 118.481 234.561 107.088 234.561 90.0288C234.561 72.9701 228.733 61.4942 220.168 61.4942C211.602 61.4942 205.982 72.908 205.982 90.2149C205.982 107.522 211.602 118.481 220.043 118.481Z"
          fill="#1E1E1E"
        />
        <path
          d="M175.744 117.799C173.214 117.633 172.094 116.455 171.99 113.663L178.108 13.9365C178.348 11.179 177.694 8.41742 176.242 6.05845C174.862 4.17202 173.051 2.64043 170.959 1.59048C168.867 0.540525 166.555 0.00241622 164.213 0.0206839C153.366 -0.682344 147.622 5.78965 147.477 9.3875C147.859 8.82336 148.375 8.36208 148.979 8.04465C149.584 7.72723 150.257 7.56347 150.94 7.56789C153.843 7.77466 154.901 9.2841 154.652 13.0267L152.039 55.1257C147.545 53.5741 142.817 52.8048 138.061 52.8512C115.58 52.8512 100.398 67.7182 100.398 90.0703C100.398 112.423 115.434 127.062 137.356 127.062C143.382 127.136 149.349 125.864 154.818 123.34C157.179 124.598 159.796 125.305 162.471 125.408C173.338 126.09 179.062 119.639 179.208 116.02C178.82 116.577 178.302 117.03 177.698 117.34C177.094 117.65 176.423 117.808 175.744 117.799ZM137.356 118.502C128.936 118.502 123.295 107.357 123.295 90.2978C123.295 73.239 129.081 61.5563 137.501 61.5563C143.723 61.5563 148.555 67.5734 150.67 77.6226C151.482 81.7076 151.871 85.8648 151.832 90.029C151.993 96.3696 150.952 102.683 148.762 108.639C146.191 114.904 142.229 118.502 137.356 118.502Z"
          fill="#1E1E1E"
        />
        <path
          d="M442.969 79.7319V114.098C442.969 117.675 444.068 119.039 446.827 119.039C447.475 119.009 448.105 118.817 448.659 118.481C449.214 118.145 449.675 117.676 450 117.116C450 120.549 445.043 127.021 434.694 127.021C427.249 127.021 422.023 123.568 420.509 116.558C418.289 119.868 415.265 122.563 411.718 124.393C408.172 126.223 404.218 127.127 400.226 127.021C387.554 127.021 377.765 118.75 377.765 106.344C377.765 81.0552 418.165 83.8053 419.119 72.3915C418.292 69.0359 416.392 66.0402 413.705 63.8577C411.018 61.6752 407.692 60.4246 404.228 60.2952C402.825 60.1891 401.423 60.5293 400.226 61.2671C401.469 62.2843 402.472 63.5619 403.164 65.0093C403.855 66.4566 404.219 68.0381 404.228 69.6414C404.157 72.5204 402.941 75.2533 400.849 77.2387C398.756 79.2241 395.958 80.2994 393.071 80.2281C390.183 80.1568 387.442 78.9448 385.451 76.8585C383.459 74.7723 382.381 71.9828 382.452 69.1037C382.452 59.6129 392.822 52.8721 410.16 52.8721C425.611 52.9134 442.969 58.2689 442.969 79.7319ZM420.156 82.606C413.25 87.5686 400.703 89.9051 400.703 101.712C400.703 108.597 404.975 112.857 409.392 112.857C414.349 112.857 419.327 107.502 420.156 99.3753V82.606Z"
          fill="#1E1E1E"
        />
        <path
          d="M371.813 119.391C369.304 119.246 368.163 118.067 368.08 115.255L374.177 15.5491C374.42 12.7821 373.758 10.0111 372.29 7.6504C369.864 3.96984 365.695 1.96412 360.282 1.6126C349.435 0.930251 343.691 7.3816 343.545 10.9795C343.93 10.4191 344.447 9.9619 345.051 9.64815C345.656 9.33441 346.328 9.1738 347.009 9.1805C349.912 9.3666 350.97 10.876 350.742 14.6393L344.645 112.981C344.402 115.748 345.064 118.519 346.532 120.879C347.904 122.769 349.71 124.304 351.799 125.354C353.889 126.405 356.2 126.941 358.54 126.917C369.407 127.599 375.131 121.148 375.276 117.55C374.896 118.118 374.381 118.584 373.777 118.905C373.173 119.226 372.498 119.393 371.813 119.391Z"
          fill="#1E1E1E"
        />
        <path
          d="M66.2618 119.391C63.7731 119.226 62.8399 118.068 63.1924 115.255L85.9019 15.4875C86.6077 12.8647 86.4031 10.0809 85.3212 7.58873C83.4961 3.90817 79.6594 1.88179 74.3087 1.55096C63.5657 0.847929 56.8462 7.31992 56.0166 10.9178C56.4672 10.3567 57.0384 9.90375 57.6882 9.59238C58.3379 9.281 59.0495 9.11918 59.7704 9.11886C62.6532 9.30495 63.462 10.8144 62.591 14.5777L40.1511 112.981C39.4453 115.604 39.6499 118.388 40.7318 120.88C42.5361 124.56 46.3729 126.587 51.7236 126.917C62.4873 127.6 69.2068 121.149 70.0364 117.53C69.5899 118.105 69.018 118.572 68.3641 118.894C67.7102 119.216 66.9913 119.386 66.2618 119.391Z"
          fill="#F3B62F"
        />
        <path
          d="M26.5257 119.391C24.037 119.225 23.1038 118.067 23.4563 115.255L46.1451 15.4872C46.8663 12.9244 46.7057 10.1945 45.6888 7.73326C43.8637 4.0527 40.027 2.02633 34.6763 1.69549C23.9333 0.99246 17.2138 7.46445 16.3842 11.0623C16.8338 10.5001 17.4048 10.0463 18.0548 9.73486C18.7048 9.42337 19.4169 9.26222 20.138 9.26339C23 9.44949 23.8089 10.9589 22.9586 14.7222L0.518728 113.125C-0.20364 115.745 -0.00605233 118.532 1.0787 121.024C2.90375 124.705 6.7405 126.731 12.0912 127.062C22.8342 127.744 29.5536 121.293 30.3832 117.675C29.9146 118.235 29.3232 118.682 28.6545 118.979C27.9857 119.277 27.2575 119.418 26.5257 119.391Z"
          fill="#F3B62F"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="450" height="127" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
