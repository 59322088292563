import React from "react";
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

import { zodResolver } from "@hookform/resolvers/zod";
import { companyBaseSchema } from "../../validation/companyValidation";

const CompanyEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm
      style={{ maxWidth: "800px" }}
      validate={async (values: any) => {
        // console.log(values);
        const validation = await zodResolver(companyBaseSchema)(
          values,
          undefined,
          {} as any
        );
        return validation.errors;
      }}
    >
      {/* <TextInput source="id" style={{ width: "100%" }} /> */}
      <TextInput
        source="customer.fullName"
        label="Full Name"
        style={{ width: "100%" }}
      />
      <TextInput source="plan" style={{ width: "100%" }} />
      <TextInput source="companyState" style={{ width: "100%" }} />
      <TextInput source="companyStateAbbreviated" style={{ width: "100%" }} />
      <TextInput source="companyDetails.name" style={{ width: "100%" }} />
      <TextInput source="llcStyle" style={{ width: "100%" }} />
      <TextInput source="companyActivity" style={{ width: "100%" }} />
      <TextInput source="companyActivityOther" style={{ width: "100%" }} />
      <TextInput source="entityType" style={{ width: "100%" }} />
      <TextInput source="companyDescription" style={{ width: "100%" }} />
      <TextInput source="companyURL" style={{ width: "100%" }} />
      <DateInput source="applicantSSNorITIN" style={{ width: "100%" }} />
      <TextInput source="companyAddressType" style={{ width: "100%" }} />
      <TextInput source="applicantAddress1" style={{ width: "100%" }} />
      <TextInput source="applicantAddress2" style={{ width: "100%" }} />
      <TextInput source="applicantCity" style={{ width: "100%" }} />
      <TextInput source="applicantState" style={{ width: "100%" }} />
      <TextInput source="applicantCountry" style={{ width: "100%" }} />
      <DateInput source="applicantPostalCode" style={{ width: "100%" }} />
      <TextInput source="applicantPhone" style={{ width: "100%" }} />
      <ArrayInput source="members" style={{ width: "100%" }}>
        <SimpleFormIterator>
          <TextInput source="fullName" style={{ width: "100%" }} />
          <DateInput source="ownership" style={{ width: "100%" }} />
          <BooleanInput source="isCompany" style={{ width: "100%" }} />
        </SimpleFormIterator>
      </ArrayInput>
      <ReferenceInput
        source="driveId"
        reference="drives"
        style={{ width: "100%" }}
      >
        <SelectInput optionText="id" style={{ width: "100%" }} />
      </ReferenceInput>
      <DateInput source="signedSS4FileId" style={{ width: "100%" }} />
      <DateInput source="articlesFileId" style={{ width: "100%" }} />
    </SimpleForm>
  </Edit>
);

export default CompanyEdit;
