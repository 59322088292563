import { FooterEmail } from "./Footer";

type Props = {
  fullName: string;
};

export const EinUpdateEmail = ({ fullName }: Props) => {
  return (
    <>
      Hi {fullName},
      <br />
      <br />
      We have tried to be upfront and honest about all potential delays, and
      factors that are not in our control, like the IRS.
      <br />
      <br />
      I understand the immense frustration at the 2-3 month waiting time. This
      is the best, most honest information that I can give you ⬇️
      <br />
      <br />
      Below is a breakdown of what one example of the time-table is for
      processing an EIN for a company we have worked with:
      <ul>
        <li>EIN Request Sent: 10/10/2020</li>
        <li>EIN Request Processed by IRS: 10/13/2020 (1 Business Day Later)</li>
        <li>
          EIN Number Given: 12/21/2020 (45 Business Days Later, there are an
          average of 20 Business Days per month, so 2.5 months give or take)
        </li>
        <li>Confirmation sent back to us: 1/4/2020 (10 Business Days Later)</li>
      </ul>
      <br />
      As you can see, there is a total of about 55 Business Days from request to
      receiving an EIN. This is just one company as an example. The timeline
      will most likely NOT be the exact same for you. The vast majority of that
      time is a delay from the IRS.  We have recently heard from the IRS that
      their delay could change from 2-3 months into 2-4 months.
      <br />
      <br />
      I know it is very frustrating, but for foreign founders without SSN's this
      is harsh truth, no matter what company or how you apply for your SSN.
      <br />
      <br />
      We completely understand if you're skeptical about these delays. Even we
      at doola are very angry that the IRS keeps moving the goalposts. Here is
      the IRS Contact Information if you wish to follow up on your own:
      <ul>
        <li>Call the Business & Specialty Tax Line at 800-829-4933</li>
        <li>
          The hours of operation are 7:00 a.m. - 7:00 p.m. local
          time, Monday through Friday.
        </li>
        <li>
          Follow the instructions to discuss EIN's (Have your SS4 copy in your
          GDrive folder readily available)
        </li>
      </ul>
      <br />
      <br />
      I wish we had more information to offer, but this is all we know as well.
      <br />
      <FooterEmail />
    </>
  );
};
