// Generated by ts-to-zod
import { z } from "zod";
import { CustomerStatus } from "../models/Customer";

export const CustomerRequest = z.object({
  fullName: z.string(),
  email: z.string(),
  phone: z.string().optional(),
  id: z.string().optional(),
  referredBy: z.string().optional(),
  timezone: z.string().optional(),
});

export const customerStatusSchema = z.nativeEnum(CustomerStatus);

export const customerBaseSchema = z.object({
  status: customerStatusSchema,
  fullName: z.string(),
  email: z.string(),
  phone: z.string().optional(),
  companyIds: z.array(z.string()),
  referredBy: z.string().optional(),
  referrals: z.array(z.string()).optional(),
  cognitoId: z.string().optional(),
  stripeId: z.string().optional(),
  customerIoId: z.string().optional(),
  lastCustomerIoSync: z.string().optional(),
  timezone: z.string().optional(),
});
