import {
  TextField,
  EmailField,
  DateField,
  EditButton,
  ShowButton,
} from "react-admin";
import { Box, Typography, Divider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

export const CustomerAside = ({ record, link = "edit" }: any) => {
  return (
    <Box ml={4} width={250} minWidth={250}>
      <Box textAlign="center" mb={2}>
        {link === "edit" ? (
          <EditButton
            basePath="/customers"
            record={record}
            label="Edit Customer"
          />
        ) : (
          <ShowButton
            basePath="/customers"
            record={record}
            label="Show Customer"
          />
        )}
      </Box>
      <Typography variant="subtitle2">Personal info</Typography>
      <Divider />
      <Box mt={2}>
        <EmailField source="email" /> <EditIcon />
      </Box>
      <Box mt={1}>
        <Typography variant="body2" color="textSecondary" component="span">
          Phone
        </Typography>
        <br />
        <TextField source="phone" />{" "}
      </Box>

      <Box mb={1}>
        <Typography variant="body2" color="textSecondary" component="span">
          Created at
        </Typography>
        <br />
        <DateField source="createdAt" />{" "}
      </Box>
    </Box>
  );
};
