import { API } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";

export class ApiClient {
  endpoint: string;
  constructor(endpoint?: string) {
    this.endpoint = endpoint || "api";
  }

  private getAuthHeader = async () => {
    try {
      return {
        Authorization: `${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      };
    } catch (error) {
      console.error("JWT");
      console.error(error);
      return {};
    }
  };

  public async get(path: string, params?: Record<string, unknown>) {
    const auth = await this.getAuthHeader();

    const init = {
      response: true,
      ...(params && { queryStringParameters: params }),
      headers: {
        "Content-Type": "application/json",
        ...auth,
      },
    };

    return await API.get(this.endpoint, path, init);
  }
  public async delete(path: string, params: Record<string, unknown>) {
    const auth = await this.getAuthHeader();

    const init = {
      response: true,
      queryStringParameters: params,
      headers: {
        "Content-Type": "application/json",
        ...auth,
      },
    };

    return await API.del(this.endpoint, path, init);
  }

  public async post(path: string, body?: Record<string, unknown>) {
    const auth = await this.getAuthHeader();

    const init = {
      response: true,
      headers: {
        "Content-Type": "application/json",
        ...auth,
      },
      ...(body && { body }),
    };

    return await API.post(this.endpoint, path, init);
  }
  public async patch(path: string, body?: Record<string, unknown>) {
    const auth = await this.getAuthHeader();
    const init = {
      response: true,
      headers: {
        "Content-Type": "application/json",
        ...auth,
      },
      ...(body && { body }),
    };

    return await API.patch(this.endpoint, path, init);
  }
  public async put(path: string, body?: Record<string, unknown>) {
    const auth = await this.getAuthHeader();

    const init = {
      response: true,
      headers: {
        "Content-Type": "application/json",
        ...auth,
      },
      ...(body && { body }),
    };

    return await API.put(this.endpoint, path, init);
  }
}
