import { AirtableEmail, EmailTypes } from "../services/airtable_emails";
import { PersistedCompany } from "../models/Company";
import { Button } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { EmailIconTicket, EmailIcon } from "./Icons";
import { useState } from "react";
import SimpleDialog from "./DialogEmail";

type CustomEmailsProps = {
  company: PersistedCompany;
  type: EmailTypes;
  label: string;
  emailSentCounter: number;
};

const initDialog = {
  open: false,
  emailService: undefined,
};

export const CustomEmailLink = ({
  label,
  type,
  company,
  emailSentCounter,
}: CustomEmailsProps) => {
  const [dialog, setDialog] = useState<{
    open: boolean;
    emailService: undefined | AirtableEmail;
  }>(initDialog);

  const handleClick = () => {
    const emailService = new AirtableEmail(company, type);
    setDialog({ open: true, emailService: emailService });
  };

  const getEmailIcon = () => {
    const wasSent = emailSentCounter > 0;

    return wasSent ? (
      <Badge badgeContent={emailSentCounter} color="error">
        <EmailIconTicket />
      </Badge>
    ) : (
      <EmailIcon />
    );
  };

  return (
    <>
      <Button
        key={label}
        onClick={handleClick}
        variant="outlined"
        size="small"
        color={"secondary"}
        style={{ marginRight: "10px", fontSize: "9px", padding: "5px" }}
      >
        {label}&nbsp;
        {getEmailIcon()}
      </Button>
      {dialog.open && (
        <SimpleDialog
          emailService={dialog.emailService as AirtableEmail}
          closeDialog={() => setDialog(initDialog)}
        />
      )}
    </>
  );
};

export default CustomEmailLink;
