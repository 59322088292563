import config from "../../config";
import { FooterEmail } from "./Footer";

const dashboardUrl = config.customerApp.URL + "/dashboard";

type Props = {
  firstName: string;
  state: string;
};

export const AnnualReportCompleteEmail = ({ firstName, state }: Props) => {
  return (
    <>
      <p>Hi {firstName},</p>
      <p>
        Good news, your Annual Report is complete and is officially submitted
        with {state}! You will be able to access a receipt of your Annual Report
        submission in your{" "}
        <a href={dashboardUrl} rel="noopener noreferrer" target="_blank">
          dashboard
        </a>{" "}
        :)
      </p>
      <p>
        Once again congratulations, and as always, don't hesitate to reach out
        with any questions.
      </p>
      <FooterEmail />
    </>
  );
};
