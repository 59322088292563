import { DBBaseItem } from "./Common";
import { CompanyService } from "./ServiceModel/ServiceModel";

export enum CompanyStatus {
  unsubmitted = "unsubmitted", // In sign up flow, haven't paid
  verified = "verified", // Sign up flow complete, has paid
  requiresAction = "requiresAction",
  blocked = "blocked",
  canceled = "canceled",
}

// Aggregates services states into one company state
export enum AggregatedState {
  unformed = "unformed", // In sign up flow, haven't paid
  formed = "formed",
  einInProgress = "einInProgress", // Company is formed but hasn't yet received EIN
  einReceived = "einReceived",
}

export interface InternalDetails {
  comment?: string;
  migrationData?: MigrationData;
}

export interface MigrationData {
  // We temporarily store this until we are fully off Airtable
  // This helps with the migration, ensuring it's idempotent and validation
  submissionId?: string;
  pipelineId?: string;
}

export interface Customer {
  fullName: string;
  id: string;
  phone?: string;
  email: string;
  timezone?: string;
  companyIds?: string[];
}

export interface MailingAddress {
  linesFormatted?: string;
  cityStateZipCodeFormatted?: string;
  countyAndStateFormatted?: string;
  country?: string;
}

export enum AddressType {
  registeredAgent = "registeredAgent",
  applicantAddress = "applicantAddress",
}

export interface CompanyDetails {
  activity?: string;
  activityOther?: string;
  description: string;
  geographicState: string;
  businessType: BusinessType;
  name: string;
  addressType: AddressType;
  mailingAddress?: MailingAddress;
  url: string;
  SSN?: string;
  ITIN?: string;
  EIN?: string;
  registeredAgentId?: string;
  verizonNumber?: string;
  owners: Owner[];
}

export interface Owner {
  ownership: string;
  fullName: string;
  isCompany: boolean;
}

export enum BusinessTypeNames {
  LLC = "LLC",
  DAO = "DAO",
  CCORP = "CCorp",
  INC = "Inc",
  SERIES_LLC = "Series LLC",
}

type BusinessTypeStylesLLC = "LLC" | "L.L.C" | "Limited Liability Company";
type BusinessTypeStylesSeriesLLC =
  | "Series LLC"
  | "Series Limited Liability Company";

type BusinessTypeStylesDAO =
  | "DAO"
  | "DAO LLC"
  | "D.A.O L.L.C"
  | "Decentralized Autonomous Organization Limited Liability Company";

type BusinessTypeStylesCCORP = "CCorp" | "C Corp" | "C Corporation" | "Inc";

export type BusinessTypeStyles =
  | BusinessTypeStylesLLC
  | BusinessTypeStylesDAO
  | BusinessTypeStylesCCORP
  | BusinessTypeStylesSeriesLLC;

export interface BusinessType {
  name: keyof typeof BusinessTypeNames;
  style: BusinessTypeStyles;
}

export interface Documents {
  googleDriveFolderId?: string;
  signedSS4FileId?: string;
  articlesFileId?: string;
  rightSignatureDocId?: string;
}

export interface ApplicantAddress {
  phone: string;
  state: string;
  address2: string;
  address1: string;
  postalCode: string;
  country: string;
  city: string;
}

export interface RenewalDetails {
  renewalDate?: string;
  charged?: boolean;
  emailed?: boolean;
}

export interface CompanyBase {
  customerId?: string; //legacy remove
  applicantAddress: ApplicantAddress;
  documents: Documents;
  companyDetails: CompanyDetails;
  status: CompanyStatus;
  cancellationReason?: string;
  renewalDetails?: RenewalDetails;
  aggregatedState: AggregatedState;
  services: CompanyService[];
  customer: Customer;
  internalDetails: InternalDetails;
  annualReport?: AnnualReport;
  invoiceDetails?: InvoiceDetails;
  vendorPayments?: [{
    [key: string]: string
  }]
}

export interface InvoiceDetails {
  lastInvoiceStatus: string;
  updatedAt: string;
}
export interface AnnualReport {
  upcomingReport: string;
  charged?: boolean;
  emailed?: boolean;
}

export type PersistedCompany = CompanyBase & DBBaseItem;
