import React from "react";
import { AirtableEmail } from "../services/airtable_emails";
import DialogActions from "@material-ui/core/DialogActions";
import { Box } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import { renderToString } from "react-dom/server";
import TextField from "@material-ui/core/TextField";
import { useMutation, useRefresh } from "ra-core";

export interface SimpleDialogProps {
  emailService: AirtableEmail;
  closeDialog: () => void;
}

const SimpleDialog = ({ emailService, closeDialog }: SimpleDialogProps) => {
  const { subject, htmlJSX } = emailService.emailData();
  const refresh = useRefresh();
  const [sendMail] = useMutation();
  const handleSend = () => {
    const payload = emailService._createEmailPayload(
      subject,
      renderToString(htmlJSX)
    );
    sendMail(
      {
        type: "customPost",
        resource: "notifications/email",
        payload: payload,
      },
      {
        onSuccess: () => {
          refresh();
        },
      }
    );
    closeDialog();
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={true}>
      <DialogContent>
        <Box mb={1} mt={1}>
          <TextField
            disabled
            fullWidth
            label="Subject"
            defaultValue={subject}
            variant="outlined"
            size="small"
          />
        </Box>
        <Box>
          <TextField
            disabled
            fullWidth
            label="emailTo"
            defaultValue={emailService.emailTo}
            variant="outlined"
            size="small"
          />
        </Box>
        <DialogContentText>
          <Box p={1}>
            <Typography variant="body2" style={{ fontSize: "12px" }}>
              {htmlJSX}
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeDialog}
          color="secondary"
          variant="outlined"
          size={"small"}
        >
          Back
        </Button>
        <Button
          onClick={handleSend}
          color="primary"
          variant="contained"
          size={"small"}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
