import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Edit,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useNotify,
  useRecordContext,
} from "react-admin";
import { Divider, Typography } from "@material-ui/core";
import industryOptions from "./resources/industries";
import countries from "./resources/countries";
import stateKeys from "../../formatters/stateFormatter";
import { StyledAlert, useCompanyEditStyles } from "./styles";
import {
  BusinessTypeNames,
  CompanyStatus,
} from "../../models/Company";
import { useCallback, useState } from "react";
import { formatCompanyStatus } from "../../formatters/companyFormatter";
import { CHURN_REASONS } from "./resources/churnReasons";
import { EditServices } from "./EditServices";
import { allBusinessTypes, stylesOptions } from ".";
import { BusinessTypeOption } from "./CompanyCreate";

const cancellationReasons = CHURN_REASONS.map((reason) => ({
  id: reason,
  name: reason,
}));

const CompanyEdit = (props: any) => {
  const classes = useCompanyEditStyles();
  const [ defaultLlcOption ] = stylesOptions(BusinessTypeNames.LLC)
  const [entityStyleSelected, setEntityStyleSelected] = useState<BusinessTypeOption>(defaultLlcOption);
  const businessTypes = allBusinessTypes();
  const handlerBusinessSelection = useCallback((selection) => {
    const value = selection.target.value;
    const selectedOption = value === '-' ? {id: '', name: '-'} : stylesOptions(value as BusinessTypeNames)[1]
    setEntityStyleSelected(selectedOption as BusinessTypeOption);
  }, [])
  const notify = useNotify();
  return (
    <Edit
      className={classes.wrapperEditCompany}
      {...props}
      mutationMode="optimistic"
      onFailure={(response) => {
        console.log("failure");
        console.log(response);
        notify(
          "Failed because of: " +
            (response?.response?.data?.message ||
              JSON.stringify(response?.response?.data)),
          {
            type: "warning",
          }
        );
      }}
    >
      <SimpleForm
        validateOnBlur={false}
        redirect="show"
        className={classes.container}
        // validateOnBlur={false}
        // onSubmit={(data: any) => {
        //   console.log(data, "data");
        //   return data;
        // }}
        // validate={async (values: any) => {
        //   const validation = await zodResolver(companyEditBaseSchema)(
        //     values,
        //     undefined,
        //     {} as any
        //   );
        //   if (Object.values(validation.errors).length > 0) {
        //     debugger;
        //     console.error("errors", validation.errors);
        //   }
        //   return validation.errors;
        // }}
      >
        <TextInput
          source="companyDetails.name"
          label="Company Name"
          variant="outlined"
        />
        <SelectInput
          variant="outlined"
          label="Entity Type"
          source="companyDetails.businessType.name"
          value={entityStyleSelected}
          onChange={handlerBusinessSelection}
          choices={[
            ...businessTypes,
            { id: "-", name: "-" },
          ]}
        />
        <BusinessStylesDropDown entityTypeSelected={entityStyleSelected?.id} />
        <TextInput
          variant="outlined"
          label="ITIN"
          source="companyDetails.ITIN"
        />
        <TextInput variant="outlined" label="EIN" source="companyDetails.EIN" />
        <TextInput variant="outlined" label="SSN" source="companyDetails.SSN" />
        <TextInput
          variant="outlined"
          label="Website"
          source="companyDetails.url"
        />
        <Typography> Company Services</Typography>
        <EditServices {...props} isEdditing />
        <Typography> Company Details</Typography>
        <Divider />
        <SelectInput
          variant="outlined"
          label="Formation State"
          source="companyDetails.geographicState"
          choices={[
            { id: "", name: "-" },
            ...Object.entries(stateKeys).map((values) => ({
              id: values[0],
              name: values[1],
            })),
          ]}
        />
        <TextInput
          variant="outlined"
          label="Description"
          source="companyDetails.description"
        />
        <SelectInput
          label="Activity"
          variant="outlined"
          source="companyDetails.activity"
          emptyValue={"-"}
          choices={[
            { value: "", name: "-" },
            ...industryOptions
              .sort((a, b) => a.position - a.position)
              .map((item) => ({ id: item.label, name: item.label })),
          ]}
        />
        <TextInput
          variant="outlined"
          label="Activity (Other)"
          source="companyDetails.activityOther"
        />
        <SelectInput
          variant="outlined"
          label="Address Type"
          source="companyDetails.addressType"
          emptyValue={"-"}
          choices={[
            {
              id: "registeredAgent",
              name: "Our US Registered Agent Address",
            },
            {
              id: "applicantAddress",
              name: "Customers mailing address (the one you enter below)",
            },
          ]}
        />
        <Typography>Applicant Address</Typography>
        <Divider />
        <TextInput
          variant="outlined"
          label="Phone"
          source="applicantAddress.phone"
        />
        <TextInput
          label="Address Line 1"
          source="applicantAddress.address1"
          initialValue=""
          variant="outlined"
        />
        <TextInput
          variant="outlined"
          label="Address Line 2"
          source="applicantAddress.address2"
          initialValue=""
        />
        <TextInput
          variant="outlined"
          label="City"
          source="applicantAddress.city"
        />
        <TextInput
          variant="outlined"
          label="State"
          source="applicantAddress.state"
        />
        <TextInput
          variant="outlined"
          label="Postal code"
          source="applicantAddress.postalCode"
        />
        <SelectInput
          variant="outlined"
          label="Country"
          choices={[
            { value: "", name: "-" },
            ...countries
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((country) => ({
                id: country.label,
                name: country.label,
              })),
          ]}
          source="applicantAddress.country"
        />
        <Typography>Mailing Address</Typography>
        <Divider />
        <TextInput
          variant="outlined"
          label="Address Lines"
          source="companyDetails.mailingAddress.linesFormatted"
        />
        <TextInput
          variant="outlined"
          label="City, State, Postal Code"
          source="companyDetails.mailingAddress.cityStateZipCodeFormatted"
        />
        <TextInput
          variant="outlined"
          label="County, State"
          source="companyDetails.mailingAddress.countyAndStateFormatted"
        />
        <SelectInput
          emptyValue={"United States"}
          label="Country"
          choices={[
            { value: "", name: "-" },
            ...countries
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((country) => ({
                id: country.label,
                name: country.label,
              })),
          ]}
          source="companyDetails.mailingAddress.country"
        />
        <Divider />
        <ArrayInput
          source="companyDetails.owners"
          label="Members"
          style={{ width: "100%" }}
        >
          <SimpleFormIterator
            defaultValue={{ ownership: "", fullName: "", isCompany: false }}
            // withDefaultValue={{ ownership: "", fullName: "", isCompany: false }}
          >
            <TextInput variant="outlined" source="fullName" label="Full name" />
            <TextInput
              source="ownership"
              variant="outlined"
              style={{ width: "100%" }}
              label="Ownership %"
            />
            <BooleanInput
              source="isCompany"
              style={{ width: "100%" }}
              initialValue={false}
              label="Is company"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          variant="outlined"
          label="Status"
          choices={Object.values(CompanyStatus).map((item) => ({
            id: item,
            name: formatCompanyStatus(item),
          }))}
          source="status"
        />
        <StyledAlert severity="info" style={{ width: "auto" }}>
          Use this field to select a churn reason or create a new one by
          clicking `Create (your text)`
        </StyledAlert>
        <AutocompleteInput
          source="cancellationReason"
          choices={cancellationReasons}
          onCreate={(value: any) => {
            const newReason = { id: value, name: value };
            cancellationReasons.push(newReason);
            return newReason;
          }}
        />
        <TextInput
          variant="outlined"
          label="Google Drive Id"
          source="documents.googleDriveFolderId"
        />
        <TextInput
          variant="outlined"
          label="Articles File Id"
          source="documents.articlesFileId"
        />
        <TextInput
          variant="outlined"
          label="Signed SS4 File Id"
          source="documents.signedSS4FileId"
        />
        <DateInput
          variant="outlined"
          label="Renewals Date"
          source="renewalDetails.renewalDate"
        />
        <BooleanInput
          label="Renewals charged"
          source="renewalDetails.charged"
        />
        <BooleanInput
          label="Renewals emailed"
          source="renewalDetails.emailed"
        />
        <DateInput variant="outlined" source="createdAt" label="created at" />
        <Typography>Annual Report</Typography>
        <Divider />
        <DateInput
          variant="outlined"
          label="Upcoming Reportt"
          source="annualReport.upcomingReport"
        />
        <BooleanInput label="Charged" source="annualReport.charged" />
        <BooleanInput label="Emailed" source="annualReport.emailed" />
      </SimpleForm>
    </Edit>
  );
};

export const BusinessStylesDropDown = ({
  entityTypeSelected,
}: {
  entityTypeSelected: string;
}) => {
  const record = useRecordContext();
  const { companyDetails } = record;
  const { businessType } = companyDetails;
  const { name } = businessType;

  const typeToConsider = entityTypeSelected ? entityTypeSelected : name;

  return (
    <SelectInput
      variant="outlined"
      label="Name Style"
      source="companyDetails.businessType.style"
      emptyValue={""}
      choices={stylesOptions(typeToConsider)}
    />
  );
};

export default CompanyEdit;
