import { FooterEmail } from "./Footer";

type Props = {
  fullName: string;
  companyName: string;
};

export const SS4SentToIRSEmail = ({ fullName, companyName }: Props) => {
  return (
    <>
      <p>Hi {fullName},</p>
      <p>
        This message is to confirm that your EIN application for {companyName}{" "}
        is under review with the IRS (USA Internal Revenue Service).
      </p>
      <p>
        The IRS' current processing time is 8-11 weeks to receive your EIN and a 
        confirmation letter. Please keep in mind the IRS is a US federal government 
        agency, and doola has no control over this processing time. The timeline above 
        is the average processing time based on what we are currently seeing.
      </p>
      <p>
        However, doola does offer an <strong>EIN Expediting Service</strong> in approximately 
        3-6 weeks. This is a premium white glove service in which an IRS-certified agent directly
        works with the IRS on your case. Please reply to this email if you are interested 
        in this EIN Expediting service for an additional fee.
      </p>
      <p>In the meantime, don't hesitate to reach out with any questions.</p>
      <FooterEmail />
    </>
  );
};
