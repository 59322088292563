import algoliasearch, { SearchClient, SearchIndex } from "algoliasearch/lite";

// [
//   "*",
//   "name",
//   "serviceState",
//   "status",
//   "companyStateAbbreviated",
// ]

export class SearchApi {
  client: SearchClient;
  index: SearchIndex;
  facets: string[];
  constructor(
    params: {
      index: string;
      facets?: string[];
      appId?: string;
      apiKey?: string;
    } = { index: "companies" }
  ) {
    this.client = algoliasearch(params?.appId || "", params?.apiKey || "");
    this.index = this.client.initIndex(params.index);
    this.facets = params.facets || [];
  }

  async getFilterCount(
    query = "",
    facetFilters: string[] = [],
    facetKeys = ["*"],
    numericFilters = []
  ) {
    return await this.index.search("", {
      attributesToRetrieve: [""],
      attributesToHighlight: [""],
      hitsPerPage: 0,
      facets: facetKeys,
      facetFilters: facetFilters,
      numericFilters: numericFilters,
    });
  }

  async search(
    query: string,
    page: number = 0,
    facetFilters: string[] = [],
    facetKeys = ["*"],
    numericFilters = []
  ) {
    return await this.index.search(query, {
      page,
      facets: facetKeys,
      facetFilters: facetFilters,
      numericFilters: numericFilters,
      //   getRankingInfo: true,
      //   analytics: false,
      //   enableABTest: false,
      //   hitsPerPage: 10,
      //   attributesToRetrieve: ["*"],
      //   attributesToSnippet: ["*:20"],
      //   snippetEllipsisText: "…",
      //   responseFields: ["*"],
      //   explain: ["*"],
      //   maxValuesPerFacet: 100,
    });
  }

  static queryBuilder(params: any) {
    let isSearch = false;
    let searchQuery: string = "";
    const facetKeys: string[] = ["*"];
    const facetQuery: string[][] = [];
    const facetParams = Object.keys(params).filter((key) => {
      const existingKey = (typeof key === "string" && key) || "";
      return (
        existingKey.startsWith("facet:") || existingKey.startsWith("global:")
      );
    });
    for (const facetParam of facetParams) {
      const cleanClean = facetParam
        .replace("facet:", "")
        .replace("global:", "");
      const facetWord = params[facetParam as any];
      if (facetWord && cleanClean === "status" && facetWord === "all") {
        isSearch = true;
      } else {
        if (facetWord) {
          facetQuery.push([cleanClean + ":" + facetWord]);
        }
        facetKeys.push(cleanClean);
      }
    }

    const searchParams = Object.keys(params).filter((key) =>
      ((typeof key === "string" && key) || "").startsWith("search:")
    );

    for (const searchParamKey of searchParams) {
      const queryWord = params[searchParamKey as any];
      if (queryWord) {
        searchQuery += " " + queryWord;
      }
    }

    const numericParams = Object.keys(params).filter((key) =>
      ((typeof key === "string" && key) || "").startsWith("numeric:")
    );

    const numericFilters: string[] = [];
    for (const searchParamKey of numericParams) {
      const queryWord = params[searchParamKey as any];
      if (queryWord) {
        numericFilters.push(searchParamKey.replace("numeric:", "") + queryWord);
      }
    }

    if (searchQuery || facetQuery.length > 1 || numericFilters.length) {
      isSearch = true;
    } else if (facetQuery.length === 1) {
      if (facetQuery[0].length && facetQuery[0][0].length) {
        isSearch = !facetQuery[0][0].includes("status:");
      } else {
        isSearch = true;
      }
    }

    return [
      isSearch,
      searchQuery as string,
      facetQuery,
      facetKeys,
      numericFilters,
    ];
  }
}
