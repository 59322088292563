import {
  TextField,
  EditButton,
  ShowButton,
  ArrayField,
  BooleanField,
  Datagrid,
} from "react-admin";
import { Box, Typography, Divider } from "@material-ui/core";
import { PersistedCompany } from "../../models/Company";

export const CompanyAside = ({
  record,
  link = "edit",
}: {
  record: PersistedCompany;
  link: string;
}) => (
  <Box ml={4} width={450} minWidth={450}>
    <Box textAlign="right" mb={2}>
      {link === "edit" ? (
        <EditButton
          basePath="/companies"
          record={record}
          label="Edit Company"
        />
      ) : (
        <ShowButton
          basePath="/companies"
          record={record}
          label="Show Company"
        />
      )}
    </Box>
    <Typography variant="subtitle2">
      Company: {record.companyDetails.name}
    </Typography>
    <Divider />
    <Box mt={2}></Box>

    <Box mt={1}>
      <Typography variant="body2" color="textSecondary" component="span">
        State: <b>{record.companyDetails.geographicState || "-"}</b>
        <br />
        Plan: ?
        <br />
        Business type: {record.companyDetails.businessType.style || "-"}
        <br />
        Activity: {record.companyDetails.activity || "-"}
        <br />
        Activity other: {record.companyDetails.activityOther || "-"}
        <br />
        Description: {record.companyDetails.description || "-"}
        <br />
        Website: {record.companyDetails.url || "-"}
        <br /> Service: {record.companyDetails.addressType || "-"} <br />
      </Typography>
      <Typography variant="subtitle2">Applicant Address</Typography>
      <Divider />
      <Typography variant="body2" color="textSecondary" component="span">
        {record.applicantAddress.address1 || "-"}
        <br />
        {record.applicantAddress.address2 || "-"}
        <br />
        {record.applicantAddress.city || "-"}
        <br />
        {record.applicantAddress.state || "-"}
        <br />
        {record.applicantAddress.country || "-"}
        <br />
        {record.applicantAddress.postalCode || "-"}
        <br />
        {record.applicantAddress.phone || "-"}
      </Typography>
      <Typography variant="subtitle2">Company Details</Typography>
      <Divider />
      <Typography variant="body2" color="textSecondary" component="span">
        SSN: {record.companyDetails.SSN || "-"}
        <br />
        ITIN: {record.companyDetails.ITIN || "-"}
        <br />
        EIN: {record.companyDetails.EIN || "-"}
      </Typography>
      <ArrayField source="companyDetails.owners" label="Members">
        <Datagrid>
          <TextField source="fullName" />
          <TextField source="ownership" />
          <BooleanField source="isCompany" />
        </Datagrid>
      </ArrayField>
    </Box>

    <Typography variant="subtitle2">Contact details</Typography>
    <Divider />

    <Typography variant="body2" color="textSecondary" component="span">
      Full name: {record.customer.fullName}
      <br />
      Email: {record.customer.email || "-"}
      <br />
      Phone {record.customer.phone || "-"}
    </Typography>
  </Box>
);
