import {
  Datagrid,
  DateField,
  Show,
  TextField,
  SimpleShowLayout,
  ArrayField,
  ReferenceField,
  NumberField,
  UrlField,
  ShowGuesser,
  DeleteButton,
} from "react-admin";
import { CurrencyField } from "../../components/fields/CurrencyField";

const OrderShow = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <div style={{ display: "none" }}>
          <ShowGuesser {...props} />
        </div>
        <ReferenceField
          source="companyId"
          label="Company Name"
          reference="companies"
        >
          <TextField source="companyDetails.name" />
        </ReferenceField>

        <DateField source="createdAt" />
        <CurrencyField label="Amount paid" source="data.amount_paid" />
        <TextField source="invoiceId" />
        <ReferenceField link="show" source="customerId" reference="customers">
          <TextField source="fullName" />
        </ReferenceField>
        <TextField source="data.customer_email" label="Customer Email" />
        <UrlField source="data.invoice_pdf" label="Invoice PDF URL" />
        <TextField label="Subscription Id" source="data.subscription" />
        <TextField label="Charge Id" source="data.charge" />
        <UrlField source="data.hosted_invoice_url" label="Hosted Invoice URL" />
        <ArrayField source="data.lines.data" label="Products">
          <Datagrid>
            <NumberField source="quantity" />
            <TextField source="description" />
            <CurrencyField label="Cost" source="amount" />
            <TextField label="Subscription Id" source="subscription_item" />
          </Datagrid>
        </ArrayField>
        <DeleteButton />
      </SimpleShowLayout>
    </Show>
  );
};

export default OrderShow;
