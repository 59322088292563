import { AggregatedState, CompanyStatus } from "../models/Company";

export const formatCompanyStatus = (companyStatus: CompanyStatus) => {
  switch (companyStatus) {
    case CompanyStatus.canceled:
      return "Canceled";
    case CompanyStatus.verified:
      return "Verified (Paid)";
    case CompanyStatus.unsubmitted:
      return "Unsubmitted";
    case CompanyStatus.requiresAction:
      return "Inbox";
    case CompanyStatus.blocked:
      return "Blocked";
    default:
      return companyStatus;
  }
};

export const formatCompanyAggregatedState = (
  aggregatedState: AggregatedState
) => {
  switch (aggregatedState) {
    case AggregatedState.einInProgress:
      return "EIN in progress";
    case AggregatedState.einReceived:
      return "EIN received";
    case AggregatedState.formed:
      return "Company formed";
    case AggregatedState.unformed:
      return "Company not yet formed";

    default:
      return aggregatedState;
  }
};
