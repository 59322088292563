export const PAGE_SIZE = 25;

export const defaultPagination = {
  page: 0,
  perPage: PAGE_SIZE,
};
export const defaultSorting = {
  field: "",
  order: "",
};
export const filterParams = {
  pageSize: PAGE_SIZE,
};
