const local = {
  stageName: "local",
  api: {
    URL: "http://localhost:3000",
  },
  customerApp: {
    URL: "http://localhost:3001",
  },
  amplify: {
    Auth: {
      region: "eu-central-1",
      userPoolId: "eu-central-1_Z6m2RUHtM",
      userPoolWebClientId: "7l7a26m5tesdp1eg07sa3mmoc0",
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: "https://api.development.aws.doola.com",
        },
      ],
    },
  },
};

const dev = {
  stageName: "dev",
  api: {
    URL: "https://api.development.aws.doola.com",
  },
  customerApp: {
    URL: "https://app.development.aws.doola.com",
  },
  amplify: {
    Auth: {
      region: "eu-central-1",
      userPoolId: "eu-central-1_Z6m2RUHtM",
      userPoolWebClientId: "7l7a26m5tesdp1eg07sa3mmoc0",
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: "https://api.development.aws.doola.com",
        },
      ],
    },
  },
};

const staging = {
  api: {
    URL: "https://api.staging.aws.doola.com",
  },
  customerApp: {
    URL: "https://staging-app.doola.com",
  },
  amplify: {
    Auth: {
      region: "eu-central-1",
      userPoolId: "eu-central-1_cSPf2JLK1",
      userPoolWebClientId: "7ivhk41n747aprh2fcv21nkbv8",
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: "https://api.staging.aws.doola.com",
        },
      ],
    },
  },
};

const sandbox = {
  api: {
    URL: "https://api.sandbox.aws.doola.com",
  },
  customerApp: {
    URL: "https://app.sandbox.aws.doola.com",
  },
  amplify: {
    Auth: {
      region: "eu-central-1",
      userPoolId: "eu-central-1_Unv4jBfvu",
      userPoolWebClientId: "6bo3uc2g4jtbq4ev83sedknva4",
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: "https://api.sandbox.aws.doola.com",
        },
      ],
    },
  },
};

const prod = {
  api: {
    URL: "https://api.production.aws.doola.com",
  },
  customerApp: {
    URL: "https://app.doola.com",
  },
  amplify: {
    Auth: {
      region: "eu-central-1",
      userPoolId: "eu-central-1_ySa0abgq4",
      userPoolWebClientId: "67l9t8ko2mdrcvg51m6esagbkh",
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: "https://api.production.aws.doola.com",
        },
      ],
    },
  },
};

const getConfig = (env: string) => {
  switch (env) {
    case "sandbox":
      return sandbox;
    case "prod":
    case "production":
      return prod;
    case "staging":
      return staging;
    case "local":
      return local;
    default:
      return dev;
  }
};

export const isProduction = process.env.REACT_APP_ENV === "prod";

const config = {
  ...(process.env.REACT_APP_ENV ? getConfig(process.env.REACT_APP_ENV) : dev),
};

export default config;
