import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ArrayField,
  Datagrid,
} from "ra-ui-materialui";

const ProductShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="entityType" />
      <DateField source="version" />
      <ArrayField source="services">
        <Datagrid>
          <TextField source="name" />
        </Datagrid>
      </ArrayField>
      <TextField source="status" />
      <DateField source="createdAt" />
      <TextField source="name" />
      <TextField source="id" />

      <DateField source="updatedAt" />
      <TextField source="description" />
      <TextField source="id" />
    </SimpleShowLayout>
  </Show>
);

export default ProductShow;
