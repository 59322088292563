import { Typography } from "@material-ui/core";
import {
  ArrayInput,
  BooleanInput,
  Create,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useNotify,
  useRedirect,
} from "react-admin";
import { customerBaseSchema } from "../../validation/customerValidation";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomerStatus, PersistedCustomer } from "../../models/Customer";
import { createCustomerStyles } from "./styles";

const CustomerCreate = (props: any) => {
  const classes = createCustomerStyles();
  const redirect = useRedirect();

  const onSuccess = ({ data }: { data: PersistedCustomer }) => {
    redirect(`/customers/${data.id}/show`);
  };
  const notify = useNotify();

  return (
    <>
      <Typography variant="h4" style={{ textAlign: "center" }}>
        Create New Customer
      </Typography>

      <Create
        onSuccess={onSuccess}
        onFailure={(response) => {
          console.log("failure");
          console.log(response);
          notify(
            "Failed because of: " +
              (response?.response?.data?.message ||
                JSON.stringify(response?.response?.data)),
            {
              type: "warning",
            }
          );
        }}
        className={classes.wrapperCreateCustomer}
        {...props}
      >
        <SimpleForm
          style={{ maxWidth: "500px" }}
          className={classes.simpleForm}
          validate={async (values: any) => {
            // console.log(values);
            const validation = await zodResolver(customerBaseSchema)(
              values,
              undefined,
              {} as any
            );

            return validation.errors;
          }}
        >
          <TextInput source="email" fullWidth />
          <TextInput source="fullName" fullWidth />
          <TextInput source="stripeId" fullWidth />
          <TextInput
            label="Airtable Pipeline ID"
            source="internalDetails.airtablePipelineId"
            fullWidth
          />

          <BooleanInput
            defaultValue={true}
            source="unsubscribeWelcomeEmail"
            label="Do not send Welcome Mail (plan)"
            fullWidth
          />

          {/* THE HIDDEN INPUTS */}
          <SelectInput
            label="Status"
            source="status"
            style={{ marginBottom: 0, display: "none" }}
            defaultValue={CustomerStatus.verified}
            fullWidth
            choices={Object.values(CustomerStatus).map((val) => ({
              id: val,
              name: val,
            }))}
          />

          <ArrayInput
            source="companyIds"
            label="Company Ids"
            defaultValue={[]}
            fullWidth
            style={{ width: "100%", display: "none" }}
          >
            <SimpleFormIterator>
              <TextInput
                fullWidth
                style={{ margin: 0 }}
                source="companyIds"
                label="Company Ids"
              />
            </SimpleFormIterator>
          </ArrayInput>

          {/* {errors && <pre style={{ color: "red" }}>{errors}</pre>} */}
        </SimpleForm>
      </Create>
    </>
  );
};

export default CustomerCreate;
