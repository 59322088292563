import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ClearCacheProvider, useClearCacheCtx } from "react-clear-cache";

import * as serviceWorker from "./serviceWorker";
const MainApp: React.FC<{}> = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion, emptyCacheStorage]);

  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

ReactDOM.render(
  <ClearCacheProvider duration={1000 * 60 * 5}>
    <MainApp />
  </ClearCacheProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
