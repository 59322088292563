import * as React from "react";
import { ChipField } from "react-admin";

export const ColoredChipField = ({ record, ...props }: any) =>
  record ? (
    <ChipField
      record={record}
      {...props}
      style={{ backgroundColor: record.color || props.color, border: 0 }}
      component="span"
    />
  ) : null;
