// Generated by ts-to-zod
import { z } from "zod";
import {
  ServiceNames,
  GenericServiceState,
  ServiceStatus,
  LLCFormationServiceState,
  EINServiceState,
  PhoneLineServiceState,
  ITINServiceState,
  BankingServiceState,
  PaymentServiceState,
  AnnualReportFilingServiceState,
  TaxFilingServiceState,
  ServiceOptionsNames,
  BookkeepingEssentialServiceState,
  BookkeepingPremiumServiceState,
} from "./ServiceEnums";

export const serviceNamesSchema = z.nativeEnum(ServiceNames);

export const genericServiceStateSchema = z.nativeEnum(GenericServiceState);

export const serviceStatusSchema = z.nativeEnum(ServiceStatus);

export const LLCFormationServiceStateSchema = z.nativeEnum(
  LLCFormationServiceState
);

export const eINServiceStateSchema = z.nativeEnum(EINServiceState);

export const phoneLineServiceStateSchema = z.nativeEnum(PhoneLineServiceState);

export const iTINServiceStateSchema = z.nativeEnum(ITINServiceState);

export const bankingServiceStateSchema = z.nativeEnum(BankingServiceState);

export const paymentServiceStateSchema = z.nativeEnum(PaymentServiceState);

export const annualReportFilingServiceStateSchema = z.nativeEnum(
  AnnualReportFilingServiceState
);

export const taxFilingServiceStateSchema = z.nativeEnum(TaxFilingServiceState);

export const bookkeepingEssentialServiceSchema = z.nativeEnum(BookkeepingEssentialServiceState);

export const bookkeepingPremiumServiceSchema = z.nativeEnum(BookkeepingPremiumServiceState);

export const serviceOptionsNamesSchema = z.nativeEnum(ServiceOptionsNames);

export const serviceOptionSchema = z.object({
  key: serviceOptionsNamesSchema,
  value: z.boolean(),
});

export const iCompanyServiceSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  validUntil: z.string(),
  standalone: z.boolean(),
  renewalCount: z.number(),
  name: serviceNamesSchema,
  subscriptionId: z.string(),
  serviceState: z.string(),
  status: serviceStatusSchema,
  options: z.array(serviceOptionSchema),
});
