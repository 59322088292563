import {
  ArrayInput,
  BooleanInput,
  Create,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  DateInput,
  useNotify,
} from "react-admin";
import { Grid, Divider } from "@material-ui/core";
import { zodResolver } from "@hookform/resolvers/zod";
import stateKeys from "../../formatters/stateFormatter";
import { companyCreateBaseSchema } from "../../validation/companyValidation";
import countries from "./resources/countries";
import industryOptions from "./resources/industries";
import { useLocation } from "react-router-dom";
import { useCompanyCreationStyles } from "./styles";
import { AddServices } from "./AddServices";
import { Typography } from "@material-ui/core";
import { allBusinessTypes, stylesOptions } from ".";
import { BusinessTypeNames, BusinessTypeStyles } from "../../models/Company";
import { useCallback, useState } from "react";
import { BusinessStylesDropDown } from "./CompanyEdit";

export type BusinessTypeOption = {id: BusinessTypeStyles | "", name: string}
const CompanyCreate = (props: any) => {
  const [ defaultLlcOption ] = stylesOptions(BusinessTypeNames.LLC)
  const [entityStyleSelected, setEntityStyleSelected] = useState<BusinessTypeOption>(defaultLlcOption);
  const classes = useCompanyCreationStyles();
  const queryParams = new URLSearchParams(useLocation().search);
  const customerId = queryParams.get("customerId");
  const notify = useNotify();
  const businessTypes = allBusinessTypes();
  const handlerBusinessSelection = useCallback((selection) => {
    const value = selection.target.value;
    const selectedOption = value === '-' ? {id: '', name: '-'} : stylesOptions(value as BusinessTypeNames)[1]
    setEntityStyleSelected(selectedOption as BusinessTypeOption);
  }, [])
  const businesStyleOption = stylesOptions(entityStyleSelected?.id as BusinessTypeNames)
  console.log(businesStyleOption, entityStyleSelected?.id)
  return (
    <>
      <Typography variant="h4" style={{ textAlign: "center" }}>
        Create Customer Company
      </Typography>
      <Create
        className={classes.wrapperCreateCompany}
        {...props}
        onFailure={(response) => {
          console.log("failure");
          console.log(response);
          notify(
            "Failed because of: " +
              (response?.response?.data?.message ||
                JSON.stringify(response?.response?.data)),
            {
              type: "warning",
            }
          );
        }}
      >
        <SimpleForm
          redirect="show"
          className={classes.container}
          style={{ width: "100%" }}
          onSubmit={(data: any) => {
            console.log(data, "data");
            return data;
          }}
          validate={async (values: any) => {
            const validation = await zodResolver(companyCreateBaseSchema)(
              values,
              undefined,
              {} as any
            );
            console.error(validation.errors);
            return validation.errors;
          }}
        >
          <Grid container spacing={2}>
            <TextInput
              style={{ display: "none" }}
              source="customerId"
              defaultValue={customerId}
              label="customerId"
            />
            <Grid item>
              <DateInput source="createdAt" />
            </Grid>
            <Grid item>
              <TextInput source="companyDetails.name" label="Company Name" />
            </Grid>
            <Grid item>
              <SelectInput
                label="Entity Type"
                source="companyDetails.businessType.name"
                value={entityStyleSelected}
                onChange={handlerBusinessSelection}
                choices={[
                  ...businessTypes,
                  { id: "", name: "-" },
                ]}
              />
            </Grid>
            <Grid item>
              <SelectInput
                label="Name Style"
                source="companyDetails.businessType.style"
                emptyValue={""}
                choices={businesStyleOption}
              />
            </Grid>

            <Grid item>
              Company Services
              <AddServices />
            </Grid>

            <Grid item>
              <TextInput label="ITIN" source="companyDetails.ITIN" />
            </Grid>
            <Grid item>
              <TextInput
                defaultValue={""}
                label="EIN"
                source="companyDetails.EIN"
              />
            </Grid>
            <Grid item>
              <TextInput label="SSN" source="companyDetails.SSN" />
            </Grid>

            <Grid item>
              <SelectInput
                label="Formation State"
                source="companyDetails.geographicState"
                choices={[
                  { id: "", name: "-" },
                  ...Object.entries(stateKeys).map((values, index) => ({
                    id: values[0],
                    name: values[1],
                  })),
                ]}
              />
            </Grid>
            <Grid item>
              <TextInput
                label="Description"
                source="companyDetails.description"
                defaultValue={""}
              />
            </Grid>

            <Grid item>
              <SelectInput
                label="Activity"
                source="companyDetails.activity"
                emptyValue={"-"}
                choices={[
                  { value: "", name: "-" },
                  ...industryOptions
                    .sort((a, b) => a.position - a.position)
                    .map((item) => ({ id: item.label, name: item.label })),
                ]}
              />
            </Grid>
            <Grid item>
              <SelectInput
                label="Website"
                source="companyDetails.url"
                defaultValue={""}
              />
            </Grid>
            <Grid item>
              <TextInput
                label="Activity (Other)"
                source="companyDetails.activityOther"
              />
            </Grid>
            <Grid item>
              <SelectInput
                label="Address Type"
                source="companyDetails.addressType"
                emptyValue={"-"}
                choices={[
                  {
                    id: "registeredAgent",
                    name: "Our US Registered Agent Address",
                  },
                  {
                    id: "applicantAddress",
                    name: "Customers mailing address (the one you enter below)",
                  },
                ]}
              />
            </Grid>
            <Grid item>
              Applicant Address
              <Divider />
            </Grid>
            <Grid item>
              <TextInput
                label="Phone"
                source="applicantAddress.phone"
                defaultValue={""}
              />
            </Grid>
            <Grid item>
              <TextInput
                label="Address Line 1"
                source="applicantAddress.address1"
              />
            </Grid>
            <Grid item>
              <TextInput
                label="Address Line 2"
                source="applicantAddress.address2"
              />
            </Grid>
            <Grid item>
              <TextInput label="City" source="applicantAddress.city" />
            </Grid>
            <Grid item>
              <TextInput label="State" source="applicantAddress.state" />
            </Grid>
            <Grid item>
              <TextInput
                label="Postal code"
                source="applicantAddress.postalCode"
              />
            </Grid>

            <Grid item>
              <SelectInput
                // emptyValue={"-"}
                label="Country"
                choices={[
                  { value: "", name: "-" },
                  ...countries
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((country) => ({
                      id: country.label,
                      name: country.label,
                    })),
                ]}
                source="applicantAddress.country"
              />
            </Grid>
            <Grid item>
              Mailing Address
              <Divider />
            </Grid>

            <Grid item>
              <TextInput
                label="Address Lines"
                source="companyDetails.mailingAddress.linesFormatted"
              />
            </Grid>
            <Grid item>
              <TextInput
                label="City, State, Postal Code"
                source="companyDetails.mailingAddress.cityStateZipCodeFormatted"
              />
            </Grid>
            <Grid item>
              <TextInput
                label="County, State"
                source="companyDetails.mailingAddress.countyAndStateFormatted"
              />
            </Grid>
            <Grid item>
              <SelectInput
                emptyValue={"United States"}
                label="Country"
                choices={[
                  { value: "", name: "-" },
                  ...countries
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((country) => ({
                      id: country.label,
                      name: country.label,
                    })),
                ]}
                source="companyDetails.mailingAddress.country"
              />
            </Grid>
            <Grid item>
              <Divider />
            </Grid>

            <Grid item>
              <ArrayInput
                source="companyDetails.owners"
                label="Members"
                style={{ width: "100%" }}
                defaultValue={[
                  { fullName: "", ownership: "", isCompany: false },
                ]}
              >
                <SimpleFormIterator>
                  <TextInput
                    style={{ margin: 0 }}
                    source="fullName"
                    label="Full name"
                  />
                  <TextInput
                    source="ownership"
                    style={{ width: "100%" }}
                    label="Ownership %"
                  />
                  <BooleanInput
                    source="isCompany"
                    style={{ width: "100%" }}
                    label="Is company"
                    defaultValue={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
            <Grid item>
              <TextInput
                label="Google Drive Id"
                source="documents.googleDriveFolderId"
                defaultValue={""}
              />
            </Grid>
            <Grid item>
              <TextInput
                label="Articles File Id"
                source="documents.articlesFileId"
              />
            </Grid>
            <Grid item>
              <TextInput
                label="Signed SS4 File Id"
                source="documents.signedSS4FileId"
              />
            </Grid>
            <Grid item>
              <DateInput
                label="Renewals Date"
                source="renewalDetails.renewalDate"
              />
            </Grid>
            <Grid item>
              <BooleanInput
                label="Renewals charged"
                source="renewalDetails.charged"
              />
            </Grid>
            <Grid item>
              <BooleanInput
                label="Renewals emailed"
                source="renewalDetails.emailed"
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </>
  );
};
export default CompanyCreate;
