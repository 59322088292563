import { Box } from "@material-ui/core";
import { FC } from "react";

export const StyledListBox: FC = ({ children }) => {
  return (
    <Box
      sx={
        {
          "& .select-all": {
            display: "none",
          },
        } as any
      }
    >
      {children}
    </Box>
  );
};
