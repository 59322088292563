import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import UpdateIcon from "@material-ui/icons/Update";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HelpIcon from "@material-ui/icons/Help";
import {
  AnnualComplianceReminderServiceState,
  AnnualReportFilingServiceState,
  BankingServiceState,
  BookkeepingEssentialServiceState,
  BookkeepingPremiumServiceState,
  EINServiceState,
  GenericServiceState,
  ITINServiceState,
  LLCFormationServiceState,
  PaymentServiceState,
  PhoneLineServiceState,
  ServiceNames,
  ServiceOptionsNames,
  ServiceStatus,
} from "../models/ServiceModel/ServiceEnums";

export const serviceTagColors = [
  "#eddcd2",
  "#fff1e6",
  "#fde2e4",
  "#fad2e1",
  "#c5dedd",
  "#dbe7e4",
  "#f0efeb",
  "#d6e2e9",
  "#bcd4e6",
  "#99c1de",
];

export const formatServiceStatusWithIcons = (serviceStatus: ServiceStatus) => {
  switch (serviceStatus) {
    case ServiceStatus.inactive:
      return <PauseCircleOutlineIcon />;
    case ServiceStatus.requiresAction:
      return <AssignmentLateIcon />;
    case ServiceStatus.processing:
      return <UpdateIcon />;
    case ServiceStatus.completed:
      return <CheckCircleOutlineIcon />;
    default:
      return <HelpIcon />;
  }
};

export const formatServiceStatus = (serviceStatus: ServiceStatus) => {
  switch (serviceStatus) {
    case ServiceStatus.inactive:
      return "Inactive";
    case ServiceStatus.requiresAction:
      return "Requires action";
    case ServiceStatus.processing:
      return "Processing (waiting)";
    case ServiceStatus.completed:
      return "Completed";
    default:
      return "Service Formatter not implemented";
  }
};

export const formatServiceName = (serviceName: ServiceNames) => {
  switch (serviceName) {
    case ServiceNames.llcFormation:
      return "LLC Formation";
    case ServiceNames.llcDissolution:
      return "LLC Dissolution (churn)";
    case ServiceNames.banking:
      return "Business Bank Account Setup";
    case ServiceNames.paymentGateway:
      return "Payment Gateway";
    case ServiceNames.einCreation:
      return "EIN (Employer Identification Number)";
    case ServiceNames.annualComplianceReminder:
      return "Annual Compliance Reminders";
    case ServiceNames.phoneLine:
      return "US Phone Line with a Utility Bill";
    case ServiceNames.taxFiling:
      return "Tax/Compliance Bundle";
    case ServiceNames.iTin:
      return "ITIN (Individual Taxpayer Identification Number)";
    case ServiceNames.annualReportFiling:
      return "Annual Report Filing";
    case ServiceNames.BookkeepingEssential:
      return "Bookkeeping Essential";
    case ServiceNames.BookkeepingPremium:
      return "Bookkeeping Premium"
    default:
      return serviceName;
  }
};

export const mapServiceNameToColor = (serviceName: ServiceNames) => {
  switch (serviceName) {
    case ServiceNames.llcFormation:
      return serviceTagColors[0];
    case ServiceNames.banking:
      return serviceTagColors[1];
    case ServiceNames.einCreation:
      return serviceTagColors[3];
    case ServiceNames.annualReportFiling:
      return serviceTagColors[4];
    case ServiceNames.phoneLine:
      return serviceTagColors[5];
    case ServiceNames.taxFiling:
      return serviceTagColors[6];
    case ServiceNames.iTin:
      return serviceTagColors[7];
    case ServiceNames.annualComplianceReminder:
      return serviceTagColors[8];
    default:
      return serviceTagColors[9];
  }
};

export const formatServiceOptions = (serviceOption: ServiceOptionsNames) => {
  switch (serviceOption) {
    case ServiceOptionsNames.speedFilingExpedite:
      return "Filing Speed Expedite";
    case ServiceOptionsNames.speedFilingRush:
      return "Filing Speed Rush";

    default:
      return "Service Formatter not implemented";
  }
};

export const mapStatusEnumToServiceName = (name: ServiceNames) => {
  switch (name) {
    case ServiceNames.phoneLine:
      return PhoneLineServiceState;
    case ServiceNames.llcFormation:
      return LLCFormationServiceState;
    case ServiceNames.einCreation:
      return EINServiceState;
    case ServiceNames.iTin:
      return ITINServiceState;
    case ServiceNames.banking:
      return BankingServiceState;
    case ServiceNames.paymentGateway:
      return PaymentServiceState;
    case ServiceNames.annualComplianceReminder:
      return AnnualComplianceReminderServiceState;
    case ServiceNames.annualReportFiling:
      return AnnualReportFilingServiceState;
    case ServiceNames.BookkeepingEssential:
      return BookkeepingEssentialServiceState;
    case ServiceNames.BookkeepingPremium:
      return BookkeepingPremiumServiceState;
    default:
      return GenericServiceState;
  }
};

export const mapServiceStateToPercentageAsString = (
  name: ServiceNames,
  serviceState: string
) => {
  const percentage = mapServiceStateToPercentageAsFloat(name, serviceState);
  if (percentage > 0) {
    return Number(percentage).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
  }
  return "Unknown";
};

export const mapServiceStateToPercentageAsFloat = (
  name: ServiceNames,
  serviceState: string
) => {
  const percentage =
    [...Object.values(mapStatusEnumToServiceName(name))].findIndex(
      (item) => item === serviceState
    ) / Object.values(mapStatusEnumToServiceName(name)).length;

  if (percentage < 0) {
    return 0;
  }
  return percentage;
};
