import { FooterEmail } from "./Footer";

type Props = {
  firstName: string;
  companyName: string;
};

export const SecondSubmissionSentEmail = ({
  firstName,
  companyName,
}: Props) => {
  return (
    <>
      <p>Hi {firstName},</p>
      <p>
        This message is to confirm that the second EIN application submission
        for {companyName} is under review with the IRS. This will help speed up
        the timeline for your EIN approval.
      </p>
      <p>
        As soon as we hear back from the IRS you will be the first to know.{" "}
        <b>We're on your team!</b>
      </p>
      <p>
        Lastly, as a reminder, the IRS doesn't provide "updates" on EINs but you
        (and you only!) can call and request an EIN verification letter. (See
        here for more:{" "}
        <a
          href="http://help.doola.com/en/articles/5572640-how-do-i-request-an-ein-verification-letter"
          rel="noopener noreferrer"
          target="_blank"
        >
          How Do I Request an EIN Verification Letter?
        </a>
        )
      </p>
      <p>
        In the meantime, thanks for your patience and don't hesitate to reach
        out with any questions!
      </p>
      <FooterEmail />
    </>
  );
};
