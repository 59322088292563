import {
  BooleanInput,
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from "react-admin";
import { customerBaseSchema } from "../../validation/customerValidation";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomerStatus } from "../../models/Customer";

const CompanyEdit = (props: any) => {
  const notify = useNotify();

  return (
    <Edit
      {...props}
      mutationMode="optimistic"
      onFailure={(response) => {
        console.log("failure");
        console.log(response);
        notify(
          "Failed because of: " +
            (response?.response?.data?.message ||
              JSON.stringify(response?.response?.data)),
          {
            type: "warning",
          }
        );
      }}
    >
      <SimpleForm
        redirect="show"
        style={{ maxWidth: "800px" }}
        validate={async (values: any) => {
          const validation = await zodResolver(customerBaseSchema)(
            values,
            undefined,
            {} as any
          );
          console.log(validation);
          return validation.errors;
        }}
      >
        <TextInput source="fullName" label="Full Name" />
        <TextInput source="email" label="Email" />
        <TextInput source="phone" label="Phone" />
        <TextInput source="stripeId" label="Stripe id" />

        <TextInput source="customerIoId" label="Customer io id" />
        <BooleanInput
          source="unsubscribeWelcomeEmail"
          label="Do not send Welcome Mail (plan)"
        />
        <TextInput
          source="internalDetails.airtablePipelineId"
          label="Airtable Pipeline ID"
        />
        <TextInput
          source="internalDetails.migrationStatus"
          label="Migration Status"
        />

        <TextInput source="timezone" label="Time Zone" />
        <SelectInput
          label="Status"
          choices={Object.values(CustomerStatus).map((item) => ({
            id: item,
            name: item,
          }))}
          source="status"
        />

        {/* <ArrayInput
          source="companyIds"
          label="Company Ids"
          style={{ width: "100%" }}
        >
          <SimpleFormIterator>
            <TextInput label="company id" source={""} />
          </SimpleFormIterator>
        </ArrayInput> */}
      </SimpleForm>
    </Edit>
  );
};
export default CompanyEdit;
