import * as React from "react";
import { Button } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Toolbar from "@material-ui/core/Toolbar";
import { PaginationProps } from "react-admin";
import { useSelector } from "react-redux";

interface CustomPagination {
  nextToken: any;
  setNextToken: (value: any) => void;
}

export const Pagination = (props: PaginationProps & CustomPagination) => {
  const nextTokenSelector = useSelector<any>((state) => state.nextToken);
  const { nextToken, setNextToken } = props;

  return (
    <>
      {(nextToken || nextTokenSelector) && (
        <Toolbar>
          {nextToken && (
            <Button
              color="primary"
              key="previous"
              startIcon={<ChevronLeft />}
              onClick={() => setNextToken(null)}
            >
              Reset
            </Button>
          )}
          {nextTokenSelector && (
            <Button
              style={{ marginLeft: "auto" }}
              color="primary"
              key="next"
              endIcon={<ChevronRight />}
              onClick={() => setNextToken(nextTokenSelector)}
            >
              Next
            </Button>
          )}
        </Toolbar>
      )}
    </>
  );
};

export const LoadMoreComponent = (
  props: PaginationProps & CustomPagination
) => {
  const nextTokenSelector = useSelector<any>((state) => state.nextToken);
  const { nextToken, setNextToken } = props;

  return (
    <>
      {(nextToken || nextTokenSelector) && (
        <Toolbar>
          {nextTokenSelector && (
            <Button
              color="primary"
              key="next"
              endIcon={<ChevronRight />}
              onClick={() => setNextToken(nextTokenSelector)}
            >
              Next
            </Button>
          )}
          {nextToken && (
            <Button
              style={{ marginLeft: "auto" }}
              color="primary"
              key="previous"
              onClick={() => setNextToken(null)}
            >
              Refresh
            </Button>
          )}
        </Toolbar>
      )}
    </>
  );
};

export default React.memo<PaginationProps & CustomPagination>(Pagination);

export const LoadMore = React.memo<PaginationProps & CustomPagination>(
  LoadMoreComponent
);
