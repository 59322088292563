import { Typography, Box } from "@material-ui/core";
import ServiceActions from "./ServiceActions";
import { ServiceStateEdit } from "../../services/ServiceStatusListEdit";
import { CompanyService } from "../../../models/ServiceModel/ServiceModel";
import { makeStyles } from "@material-ui/core";
import { PersistedCompany } from "../../../models/Company";
import { Link, useMutation } from "react-admin";
import { FC } from "react";
import { Grid } from "@material-ui/core";
import { ColoredChipField } from "../../customers/TagsList";
import { mapServiceNameToColor } from "../../../formatters/serviceFormatters";

export const InboxServiceStyles = makeStyles({
  serviceWrapper: {},
  serviceStateWrapper: {
    float: "right",
  },
  wrapper: {
    position: "relative",
    borderRadius: "4px",
    padding: "10px",
    margin: "10px",
  },
});

type Props = {
  zIndex?: number;
  company: PersistedCompany;
  service: {
    object: CompanyService;
    name: string;
    key: string;
    serviceState: string;
    serviceColor: string;
    serviceProgress: number;
    lastUpdate: string;
  };
};
const InboxServiceEdit: FC<Props> = ({ service, company, zIndex }) => {
  const classes = InboxServiceStyles();
  const [refetchCompany] = useMutation({
    type: "getOne",
    resource: "companies",
    payload: { id: company.id },
  });

  return (
    <Grid
      container
      spacing={1}
      className={classes.wrapper}
      style={{
        border: "1px solid " + service.serviceColor,
        borderLeft: "2mm solid " + service.serviceColor,
      }}
    >
      <Grid item sm={6} xs={12}>
        <Typography
          variant="subtitle2"
          component={Link}
          to={`services/${encodeURIComponent(service.object.id)}/show`}
          style={{ display: "initial" }}
        >
          <b>{service.name}</b>
          <small style={{ paddingLeft: 10 }}>
            {Math.round(service.serviceProgress)}%
          </small>
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Box className={classes.serviceStateWrapper}>
          <ServiceStateEdit
            record={service.object}
            refetchCompany={refetchCompany}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ServiceActions service={service} company={company} />
      </Grid>
    </Grid>
  );
};
export const InboxServiceShow: FC<Props> = ({ service, company, zIndex }) => {
  const classes = InboxServiceStyles();

  return (
    <Grid
      container
      spacing={1}
      className={classes.wrapper}
      style={{
        border: "1px solid " + service.serviceColor,
        borderLeft: "2mm solid " + service.serviceColor,
      }}
    >
      <Grid item sm={6} xs={6}>
        <Typography
          variant="subtitle2"
          component={Link}
          to={`services/${encodeURIComponent(service.object.id)}/show`}
          style={{ display: "initial" }}
        >
          <b>
            {service.name} (Progress {Math.round(service.serviceProgress)}%)
          </b>
          <br />
        </Typography>
        <ColoredChipField
          record={{
            name: service.serviceState,
            color: mapServiceNameToColor(service.name as any),
          }}
          source="name"
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item xs={6}>
        <ServiceActions service={service} company={company} />
      </Grid>
    </Grid>
  );
};

export default InboxServiceEdit;
