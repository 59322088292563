import { BackendActions } from "../services/backendActions";
import { PersistedCompany } from "../models/Company";
import {
  Confirm,
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  useNotify,
  useRefresh,
  Button as RaButton,
} from "react-admin";
import { CompanyService } from "../models/ServiceModel/ServiceModel";
import { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SelectExpediteModalContainer } from "../features/companies/components/SelectExpedite";

export enum ActionTypes {
  formCompany = "form-company",
  previewSS4 = "preview-ss4",
  previewSignedSS4 = "preview-signed-ss4",
  requestSS4Signature = "request-ss4-signature",
  faxSS4ToIRS = "fax-SS4-to-IRS",
  goToFolder = "go-to-folder",
  completeService = "complete-service",
  changeServiceState = "change-service-state",
  annualReportOnline = "anual-report-online",
  applyForEinOnline = "apply-for-ein-online",
  einReceived = "ein-received",
  einResubmissionSent = "ein-resubmission-sent",
  linkInvoice = "link-invoice",
}

export const ACTIONS_DISABLED_FOR_NON_LLCS = [ActionTypes.formCompany];

type CustomActionsProps = {
  company: PersistedCompany;
  service?: CompanyService;
  label: string;
  type: ActionTypes;
  payload?: any;
  noConfirm?: boolean;
};

export const CustomAction = (props: CustomActionsProps) => {
  const dispatch = useDispatch();
  const [expedite, setExpedite] = useState({
    showModal: false,
    option: "",
  });
  const { noConfirm, label, type, payload, company, service } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    if (type === ActionTypes.formCompany) {
      setExpedite((e) => ({ ...e, showModal: true }));
    } else {
      setOpen(true);
    }
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    dispatch({ type: FETCH_START });
    setLoading(true);
    try {
      const actionService = new BackendActions({ company, service, notify });
      await triggerActionDependingOnType(
        actionService,
        type,
        payload,
        expedite.option
      );
      refresh();
      notify("Action complete", { type: "success" });
    } catch (error) {
      console.error(error);
      handleDialogClose();

      const MySwal = withReactContent(Swal);
      const { swapData } = error as any;

      !swapData &&
        notify(`Action error: ${(error as any).message}`, {
          type: "warning",
        });

      swapData && (await MySwal.fire(swapData));

      dispatch({ type: FETCH_ERROR });
    }
    dispatch({ type: FETCH_END });
    setLoading(false);
    setOpen(false);
  };

  return (
    <Fragment>
      {!noConfirm ? (
        <Fragment>
          <RaButton
            label={label}
            onClick={handleClick}
            variant="contained"
            size="small"
            color={"secondary"}
            disabled={false}
            style={{
              ...getStylesDependingOnType(type),
              marginRight: "10px",
              fontSize: "10px",
              float: "right",
            }}
          />
          <Confirm
            isOpen={open}
            loading={loading}
            title="Are you sure?"
            content="Actions can not be undone."
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </Fragment>
      ) : (
        <RaButton
          disabled={loading}
          label={label}
          onClick={handleConfirm}
          variant="contained"
          size="small"
          color={"secondary"}
          style={{
            ...getStylesDependingOnType(type),
            marginRight: "10px",
            fontSize: "10px",
            float: "right",
          }}
        />
      )}
      {expedite.showModal && (
        <SelectExpediteModalContainer
          state={company.companyDetails.geographicState}
          onSuccessCallback={(option: string) => {
            setExpedite((e) => ({ option: option, showModal: false }));
            setOpen(true);
          }}
        />
      )}
    </Fragment>
  );
};

export default CustomAction;

const triggerActionDependingOnType = (
  service: BackendActions,
  type: ActionTypes,
  payload: any,
  expediteOption: string | undefined
) => {
  switch (type) {
    case ActionTypes.formCompany:
      return service.formCompany(expediteOption);

    case ActionTypes.previewSS4:
      return service.previewSS4();

    case ActionTypes.requestSS4Signature:
      return service.requestSS4Signature();

    case ActionTypes.faxSS4ToIRS:
      return service.faxSS4ToIRS();

    case ActionTypes.einReceived:
      return service.einReceived();

    case ActionTypes.previewSignedSS4:
      return service.previewSignedSS4();

    case ActionTypes.applyForEinOnline:
      return service.applyForEinOnline();

    case ActionTypes.einResubmissionSent:
      return service.einResubmissionSent();

    case ActionTypes.goToFolder:
      return service.goToFolder();

    case ActionTypes.annualReportOnline:
      return service.annualReportOnline();

    case ActionTypes.changeServiceState:
      return service.changeServiceState(payload as { to: string });

    case ActionTypes.completeService:
      return service.completeService();

    default:
      throw new Error(`Action ${type} not defined`);
  }
};

const getStylesDependingOnType = (
  type: ActionTypes
): { backgroundColor: string; color: string } => {
  switch (type) {
    case ActionTypes.formCompany:
      return { backgroundColor: "#1ec932", color: "#fffbfc" };
    case ActionTypes.goToFolder:
      return { backgroundColor: "#17bfff", color: "#fffbfc" };
    case ActionTypes.previewSS4:
      return { backgroundColor: "#17bfff", color: "#fffbfc" };
    case ActionTypes.previewSignedSS4:
      return { backgroundColor: "#17bfff", color: "#fffbfc" };
    case ActionTypes.requestSS4Signature:
      return { backgroundColor: "#f82b60", color: "#fffbfc" };
    case ActionTypes.faxSS4ToIRS:
      return { backgroundColor: "#f82b60", color: "#fffbfc" };
    case ActionTypes.einReceived:
      return { backgroundColor: "#f82b60", color: "#fffbfc" };
    case ActionTypes.einResubmissionSent:
      return { backgroundColor: "#f82b60", color: "#fffbfc" };
    case ActionTypes.changeServiceState:
      return { backgroundColor: "#fbb400", color: "#fffbfc" };
    case ActionTypes.applyForEinOnline:
      return { backgroundColor: "#fbb400", color: "#fffbfc" };
    case ActionTypes.annualReportOnline:
      return { backgroundColor: "#fbb400", color: "#fffbfc" };
    case ActionTypes.completeService:
      return { backgroundColor: "#2072d9", color: "#fffbfc" };
    default:
      return { backgroundColor: "#eeeeee", color: "#333333" };
  }
};
