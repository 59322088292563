export const CHURN_REASONS = [
  "The customer’s company has closed.",
  "The payment failed (expired card/insufficient/etc)",
  "We cannot fulfill the service (restricted countries, rejected by every bank or payment provider)",
  "Special circumstances (force major, death, illness, etc)",
  "Fraud",
  "Formation takes too long/was not submitted on time/incorrect",
  "EIN takes too long/was not submitted on time/incorrect",
  "Unhappy with the customer service (response time, responses, expertise)",
  "Unhappy with another product (banking, tax filing, bookkeeping, RA)",
  "It is too expensive",
  "Found another vendor",
  "The customer doesn’t disclose the reason",
];
