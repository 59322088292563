import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

const ProductEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <ArrayInput source="services">
        <SimpleFormIterator>
          <TextInput source="name" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="status" />
      <DateInput source="createdAt" />
      <TextInput source="name" />
      <DateInput source="updatedAt" />
      <TextInput source="description" />
      <TextInput source="id" />
    </SimpleForm>
  </Edit>
);
export default ProductEdit;
