export enum LLCFormationServiceState {
  formationPaused = "Formation Paused",
  pendingFormation = "Pending Company Formation",
  formationInitiated = "Initiated Company Formation",
  waitingForFormationDocs = "Waiting for Formation Docs",
  companyFormationCompleted = "Company Formation Completed",
  companyFormationFailed = "Company Formation Failed",
}

export enum ServiceNames {
  llcFormation = "llcFormation",
  einCreation = "einCreation",
  banking = "banking",
  llcDissolution = "llcDissolution",
  paymentGateway = "paymentGateway",
  iTin = "iTin",
  phoneLine = "phoneLine",
  taxFiling = "taxFiling",
  annualComplianceReminder = "annualComplianceReminder",
  annualReportFiling = "annualReportFiling",
  BookkeepingEssential = 'Bookkeeping',
  BookkeepingPremium = 'Bookkeeping Premium',
}

export enum ServiceStatus {
  inactive = "inactive", // Service is in initial state, has not been started
  requiresAction = "requiresAction", // Service is waiting on an internal action
  processing = "processing", // Service is waiting on an external action
  completed = "completed", // Service is in terminal state, completed
}

export enum EINServiceState {
  einPaused = "EIN Paused",
  companyInformationNeeded = "Company Information Needed",
  waitingForCompanyFormation = "Waiting for Company Formation",
  formationDocsReceived = "Formation Docs Received",
  waitingForSS4Signature = "Waiting for SS-4 Signature",
  applyingForEINOnline = "Applying for EIN Online (Has SSN)",
  applyingForEINFaxToIRS = "Applying for EIN via Fax to IRS (No SSN)",
  waitingOnEINFromIRS = "Waiting on EIN From IRS",
  einResubmissionRequired = "EIN Resubmission Required (21+ days since latest submission)",
  // callIRStoGetEIN = "Call IRS to get EIN",
  einReceived = "EIN Received",
}

export enum PhoneLineServiceState {
  phoneLineWaitingForEIN = "Phone Line Waiting for EIN",
  // introCustomerToPartner = "Intro customer to partner",
  // waitingForPartner = "Waiting for partner or customer",
  phoneLineSetupCompleted = "Phone Line Setup Completed",
}

export enum ITINServiceState {
  itinWaitingForEIN = "ITIN Waiting for EIN",
  readyForItin = "Email intro Customer to ITIN Partner",
  waitingForPartner = "Waiting for response from ITIN Partner",
  itinCompleted = "ITIN Completed",
}

export enum BankingServiceState {
  bankingWaitingForEIN = "Banking Waiting for EIN",
  readyToSendBankingInformation = "Ready to Send Banking Information",
  sentCustomerBankInformation = "Sent Customer Banking Information",
  // fillingOutBankingApplication = "Filling Out Banking Application",
  // waitingForBankAccountApproval = "Waiting for Bank Account Approval",
  // bankApplicationApproved = "Bank Application Approved",
  // bankApplicationRejected = "Bank Application Rejected",
}

export enum PaymentServiceState {
  paymentWaitingForEIN = "Payment Waiting for EIN",
  sentCustomerPaymentInformation = "Sent Customer Payment Information",
  // customerBankInformationSent = "Filling Out Stripe Link",
  // paymentApplicationApproved = "Payment Application Approved",
  // paymentApplicationRejected = "Payment Application Rejected",
}

export enum LLCDissolutionServiceState {
  dissolutionInactive = "Dissolution Inactive",
  dissolutionPending = "Dissolution Pending",
  dissolutionCompleted = "Dissolution Completed",
  dissolutionFailed = "Dissolution Failed",
}

export enum AnnualReportFilingServiceState {
  annualReportFilingUnrequired = "Annual Report Filing Unrequired",
  // sendAnnualReportingReminder = "Request Annual Report Online",
  waitingToCompleteAnnualReportOnline = "Waiting to Complete Annual Report Online",
  annualReportFilingCompleted = "Annual Report Filing Completed",
}

export enum AnnualComplianceReminderServiceState {
  annualComplianceReminderUnrequired = "Annual Compliance Reminder Unrequired",
  waitingToCompleteComplianceReminder = "Waiting to Complete Annual Compliance Reminder",
  annualComplianceReminderCompleted = "Annual Compliance Reminder Completed",
}

export enum TaxFilingServiceState {
  taxOffseason = "Tax Offseason",
  readyForTaxIntro = "Ready for Tax Intro",
  taxFilingIntroCompleted = "Tax Filing Intro Completed",
}

export enum BookkeepingEssentialServiceState {
  waitingOnEin = 'Bookkeeping Essential waiting on EIN',
  completedEin = 'Bookkeeping Essential completed',
}

export enum BookkeepingPremiumServiceState {
  waitingOnEin = 'Bookkeeping Premium waiting on EIN',
  completedEin = 'Bookkeeping Premium completed',
}

//Absctract / Default :)
export enum GenericServiceState {
  unrequested = "Unrequested",
  processing = "Processing",
  requiresAction = "Requires Action",
  completed = "Completed",
  canceled = "Canceled",
}

export enum ServiceOptionsNames {
  speedFilingRush = "speedFilingRush",
  speedFilingExpedite = "speedFilingExpedite",
  speedFilingAccelerated = "speedFilingAccelerated",
  speedFilingPriority = "speedFilingPriority",
}
